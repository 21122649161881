import React, { useState, useEffect } from 'react';
import ContentTitle from '../../components/core/typography/ContentTitle';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import { getTermsAndConditions } from '../../API/TermsConditionApi';

const TermsAndConditions = () => {
	const [termsData, setTermsData] = useState({
		lastUpdated: '',
		sections: [],
	});

	useEffect(() => {
		const fetchData = async () => {
			const data = await getTermsAndConditions();
			setTermsData(data);
		};

		fetchData();
	}, []);

	return (
		<div className="background term-section d-flex justify-content-center align-items-center pb-5">
			<div className="container-lg container-md container-sm mt-5">
				<div className=" title-section">
					<div className=" mb-5">
						<SubTitle subTitle={`Terms and Conditions`} />
					</div>
				</div>

				<div className="updated-section pb-5 pt-4">
					<Text text={`Last Updated`} />
					<Text text={termsData.lastUpdated} />
				</div>

				{termsData.sections.map((section, index) => (
					<div key={index} className="content-section pb-2">
						<ContentTitle contentTitle={section.title} />
						<Text text={section.content} />
					</div>
				))}

				<div className="action-section d-flex justify-content-end pt-4">
					<ButtonPrimary text={`I Accept`} onClick={''} />
				</div>
			</div>
		</div>
	);
};

export default TermsAndConditions;

import React from 'react';

const BlogCardImg = ({ imageUrl, altText = 'blog post' }) => {
	return (
		<div>
			<img src={imageUrl} alt={altText} width={100} height={250} />
		</div>
	);
};

export default BlogCardImg;

import React, { useState } from 'react';
import SelectInput from '../core/typography/SelectInput';

const ListSwitcher = () => {
	const [selectedValue, setSelectedValue] = useState('');

	const handleSelectChange = (event) => {
		setSelectedValue(event.target.value);
	};
	const customCategory = [
		{ value: 'Sort by (default)', label: 'Sort by (default)' },
		{ value: 'des', label: 'Newest' },
		{ value: 'asc', label: 'Oldest' },
	];

	// pages options
	const pages = [
		{ value: '{"start":0,"end":0}', label: 'All' },
		{ value: '{"start":0,"end":10}', label: '10 per page' },
		{ value: '{"start":0,"end":20}', label: '20 per page' },
		{ value: '{"start":0,"end":24}', label: '24 per page' },
	];
	return (
		<>
			<div class="sort-by d-flex justify-content-between ">
				<div>
					<SelectInput
						options={customCategory}
						value={selectedValue}
						onChange={handleSelectChange}
					/>
				</div>
				<div>
					<SelectInput
						options={pages}
						value={selectedValue}
						onChange={handleSelectChange}
					/>
				</div>
			</div>
		</>
	);
};

export default ListSwitcher;

// ACTUAL API

// import axios from 'axios';

// export const getCandidateDetails = async () => {
// 	try {
// 		const response = await axios.get(
// 			'https://your-api-endpoint/candidateDetails',
// 		);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Error fetching candidate details:', error);
// 		throw new Error('Error fetching candidate details');
// 	}
// };

// export const getTitleSectionDetails = async () => {
// 	try {
// 		const response = await axios.get(
// 			'https://your-api-endpoint/titleSectionDetails',
// 		);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Error fetching title section details:', error);
// 		throw new Error('Error fetching title section details');
// 	}
// };

// HARD CODED API

export const getCandidateDetails = () => {
	return Promise.resolve({
		aboutCandidate: `Moody’s Corporation, often referred to as Moody’s,
            is an American business and financial services company...`,
		images: [
			'../assets/images/blogImg/blog-1.jpg',
			'../assets/images/blogImg/blog-1.jpg',
			'../assets/images/blogImg/blog-1.jpg',
			'../assets/images/blogImg/blog-1.jpg',
		],
		professionalSkills: [
			{
				skill: 'App',
			},
			{
				skill: 'Administrative',
			},
			{
				skill: 'Android',
			},
			{
				skill: 'WordPress',
			},
			{
				skill: 'React Js',
			},
			{
				skill: 'Node Js',
			},
		],
		education: [
			{
				collegeInitial: 'M',
				course: `Bachelor's of Computer Science`,
				collegeName: `Kamla Nehru College, Nagpur`,
				year: '2019-2022',
				detail: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio. 
							Sequi debitis animi obcaecati unde voluptatem `,
			},
			{
				collegeInitial: 'M',
				course: `Bachelor's of Computer Science`,
				collegeName: `Kamla Nehru College, Nagpur`,
				year: '2019-2022',
				detail: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio. 
							Sequi debitis animi obcaecati unde voluptatem `,
			},
		],
		workExperience: [
			{
				companyInitial: 'M',
				designation: 'Front-End Developer',
				companyName: 'Menteechain',
				year: `2023-0000`,
				info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio. 
				Sequi debitis animi obcaecati unde voluptatem`,
			},
			{
				companyInitial: 'M',
				designation: 'Front-End Developer',
				companyName: 'Menteechain',
				year: `2023-0000`,
				info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio. 
				Sequi debitis animi obcaecati unde voluptatem`,
			},
		],
		awards: [
			// ... awards
		],
	});
};
export const getTitleSectionDetails = async () => {
	return {
		name: 'Sam Jon',
		position: 'Catalyst',
		location: 'Mumbai, India',
		postedTime: '11 Hour ago',
		salaryRange: '3LPA-$LPA',
		skills: ['App', 'Design', 'Digital'],
	};
};

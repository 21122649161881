import React from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import { Fragment } from 'react';
import AddBlogForm from '../../components/forms/AddBlogForm';
import Title from '../../components/core/typography/Title';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
const AddBlog = () => {
	return (
		<Fragment>
			<div className="add-blog pt-lg-5 container-lg container-md container-sm">
				<ProfileTitleSection
					title={'Add Blog'}
					subText={'Ready to jump back in?'}
				/>
				<div className="blog-form-container container pt-5 pb-5">
					<AddBlogForm />
				</div>
			</div>
		</Fragment>
	);
};

export default AddBlog;

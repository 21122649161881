// SingleBlogAPI.js

export const fetchSingleBlog = async () => {
	// Simulate a delay
	await new Promise((resolve) => setTimeout(resolve, 500));

	// This is the hardcoded data for a single blog post.
	return {
		subTitle:
			'Attract Sales And Profits toward the sunshine - and shadows will fall behind you.',
		author: 'Alison Dawn',
		date: 'August 31, 2021',
		commentsCount: 12,
		contentDescription: 'Course Description',
		content: [
			'Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.',
			// ... Add more paragraphs or content data as needed ...
		],
		quote: {
			text: 'Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue.',
			author: 'Luis Pickford',
		},
		requirements: [
			'We do not require any previous experience or pre-defined skills to take this course.',
			'A computer with a good internet connection.',
			'Adobe Photoshop (OPTIONAL)',
		],
		previousPost: {
			title: 'Given Set was without from god divide rule Hath',
			url: '/previous-post-url',
		},
		nextPost: {
			title: 'Tree earth fowl given moveth deep lesser After',
			url: '/next-post-url',
		},
		comments: [
			{
				author: 'Oscar Cafeo',
				title: 'Beautiful courses',
				text: 'Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly vehement tantaneously eel valiantly petted this along across highhandedly much.',
			},
			{
				author: 'Oscar Cafeo',
				title: 'Beautiful courses',
				text: 'Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly vehement tantaneously eel valiantly petted this along across highhandedly much.',
			},
			{
				author: 'Oscar Cafeo',
				title: 'Beautiful courses',
				text: 'Far much that one rank beheld bluebird after outside ignobly allegedly more when oh arrogantly vehement tantaneously eel valiantly petted this along across highhandedly much.',
			},
			// ... Add more comments as needed ...
		],
	};
};

// ACTUAL API
// import axios from 'axios';

// const BASE_URL = 'https://api.yoursite.com/';

// export const getTrainersData = async () => {
// 	try {
// 		const response = await axios.get(`${BASE_URL}trainers`);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Failed to fetch trainers:', error);
// 		throw error;
// 	}
// };

// HARD CODED DATA
import TrainerImg from '../components/core/img/TrainerImg';

export const getTrainersData = async () => {
	// Mock data for the sake of this example
	return [
		{
			id: 1,
			title: 'UPSC',
			text: 'John is an experienced cardio trainer...',
			name: 'John Doe',
			rating: '4.5',
			specialty: 'Cardio',
			image: <TrainerImg />,
		},
		{
			id: 2,
			title: 'UPSC',
			text: 'John is an experienced cardio trainer...',
			name: 'Jane Smith',
			rating: '4.5',
			specialty: 'Strength',
			image: <TrainerImg />,
		},
		{
			id: 3,
			title: 'UPSC',
			text: 'John is an experienced cardio trainer...',
			name: 'Alex Brown',
			rating: '4.5',
			specialty: 'Flexibility',
			image: <TrainerImg />,
		},
		{
			id: 3,
			title: 'UPSC',
			text: 'John is an experienced cardio trainer...',
			name: 'Alex Brown',
			rating: '4.5',
			specialty: 'Flexibility',
			image: <TrainerImg />,
		},
	];
};

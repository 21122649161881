import React from 'react';
import SubTitle from '../core/typography/SubTitle';
import Text from '../core/typography/Text';

const About = () => {
	return (
		<div>
			<div id="about" className="about">
				<div className="container">
					<div className="text-center">
						<SubTitle subTitle={`About Us`} />
					</div>
					<div className="content mt-5 text-center">
						<Text
							text={`We specialize in hiring technical and management
							professionals for young and growing startups and
							mid-size companies. We use our extensive sourcing
							strategy to identify the top talent from our
							proprietary and referral networks, conduct
							interviews and provide detailed insights into
							candidates’ profiles. We align the talent pool with
							our customer’s business and culture by working with
							you to develop a fit not only for the job
							description and keywords but also for the vision and
							mission our customers stand for.`}
							lineHeight={`1.9`}
							letterSpacing={'.2rem'}
							size={`2.2rem`}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;

import React from 'react';
import profile from '../../../assets/images/blogImg/blog-1.jpg';
const CommentProfile = () => {
	return (
		<div>
			<img
				class="rounded-circle"
				src={profile}
				alt="resource"
				width="70"
				height="70"
			/>
		</div>
	);
};

export default CommentProfile;

// dashboardApi.js
import axios from 'axios';
import BlogProfile from '../components/core/img/BlogProfile';
import TeamMemberImg from '../components/core/img/TeamMemberImg';

// Assume this is the base URL of the backend server
// const BASE_URL = 'https://your-backend-api.com/api/';

// export const getNotifications = async () => {
// 	try {
// 		const response = await axios.get(`${BASE_URL}notifications`);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Failed to fetch notifications:', error);
// 		return []; // or throw the error depending on your error handling strategy
// 	}
// };

// export const getApplicantDetails = async () => {
// 	try {
// 		const response = await axios.get(`${BASE_URL}applicantDetails`);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Failed to fetch applicant details:', error);
// 		return [];
// 	}
// };

// export const getTeamMembers = async () => {
// 	try {
// 		const response = await axios.get(`${BASE_URL}teamMembers`);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Failed to fetch team members:', error);
// 		return [];
// 	}
// };

// const BASE_URL = 'https://your-backend-api.com/api/';

// export const getApplicants = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}applicants`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch applicants:', error);
//         return [];
//     }
// };

// export const getCountryData = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}countryData`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch country data:', error);
//         return [];
//     }
// };

// export const getStatusData = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}statusData`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch status data:', error);
//         return [];
//     }
// };

// **********************************************************
export const getCountryData = async () => {
	try {
		// Mocked data for country
		return [
			{ country: 'Italy', value: 456 },
			{ country: 'United States', value: 342 },
			{ country: 'Canada', value: 190 },
			{ country: 'Spain', value: 256 },
		];
	} catch (error) {
		console.error('Error fetching country data:', error);
		return [];
	}
};

export const getStatusData = async () => {
	try {
		// Mocked data for status
		return [
			{ status: 'Jobs', value: 456 },
			{ status: 'Candidates', value: 238, trend: 'increase' },
			{ status: 'Rejection Rate', value: 67, trend: 'decrease' },
			{
				status: 'Currencies',
				currencies: ['Bitcoin', 'Dollar', 'Euro', 'Rupee'],
			},
		];
	} catch (error) {
		console.error('Error fetching status data:', error);
		return [];
	}
};
export const getNotifications = async () => {
	// Hardcoded data for Notifications
	return [
		{ name: 'Henry Willson', jobTitle: 'Product Manager' },
		{ name: 'Henry Willson', jobTitle: 'Product Manager' },
		{ name: 'Henry Willson', jobTitle: 'Product Manager' },
		{ name: 'Henry Willson', jobTitle: 'Product Manager' },
		{ name: 'Henry Willson', jobTitle: 'Product Manager' },
	];
};

export const getTeamMembers = async () => {
	// Hardcoded data for Team Members
	return [
		{
			memberImg: <TeamMemberImg />,
			name: 'Andrison John',
			designation: 'Top Trader',
		},
		{
			memberImg: <TeamMemberImg />,
			name: 'Andrison John',
			designation: 'Top Trader',
		},
		{
			memberImg: <TeamMemberImg />,
			name: 'Andrison John',
			designation: 'Top Trader',
		},
		{
			memberImg: <TeamMemberImg />,
			name: 'Andrison John',
			designation: 'Top Trader',
		},
		{
			memberImg: <TeamMemberImg />,
			name: 'Andrison John',
			designation: 'Top Trader',
		},
		// ... add more team members as needed
	];
};

import React from 'react';
import logo from '../../../assets/images/logo.png';
const FooterLogo = () => {
	return (
		<div>
			<img src={logo} alt="brand" width="75rem" height="75rem" />
		</div>
	);
};

export default FooterLogo;

import React, { useState } from 'react';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import Input from '../core/typography/Input';
import CheckBox from '../core/typography/CheckBox';
import SubText from '../core/typography/SubText';
import { Link } from 'react-router-dom';
import { loginUser } from '../../API/Login';

const LoginForm = () => {
	// States to hold form data and errors
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});
	const [errors, setErrors] = useState({});
	// Handle input changes and update form data state
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {};
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		if (!formData.email) {
			newErrors.email = 'Email is required!';
		} else if (!emailPattern.test(formData.email)) {
			newErrors.email = 'Invalid email format!';
		}

		if (!formData.password) {
			newErrors.password = 'Password is required!';
		}

		if (Object.keys(newErrors).length === 0) {
			try {
				const response = await loginUser(formData);
				console.log('API Response:', response);
				// You can save the token or perform other actions.
			} catch (error) {
				if (error.response && error.response.status === 400) {
					newErrors.loginError = 'Invalid email or password.';
				} else {
					newErrors.loginError = 'Failed to login. Please try again.';
				}
				setErrors(newErrors);
			}
		} else {
			setErrors(newErrors);
		}
	};

	return (
		<div>
			<form
				id="loginForm"
				className="text-center"
				onSubmit={handleSubmit}
			>
				<Input
					type="email"
					name="email"
					id="floatingEmail"
					label="Email"
					value={formData.email}
					onChange={handleChange}
				/>
				{errors.email && (
					<p className="error-text text-danger">{errors.email}</p>
				)}

				<Input
					type="password"
					name="password"
					id="floatingPassword"
					label="Password"
					value={formData.password}
					onChange={handleChange}
				/>
				{errors.password && (
					<p className="error-text text-danger">{errors.password}</p>
				)}

				<div className="d-flex justify-content-between mb-3 mt-2">
					<div className="d-flex justify-content-center align-items-center">
						<CheckBox />
						<SubText subText="Remember Me" />
					</div>

					<Link
						to="/forgot-password"
						className="text-decoration-none"
					>
						<SubText subText="Forgot Password?" />
					</Link>
				</div>

				<ButtonTertiary type="submit" text="Login" />
			</form>
		</div>
	);
};

export default LoginForm;

import React, { useState } from 'react';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import ContentTitle from '../../components/core/typography/ContentTitle';
import Text from '../../components/core/typography/Text';
import { Modal } from 'react-bootstrap';
import SubTitle from '../../components/core/typography/SubTitle';
import CompanyTitle from './CompanyTitle';
import CompanyOveriview from '../../components/jobs/CompanyOveriview';
import BlogCardImg from '../../components/core/img/BlogCardImg';
import CompanyCard from '../../components/core/cards/CompanyCard';
import MessageForm from '../../components/forms/MessageForm';
import SubText from '../../components/core/typography/SubText';
import JobCard from '../../components/core/cards/JobCard';

const CompanyDetails = () => {
	const [showModal, setShowModal] = useState(false);

	const handleModalOpen = () => {
		setShowModal(true);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};
	return (
		<>
			<section className="job-detail-section company-details">
				<div className="upper-box">
					<div className="container container d-flex align-items-center">
						{/* <JobCard /> */}
						<CompanyTitle />
						<ButtonPrimary
							text={'Private Message'}
							onClick={handleModalOpen}
						/>
					</div>
				</div>
				<div className="job-detail-outer position-relative">
					<div className="auto-container container">
						<div className="row">
							<div className="content-column col-lg-8 col-md-12 col-sm-12">
								<div className="job-detail position-relative">
									<ContentTitle
										contentTitle={`About Company`}
									/>
									<Text
										text={`Moody’s Corporation, often referred to as Moody’s,
												is an American business and financial services company. 
												It is the holding company for Moody’s Investors Service (MIS), 
												an American credit rating agency, and Moody’s Analytics (MA), 
												an American provider of financial analysis software and services.
												Moody’s was founded by John Moody in 1909 to produce manuals of statistics 
												related to stocks and bonds and bond ratings.
												Moody’s was acquired by Dun & Bradstreet in 1962. In 2000, Dun & Bradstreet spun off Moody’s Corporation as 
												a separate company that was listed on the NYSE under MCO. In 2007, Moody’s 
												Corporation was split into two operating divisions, Moody’s Investors Service, 
												the rating agency, and Moody’s Analytics, with all of its other products.`}
									/>

									<div className="row images-outer">
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
									</div>

									<Text
										text={`Moody’s Corporation, often referred to as Moody’s,
												is an American business and financial services company. 
												It is the holding company for Moody’s Investors Service (MIS), 
												an American credit rating agency, and Moody’s Analytics (MA), 
												an American provider of financial analysis software and services.
												Moody’s was founded by John Moody in 1909 to produce manuals of statistics 
												related to stocks and bonds and bond ratings.
												Moody’s was acquired by Dun & Bradstreet in 1962. In 2000, Dun & Bradstreet spun off Moody’s Corporation as 
												a separate company that was listed on the NYSE under MCO. In 2007, Moody’s 
												Corporation was split into two operating divisions, Moody’s Investors Service, 
												the rating agency, and Moody’s Analytics, with all of its other products.`}
									/>
									<div className="mt-5 pt-5">
										<ContentTitle
											contentTitle={`Current Open Jobs with Employers`}
										/>
										<SubText
											subText={`20 jobs live - 3 added today`}
										/>

										<JobCard />
										<JobCard />
										<JobCard />
									</div>
								</div>
							</div>
							<div className="sidebar-column col-lg-4 col-md-12 col-sm-12 position-relative">
								<aside className="sidebar">
									<CompanyOveriview />
								</aside>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Modal */}
			<Modal show={showModal} onHide={handleModalClose}>
				<Modal.Title className="text-center pt-5 pb-5">
					<SubTitle
						subTitle={`Send Message to Coursera`}
						size={`2.6rem`}
					/>
				</Modal.Title>
				<Modal.Body>
					<div className="modal-content container pb-4">
						<div className="modal-body">
							<MessageForm />
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CompanyDetails;

import React from 'react';

const ButtonTertiary = ({ text, size, onClick, icon, id }) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	const buttonStyle = {
		fontSize: size,
	};
	return (
		<div>
			<button
				className=" button-tertiary"
				onClick={handleClick}
				style={{ ...buttonStyle }}
				id={id}
			>
				{text}
				{icon && <span className="icon px-2 ">{icon}</span>}
			</button>
		</div>
	);
};

export default ButtonTertiary;

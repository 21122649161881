import React from 'react';

const Text = ({ text, lineHeight, letterSpacing, size, color }) => {
	const textStyles = {
		lineHeight,
		letterSpacing,
		fontSize: size,
		color,
	};
	return (
		<>
			<p className="text" style={{ ...textStyles }}>
				{text}
			</p>
		</>
	);
};

export default Text;

import React, { useState, useEffect } from 'react';
import ButtonTertiary from '../../core/buttons/ButtonTertiary';
import { Link } from 'react-router-dom';
import SubTitle from '../../core/typography/SubTitle';
import { fetchJobs } from '../../../API/JobsAPI';
import JobList from '../JobList';

const RecentJobs = () => {
	const [jobs, setJobs] = useState([]);

	useEffect(() => {
		async function loadJobs() {
			try {
				const fetchedJobs = await fetchJobs();
				setJobs(fetchedJobs);
			} catch (error) {
				console.error('Error fetching jobs:', error);
			}
		}

		loadJobs();
	}, []);

	return (
		<div id="recent-jobs" className="recent-job-section content-area-5">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="heading d-flex justify-content-between">
							<SubTitle subTitle={`Recent Jobs`} />
							<a
								href={
									'https://www.linkedin.com/company/menteechain/jobs/'
								}
								target="_blank"
								rel="noopener noreferrer"
							>
								<ButtonTertiary text={'Browse All Jobs'} />
							</a>
						</div>
					</div>
				</div>
				<div className="row">
					<JobList jobs={jobs} />
				</div>
			</div>
		</div>
	);
};

export default RecentJobs;

import React from 'react';
import ButtonTertiary from '../buttons/ButtonTertiary';
import Caption from './Caption';

const UploadDoc = () => {
	return (
		<div>
			<div className=" position-relative">
				<div className="uploading-resume">
					<div className="uploadButton position-relative d-flex align-content-center flex-column justify-content-center">
						<input
							className="uploadButton-input position-relative overflow-hidden d-none pointer-event-none"
							type="file"
							name="attachments[]"
							accept=".doc,.docx,.xml,application/msword,application/pdf, image/*"
							id="upload"
							multiple=""
						/>
						<label className="cv-uploadButton" for="upload">
							<Caption
								caption={`Drop files here to upload`}
								size={`2.2rem`}
								color={`var(--common-color)`}
							/>
							<Caption
								caption={`To upload file size is (Max
		5Mb) and allowed file types
		are (.doc, .docx, .pdf)`}
								size={`1.5rem`}
							/>

							<ButtonTertiary
								text={'Upload Resume'}
								size={`1.5rem`}
							/>
						</label>
						<span className="uploadButton-file-name"></span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UploadDoc;

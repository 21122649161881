// ACTUAL API

// import axios from 'axios';

// export async function fetchForwardCandidateDetails() {
// 	try {
// 		const response = await axios.get(
// 			'https://api.yourwebsite.com/forward-candidate-details',
// 		);
// 		return response.data;
// 	} catch (error) {
// 		console.error(
// 			'Error fetching forward candidate details:',
// 			error.message,
// 		);
// 		throw new Error(
// 			'Failed to fetch forward candidate details: ' + error.message,
// 		);
// 	}
// }

// HARD CODED DATA
const mockApplicantDetails = [
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
];

export async function fetchForwardCandidateDetails() {
	try {
		// Simulating an API delay with hardcoded data
		const response = await new Promise((resolve) => {
			setTimeout(() => {
				resolve({ data: mockApplicantDetails });
			}, 1000);
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching forward candidate details:', error);
		throw new Error('Error fetching forward candidate details');
	}
}

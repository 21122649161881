import React, { useEffect, useState } from 'react';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import ContentTitle from '../../components/core/typography/ContentTitle';
import Text from '../../components/core/typography/Text';
import { Modal } from 'react-bootstrap';
import SubTitle from '../../components/core/typography/SubTitle';
import ApplyForm from '../../components/forms/ApplyForm';
import JobTitle from '../../components/jobs/JobTitle';
import JobOverview from '../../components/jobs/JobOverview';
import CompanyOveriview from '../../components/jobs/CompanyOveriview';
import ShareApp from '../../components/shareApp/ShareApp';
import JobCard from '../../components/core/cards/JobCard';
import SubText from '../../components/core/typography/SubText';
import { FiMapPin, FiX } from 'react-icons/fi';
import { fetchJobDetails } from '../../API/JobDetailsApi';
import { useParams } from 'react-router-dom';
import JobList from '../../components/jobs/JobList';
import { fetchJobs } from '../../API/JobsAPI';
import { GoBriefcase } from 'react-icons/go';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IconContext } from 'react-icons';
import CompaniesLogo from '../../components/core/img/CompaniesLogo';
import { IoCashOutline } from 'react-icons/io5';

const JobDetails = () => {
	// const [showModal, setShowModal] = useState(false);
	// const [jobDetails, setJobDetails] = useState({});

	// const handleModalOpen = () => {
	// 	setShowModal(true);
	// };

	// const handleModalClose = () => {
	// 	setShowModal(false);
	// };

	// // fetch api
	// useEffect(() => {
	// 	const getJobDetails = async () => {
	// 		try {
	// 			const data = await fetchJobDetails();
	// 			setJobDetails(data);
	// 		} catch (error) {
	// 			console.error(
	// 				'There was an error fetching the job details:',
	// 				error,
	// 			);
	// 		}
	// 	};

	// 	getJobDetails();
	// }, []);

	// const jobSkills = jobDetails.jobSkills || [];
	// const jobs = jobDetails.jobs || [];
	// const jobDescription = jobDetails.jobDescription || '';
	// const keyResponsibilities = jobDetails.keyResponsibilities || [];
	// const requirements = jobDetails.requirements || [];

	const { jobId } = useParams();
	const [jobs, setJobs] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	useEffect(() => {
		const getJobDetails = async () => {
			try {
				const allJobs = await fetchJobDetails();
				const matchedJob = allJobs.find(
					(job) => job.id === parseInt(jobId),
				);
				setSelectedJob(matchedJob);
			} catch (error) {
				console.error(
					'There was an error fetching the job details:',
					error,
				);
			}
		};
		getJobDetails();
	}, [jobId]);

	useEffect(() => {
		async function loadJobs() {
			try {
				const fetchedJobs = await fetchJobs();
				setJobs(fetchedJobs);
			} catch (error) {
				console.error('Error fetching jobs:', error);
			}
		}

		loadJobs();
	}, []);
	const handleModalOpen = () => {
		setShowModal(true);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};

	if (!selectedJob) {
		return <p>Loading...</p>;
	}

	const {
		jobTitle,
		location,
		jobType,
		salaryRange,
		description,
		keyresponsibilities,
		requirements,
		skills,
	} = selectedJob;

	const jobDetails = [
		{
			icon: FiMapPin,
			text: location,
		},
		{
			icon: AiOutlineClockCircle,
			text: jobType,
		},
		{
			icon: IoCashOutline,
			text: salaryRange,
		},
	];
	return (
		<>
			<section className="job-detail-section background pt-3 ">
				<div className="container-lg container-md container-sm">
					<div className="pb-5">
						<div className=" title-section  d-flex align-items-center flex-lg-row flex-column">
							{/* JobTitle  */}
							<div className="container">
								<div className="d-flex justify-content-between align-items-center">
									<div className="d-flex align-items-center mt-5 mb-5 pt-5">
										{/* <CompaniesLogo /> */}

										<div className="mx-3">
											<SubTitle subTitle={jobTitle} />

											<div className="job-ad-item pt-2">
												<div className="d-flex flex-wrap">
													{jobDetails.map(
														(detail, index) => (
															<div
																className="d-flex mx-3 align-items-center"
																key={index}
															>
																<IconContext.Provider
																	value={{
																		size: '1.7rem',
																		color: 'var(--subtext-color)',
																		className:
																			'me-2',
																	}}
																>
																	<detail.icon />
																</IconContext.Provider>
																<SubText
																	subText={
																		detail.text
																	}
																	size="1.7rem"
																/>
															</div>
														),
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className=" pt-5 d-flex">
								<ButtonPrimary
									text="Apply Now"
									onClick={handleModalOpen} // Fixed typo here
								/>
							</div>
						</div>
					</div>
					<div class="background position-relative container-md container-sm">
						<div class="auto-container container">
							<div class="row">
								<div class="content-column col-lg-8 col-md-12 col-sm-12 ">
									<div class="job-detail position-relative">
										<div>
											<ContentTitle
												contentTitle="Job Description"
												size={'2.5rem'}
											/>
											<Text text={description} />

											<div className="pt-5 mt-3">
												<ContentTitle
													contentTitle="Key Responsibilities"
													size={'2.5rem'}
												/>
												<div className="px-4 position-relative mb-5">
													{keyresponsibilities.map(
														(item, index) => (
															<Text
																text={item}
																key={index}
															/>
														),
													)}
												</div>
											</div>

											<ContentTitle
												contentTitle="Who we’re looking for:"
												size={'2.5rem'}
											/>
											<div className="divst-style-three px-4">
												{requirements.map(
													(item, index) => (
														<Text
															text={item}
															key={index}
														/>
													),
												)}
											</div>

											<div className="pt-5 mt-3 ">
												<ContentTitle
													contentTitle="Share this job"
													size={'2.5rem'}
												/>
												<div className=" m-auto container">
													<ShareApp />
												</div>
											</div>
										</div>
										<div className="mt-5 pt-5">
											<ContentTitle
												contentTitle={`Related Jobs`}
												size={'3rem'}
											/>
											<SubText
												subText={`2023 job divve - 200 added today`}
											/>
											<div
												className="pt-4"
												style={{
													height: '555px',
													overflow: 'hidden',
												}}
											>
												{/* {jobs.map((job, index) => (
													<JobCard
														key={index}
														{...job}
													/>
												))} */}
												<JobList jobs={jobs} />
											</div>
										</div>
									</div>
								</div>
								<div class="sidebar-column col-lg-4 col-md-12 col-sm-12 position-relative d-flex flex-column align-items-center ">
									<aside class="sidebar">
										<JobOverview />
										<div className=" sidebar-widget mt-5 ">
											<ContentTitle
												size={'2.5rem'}
												contentTitle={`Job Skills`}
												color={`var(--text-color)`}
											/>
											<div>
												<div class="widget-content pt-4">
													<div class="job-skills ">
														{skills.map(
															(elem, key) => {
																return (
																	<>
																		<div
																			key={
																				key
																			}
																			className="d-flex"
																		>
																			<Text
																				text={
																					elem
																				}
																			/>
																		</div>
																	</>
																);
															},
														)}
													</div>
												</div>
											</div>
										</div>

										<CompanyOveriview />
									</aside>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Modal */}
			<Modal show={showModal} onHide={handleModalClose}>
				<div className="d-flex justify-content-end pt-4 pe-4">
					<FiX
						style={{ cursor: 'pointer', fontSize: '2.3rem' }}
						onClick={handleModalClose}
					/>
				</div>
				<div className="d-flex justify-content-center">
					<SubTitle subTitle={'Apply Now'} />
				</div>
				<Modal.Body className="container">
					<div className="container">
						<ApplyForm />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default JobDetails;

import React from 'react';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import SelectInput from '../core/typography/SelectInput';
import TextArea from '../core/typography/TextArea';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import UploadDocument from '../core/typography/UploadDocument';
import UploaddocImg from '../core/img/UploaddocImg';
import Caption from '../core/typography/Caption';
import { useState } from 'react';

const CompanyProfileForm = () => {
	// State to manage the form fields
	const [formData, setFormData] = useState({
		companyname: '',
		email: '',
		phonenumber: '',
		weburl: '',
		established: '',
		teamsize: '',
		domain: '',
		address: '',
		country: '',
		city: '',
		categories: '',
		searchlisting: '',
		aboutCompany: '',
		companyLogo: null,
	});

	// State for form validations
	const [formErrors, setFormErrors] = useState({});

	const teamsize = [
		{ value: '', label: '100-150' },
		{ value: '', label: '10' },
		{ value: '', label: '30' },
		{ value: '', label: '40' },
	];

	const country = [
		{ value: '', label: 'India' },
		{ value: '', label: 'USA' },
		{ value: '', label: 'Canada' },
	];

	const categories = [
		{ value: '', label: 'India' },
		{ value: '', label: 'USA' },
		{ value: '', label: 'Canada' },
	];

	const searchListing = [
		{ value: '', label: 'India' },
		{ value: '', label: 'USA' },
		{ value: '', label: 'Canada' },
	];
	const companyForm = [
		{
			label: 'Company Name',
			name: 'companyname',
			type: 'text',
			placeholder: 'Menteechain PVT LTD',
		},
		{
			label: 'Email',
			name: 'email',
			type: 'email',
			placeholder: 'Company Name',
		},
		{
			label: 'Phone Number',
			name: 'phonenumber',
			type: 'text',
			placeholder: '0 123 456 7890',
		},
		{
			label: 'Website',
			name: 'weburl',
			type: 'text',
			placeholder: 'www.menteechain.com',
		},
		{
			label: 'Est. Since',
			name: 'established',
			type: 'text',
			placeholder: '04 Dec 2020',
		},
		{
			label: 'Team Size',
			name: 'teamsize',
			type: 'select',
			options: teamsize,
		},
		{
			label: 'Domain',
			name: 'domain',
			type: 'text',
			placeholder: 'CAD Domain',
		},
		{
			label: 'Address',
			name: 'address',
			type: 'text',
			placeholder: 'Abc street, xyz Location',
		},
		{ label: 'Country', name: 'country', type: 'select', options: country },
		{ label: 'City', name: 'city', type: 'text', placeholder: 'eg. Pune' },
		{
			label: 'Categories',
			name: 'categories',
			type: 'select',
			options: categories,
		},
		{
			label: 'Allow in search & listing',
			name: 'searchlisting',
			type: 'select',
			options: searchListing,
		},
	];

	// Handle input change
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	// Handle file change (for company logo)
	const handleFileChange = (e) => {
		setFormData((prevData) => ({
			...prevData,
			companyLogo: e.target.files[0],
		}));
	};

	// Basic validation check
	const validateForm = () => {
		let isValid = true;
		let errors = {};

		// Simple email validation pattern
		const emailPattern = /^\S+@\S+\.\S+$/;
		if (!emailPattern.test(formData.email)) {
			isValid = false;
			errors.email = 'Invalid email address';
		}

		// Check if company name is provided
		if (!formData.companyname.trim()) {
			isValid = false;
			errors.companyname = 'Company name is required';
		}

		// ... Add other validation checks as required ...

		setFormErrors(errors);
		return isValid;
	};

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			console.log('Form data submitted:', formData);
			// Handle form submission logic here
		}
	};
	return (
		<div>
			<form onSubmit={handleSubmit} className=" position-relative">
				<div className="company-profile-form row d-flex justify-content-between p-4 rounded-5">
					<div className="uploadButton position-relative d-flex mb-3 ">
						<div className="">
							<input
								className="uploadButton-input position-relative overflow-hidden d-none pointer-event-none"
								type="file"
								name="attachments[]"
								accept=".doc,.docx,.xml,application/msword,application/pdf, image/*"
								id="upload"
								multiple=""
							/>

							<label
								className="cv-uploadButton mt-4 mb-2 "
								for="upload"
							>
								<UploaddocImg />
								<div className="pt-3">
									<ButtonTertiary
										text={'Upload Logo'}
										size={`1.3rem`}
										type={'file'}
										id={'upload'}
									/>
								</div>
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-center mx-5">
							<Caption
								caption={`To upload file size is (Max
							5Mb) and allowed file types
							are (.doc, .docx, .pdf, .jpg or .png)`}
								size={`1.5rem`}
							/>
						</div>

						<span className="uploadButton-file-name"></span>
					</div>
					{companyForm.map((element, key) => {
						const { label, name, id, placeholder, type, options } =
							element;
						return (
							<div
								className="col-lg-5 col-md-6 col-sm-12"
								key={key}
							>
								<LabelText labelText={label} />
								{type === 'select' ? (
									<SelectInput options={options} />
								) : (
									<Input
										label={placeholder}
										name={name}
										type={type}
										id={id}
									/>
								)}
							</div>
						);
					})}
					<div className="pt-5">
						<LabelText labelText={'About Company'} />
						<TextArea
							placeholder={`Your Company Details...`}
							name="aboutCompany"
							value={formData.aboutCompany}
							onChange={handleChange}
						/>
						{formErrors.aboutCompany && (
							<p className="text-danger">
								{formErrors.aboutCompany}
							</p>
						)}
						<div className="d-flex justify-content-end pt-5">
							<ButtonTertiary text={'Submit'} />
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CompanyProfileForm;

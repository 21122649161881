import React from 'react';
import Rating from '../../rating/Rating';
import TrainerImg from '../img/TrainerImg';
import ButtonPrimary from '../buttons/ButtonPrimary';
import Text from '../typography/Text';
import ContentTitle from '../typography/ContentTitle';
import SubText from '../typography/SubText';

const Trainer = ({
	title,
	text,
	image,
	trainerName,
	ratingValue,
	buttonText,
}) => {
	return (
		<div className="card m-auto">
			<TrainerImg />

			<div className="card-block">
				<ContentTitle contentTitle={title} />
				<Text text={text} />
			</div>
			<div className="card-block course-info d-flex justify-content-between">
				<div className="card-course-info">
					<figure>{image}</figure>
					<div className="card-text tutor-name">
						<Text text={trainerName} />
					</div>
					<span className="tutor-description">TUTOR</span>
				</div>
				<div className="card-course-info d-flex text-center">
					<div className="rating-container">
						<Rating />
						{/* <SubText subText={ratingValue} /> */}
					</div>
				</div>
			</div>
			<div className="card-block card-bottom">
				<ButtonPrimary text={buttonText} />
			</div>
		</div>
	);
};

export default Trainer;

import React from 'react';
import { IconContext } from 'react-icons';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import ContentTitle from '../typography/ContentTitle';
import SubText from '../typography/SubText';
import Text from '../typography/Text';

const ApplicantCard = ({ details, isBookmarked, onBookmarkToggle }) => {
	const {
		applicantImg,
		title,
		designation,
		location,
		hoursAgo,
		salary,
		remote,
		joining,
		workType,
	} = details;

	return (
		<div className="d-flex  p-3 justify-content-between applicant-card rounded-4">
			<div className="d-flex flex-lg-row flex-column">
				<div>{applicantImg}</div>
				<div>
					<ContentTitle contentTitle={title} />
					<div className="job-ad-item pt-2">
						<div className="d-flex ">
							<div className="d-flex  align-items-center">
								<IconContext.Provider
									value={{
										size: '1.5rem',
										color: 'var(--subtext-color)',
									}}
								>
									<GoBriefcase />
								</IconContext.Provider>
								<SubText subText={designation} size="1.3rem" />
							</div>
							<div className="d-flex mx-2 align-items-center">
								<IconContext.Provider
									value={{
										size: '1.5rem',
										color: 'var(--subtext-color)',
									}}
								>
									<FiMapPin />
								</IconContext.Provider>
								<SubText subText={location} size="1.3rem" />
							</div>
							<div className="d-flex mx-2 align-items-center">
								<IconContext.Provider
									value={{
										size: '1.5rem',
										color: 'var(--subtext-color)',
									}}
								>
									<AiOutlineClockCircle />
								</IconContext.Provider>
								<SubText subText={hoursAgo} size="1.3rem" />
							</div>
							<div className="d-flex mx-2 align-items-center">
								<IconContext.Provider
									value={{
										size: '1.5rem',
										color: 'var(--subtext-color)',
									}}
								>
									<IoCashOutline />
								</IconContext.Provider>
								<SubText subText={salary} size="1.3rem" />
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-evenly  pt-4">
						<div className="joining">
							<Text text={remote} size="1.5rem" />
						</div>
						<div className="joining ">
							<Text text={joining} size="1.5rem" />
						</div>
						<div className="joining">
							<Text text={workType} size="1.5rem" />
						</div>
					</div>
				</div>
			</div>
			<div onClick={onBookmarkToggle} style={{ cursor: 'pointer' }}>
				<IconContext.Provider
					value={{
						size: '2rem',
						color: 'var(--common-color)',
					}}
				>
					{isBookmarked ? <FaBookmark /> : <FaRegBookmark />}
				</IconContext.Provider>
			</div>
		</div>
	);
};

export default ApplicantCard;

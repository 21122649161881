import React, { useState } from 'react';
import JobsFilter from '../../components/jobs/JobsFilter';
import SubTitle from '../../components/core/typography/SubTitle';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import Candidate from '../../components/core/img/Candidate';
import CandidateCard from '../../components/core/cards/CandidateCard';
import Title from '../../components/core/typography/Title';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import { Modal } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { IoFilter } from 'react-icons/io5';
import ButtonSecondary from '../../components/core/buttons/ButtonSecondary';
import { getApplicantDetails } from '../../API/CandidateApi';
import { useEffect } from 'react';

const BrowseCandidate = () => {
	// state for filter column
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
	// Applicants state
	const [applicantDetails, setApplicantDetails] = useState([]);
	// Initialize an array with the same length as applicantDetails and fill it with false values
	const [bookmarks, setBookmarks] = useState(
		new Array(applicantDetails.length).fill(false),
	);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getApplicantDetails();
			setApplicantDetails(data);
		};

		fetchData();
	}, []);

	// Bookmark toggle function
	const handleBookmarkClick = (index) => {
		const newBookmarks = [...bookmarks];
		newBookmarks[index] = !newBookmarks[index];
		setBookmarks(newBookmarks);
	};

	//filter modal function
	const toggleFilterModal = () => {
		setIsFilterModalVisible(!isFilterModalVisible);
	};

	return (
		<>
			<div className="container-lg">
				<div className=" pt-3">
					<ProfileTitleSection
						title={'Browse Candidate'}
						subText={'Ready to jump back in?'}
					/>
				</div>
				<div className=" row gx-0 content-section">
					<div
						className="filters-column col-lg-3 d-none d-lg-block"
						style={{ width: '35rem' }}
					>
						<JobsFilter />
					</div>
					<div className="  col-lg-8 col-md-12 col-sm 12 jobs-list">
						{/* Filter button visible only on small and medium screens */}
						<div className="container  pt-3 d-flex  align-items-center d-lg-none  ">
							<div className="menu-btn m-auto px-5">
								<IconContext.Provider
									value={{
										size: '2rem',
										color: 'var(--common-color)',
									}}
								>
									<IoFilter />
								</IconContext.Provider>
								<ButtonSecondary
									text={'Filter'}
									onClick={toggleFilterModal}
								/>
							</div>
						</div>
						<div class="ls-switcher container  pb-5 position-relative d-flex justify-content-between align-items-center">
							<div class="showing-result">
								<div class="text text-white">
									<strong>20</strong> jobs
								</div>
							</div>
							<ListSwitcher />
						</div>
						<section className=" pt-5 pb-5">
							<div className="container row d-flex rounded-5 mb-5">
								{applicantDetails.map((curElement, index) => {
									return (
										<div className=" col-lg-12 col-md-12 col-sm-12 mb-4 ">
											<CandidateCard
												key={index}
												applicantImg={
													curElement.applicantImg
												}
												title={curElement.title}
												designation={
													curElement.designation
												}
												location={curElement.location}
												salary={curElement.salary}
												skill1={curElement.skill1}
												skill2={curElement.skill2}
												skill3={curElement.skill3}
												handleBookmarkClick={() =>
													handleBookmarkClick(index)
												}
												isBookmarked={bookmarks[index]}
											/>
										</div>
									);
								})}
							</div>
						</section>
					</div>
				</div>
				{/* Filter Modal for medium and smaller screens */}
				<Modal
					show={isFilterModalVisible}
					onHide={toggleFilterModal}
					size="lg"
					className="d-lg-none left-side-modal"
				>
					<div className="d-flex justify-content-end pt-4 pe-4">
						<FiX
							style={{
								cursor: 'pointer',
								fontSize: '2.3rem',
								border: '.2rem solid white',
								borderRadius: '5rem',
							}}
							onClick={toggleFilterModal}
						/>
					</div>
					<Modal.Body>
						<JobsFilter />
					</Modal.Body>
				</Modal>
			</div>
		</>
	);
};

export default BrowseCandidate;

import React, { useState } from 'react';
import Input from '../core/typography/Input';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import { FaArrowRightLong } from 'react-icons/fa6';

const ForgotPasswordForm = () => {
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const handleChange = (event) => {
		const { value } = event.target;
		setEmail(value);

		// Validating email
		setEmailError(
			/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
				? ''
				: 'Email is not valid!',
		);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// If the email is valid, process the form
		if (!emailError) {
			console.log('Email submitted:', email);
		} else {
			console.log('Email has errors.');
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Input
					type={`email`}
					label={`Email`}
					id={`floatingEmail`}
					name={`email`}
					value={email}
					onChange={handleChange}
				/>
				{emailError && <span>{emailError}</span>}

				<ButtonTertiary text={`Submit`} icon={<FaArrowRightLong />} />
			</form>
		</>
	);
};

export default ForgotPasswordForm;

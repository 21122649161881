import React from 'react';
import { FiMapPin, FiPlusCircle } from 'react-icons/fi';
import { AiOutlineSearch } from 'react-icons/ai';
import { GoBriefcase } from 'react-icons/go';
import ContentTitle from '../core/typography/ContentTitle';
import Input from '../core/typography/Input';
import Text from '../core/typography/Text';
import MultiRangeSlider from '../multirange/MultiRangeSlider';
import SelectInput from '../core/typography/SelectInput';
import { IconContext } from 'react-icons';
import { HiOutlineSearch } from 'react-icons/hi';
import FilterInput from '../core/typography/FilterInput';

const category = [
	{ value: '', label: 'Choose a category' },
	{ value: '', label: 'Residential' },
	{ value: '', label: 'Commercial' },
	{ value: '', label: 'Apartments' },
];
const tags = [
	'app',
	'administrative',
	'android',
	'React Js',
	'design',
	'react',
];

const JobsFilter = () => {
	return (
		<>
			<div className="inner-column">
				<div className="filters-outer border border-dark rounded-4">
					<div className="filter-block">
						<ContentTitle contentTitle={`Search by Keywords`} />
						<div className="form-group  ">
							<IconContext.Provider
								value={{
									size: '2.4rem',
									color: '#5c6770',
									className: 'icon',
								}}
							>
								<HiOutlineSearch />
							</IconContext.Provider>
							<FilterInput
								type={'text'}
								name={'jobsearch'}
								placeholder={`Job title, keywords, or company`}
							/>
						</div>
					</div>
					<div className="filter-block">
						<ContentTitle contentTitle={`Location`} />

						<div className="form-group">
							<IconContext.Provider
								value={{
									size: '2.4rem',
									color: '#5c6770',
									className: 'icon',
								}}
							>
								<FiMapPin />
							</IconContext.Provider>
							<FilterInput
								type={'text'}
								name={'location'}
								placeholder={`Location`}
							/>
						</div>
						<Text text={`Radius around selected destination`} />
						<div className="range-slider-one">
							<MultiRangeSlider
								min={0}
								max={1000}
								onChange={({ min, max }) =>
									console.log(`min = ${min}, max = ${max}`)
								}
							/>
							<div className="input-outer pt-5">
								<div className="amount-outer">
									<span className="area-amount">100 </span>
									km
								</div>
							</div>
						</div>
					</div>
					<div className="filter-block">
						<ContentTitle contentTitle={`Category`} />

						<div className="form-group">
							<SelectInput options={category} />
							<span className="icon">
								<GoBriefcase />
							</span>
						</div>
					</div>
					<div className="switchbox-outer">
						<ContentTitle contentTitle={`Job type`} />

						<ul className="switchbox">
							<li>
								<label className="switch">
									<input type="checkbox" value="freelancer" />
									<span className="slider-check round "></span>
									<span className="text">Freelancer</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="full-time" />
									<span className="slider-check round"></span>
									<span className="text">Full Time</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="part-time" />
									<span className="slider-check round"></span>
									<span className="text">Part Time</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="temporary" />
									<span className="slider-check round"></span>
									<span className="text">Temporary</span>
								</label>
							</li>
						</ul>
					</div>
					<div className="checkbox-outer">
						<ContentTitle contentTitle={`Date Posted`} />

						<ul className="radio-checkbox">
							<li>
								<label>
									<input type="radio" value="all" />
									<span></span>
									<p>All</p>
								</label>
							</li>
							<li>
								<label>
									<input type="radio" value="last-hour" />
									<span></span>
									<p>Last Hour</p>
								</label>
							</li>
							<li>
								<label>
									<input type="radio" value="last-24-hour" />
									<span></span>
									<p>Last 24 Hour</p>
								</label>
							</li>
							<li>
								<label>
									<input type="radio" value="last-7-days" />
									<span></span>
									<p>Last 7 Days</p>
								</label>
							</li>
							<li>
								<label>
									<input type="radio" value="last-14-days" />
									<span></span>
									<p>Last 14 Days</p>
								</label>
							</li>
							<li>
								<label>
									<input type="radio" value="last-30-days" />
									<span></span>
									<p>Last 30 Days</p>
								</label>
							</li>
						</ul>
					</div>
					<div className="checkbox-outer">
						<ContentTitle contentTitle={`Experience Level`} />

						<ul className="switchbox">
							<li>
								<label className="switch">
									<input type="checkbox" value="fresh" />
									<span className="slider-check round "></span>
									<span className="text">Fresh</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="1-year" />
									<span className="slider-check round "></span>
									<span className="text">1 Year</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="2-year" />
									<span className="slider-check round "></span>
									<span className="text">2 Year</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="3-year" />
									<span className="slider-check round "></span>
									<span className="text">3 Year</span>
								</label>
							</li>
							<li>
								<label className="switch">
									<input type="checkbox" value="4-year" />
									<span className="slider-check round "></span>
									<span className="text">4 Year</span>
								</label>
							</li>
							<li>
								<button className="view-more">
									<i className="icon ">
										<FiPlusCircle />
									</i>
									View More
								</button>
							</li>
						</ul>
					</div>
					<div className="filter-block">
						<ContentTitle contentTitle={`Salary`} />

						<div className="range-slider-one salary-range">
							<MultiRangeSlider
								min={0}
								max={1000}
								onChange={({ min, max }) =>
									console.log(`min = ${min}, max = ${max}`)
								}
							/>
							<div className="input-outer pt-5">
								<div className="amount-outer">
									<span className="d-inline-flex align-items-center">
										<span className="min">$0</span>
										<span className="max ms-2">$20000</span>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className="filter-block">
						<ContentTitle contentTitle={`Tags`} />

						<div className="tag-list pt-3 position-relative d-flex align-items-center flex-wrap ">
							{tags.map((tag) => (
								<div key={tag} className="tag-item">
									<a href="/blog-list-v1#">{tag}</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default JobsFilter;

import React from 'react';
import recruitingImg from '../../../assets/images/recruitingImg.png';
const RecruitingSideImg = () => {
	return (
		<div>
			<img src={recruitingImg} alt="resource" />
		</div>
	);
};

export default RecruitingSideImg;

import React from 'react';

const LabelText = ({ labelText, id }) => {
	return (
		<>
			<label htmlFor={id}>{labelText}</label>
		</>
	);
};

export default LabelText;

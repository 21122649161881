import React, { useEffect, useState } from 'react';
import Title from '../../components/core/typography/Title';
import SelectInput from '../../components/core/typography/SelectInput';
import { useTable } from 'react-table';
import { FaSearchPlus } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { IconContext } from 'react-icons';
import {
	fetchResumeAlerts,
	fetchTimelineData,
} from '../../API/ResumeAlertsApi';
const timeline = [
	{ value: '', label: 'Last 1 month' },
	{ value: '', label: 'Last 3 months' },
	{ value: '', label: 'Last 6 months' },
	{ value: '', label: 'All' },
];
const ResumeAlerts = () => {
	const [data, setData] = useState([]);
	const [timelineOptions, setTimelineOptions] = useState([]);
	// fetching api

	useEffect(() => {
		// Fetch resume alerts data
		fetchResumeAlerts().then((data) => {
			setData(data);
		});

		// Fetch timeline data
		fetchTimelineData().then((data) => {
			setTimelineOptions(data);
		});
	}, []);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Title',
				accessor: 'title', // accessor is the "key" in the data
			},
			{
				Header: 'Alert Query',
				accessor: 'query',
			},
			{
				Header: 'Number Jobs',
				accessor: 'jobs',
			},
			{
				Header: 'Time',
				accessor: 'time',
			},
			{
				Header: 'Actions',
				accessor: 'actions',
			},
		],
		[],
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data });

	// Functionality

	const deleteRow = (indexToDelete) => {
		setData(data.filter((_, index) => index !== indexToDelete));
	};
	return (
		<div>
			<section className=" applicants resume-alerts">
				<div className="container-lg container-md container-sm pt-3 ">
					<div className="title-section  d-flex justify-content-between px-5">
						<Title title={`Resume Alerts`} />
						<div className="pt-5">
							<SelectInput options={timeline} />
						</div>
					</div>
					<div className="pt-5 mt-4" style={{ overflowX: 'auto' }}>
						<table
							{...getTableProps()}
							style={{
								margin: '0 auto',
								width: '80%',
								border: '.2rem solid var(--filter-color)',
							}}
						>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps()}
												className="text-center"
											>
												{column.render('Header')}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map((row, rowIndex) => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => (
												<td {...cell.getCellProps()}>
													{cell.column.id ===
													'actions' ? (
														<div className="d-flex">
															<div className="me-4">
																<IconContext.Provider
																	value={{
																		color: 'var(--common-color)',
																	}}
																>
																	<FaSearchPlus
																		style={{
																			cursor: 'pointer',
																		}}
																	/>
																</IconContext.Provider>
															</div>
															<div>
																<IconContext.Provider
																	value={{
																		color: 'red',
																	}}
																>
																	<FaTrash
																		style={{
																			cursor: 'pointer',
																		}}
																		onClick={() =>
																			deleteRow(
																				rowIndex,
																			)
																		}
																	/>
																</IconContext.Provider>
															</div>
														</div>
													) : (
														cell.render('Cell')
													)}
												</td>
											))}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ResumeAlerts;

import React from 'react';
import ContentTitle from '../../components/core/typography/ContentTitle';
import Text from '../../components/core/typography/Text';
import CompanyOveriview from '../../components/jobs/CompanyOveriview';
import JobOverview from '../../components/jobs/JobOverview';
import CompanyTitle from '../company/CompanyTitle';
import SubTitle from '../../components/core/typography/SubTitle';
import Title from '../../components/core/typography/Title';

const Interview = () => {
	const jobSkills = [
		{
			skill: 'App',
		},
		{
			skill: 'Administrative',
		},
		{
			skill: 'Android',
		},
		{
			skill: 'WordPress',
		},
		{
			skill: 'React Js',
		},
		{
			skill: 'Node Js',
		},
	];
	return (
		<>
			<section className=" container company-details">
				<div className="container-lg container-md container-sm">
					<div className="title-section pb-5">
						<div className="container mb-5 px-5">
							<Title title={`Interview!`} />
						</div>
						<div className=" container rounded d-flex align-items-center">
							<CompanyTitle />
						</div>
					</div>
					<div className="job-detail-outer container position-relative pt-5">
						<div className="auto-container container">
							<div className="row">
								<div className="content-column col-lg-7 col-md-12 col-sm-12">
									<div className="job-detail position-relative">
										<ContentTitle
											contentTitle={`About Company`}
											size={'3rem'}
										/>
										<div className="pt-4">
											<Text
												text={`Moody’s Corporation, often referred to as Moody’s,
												is an American business and financial services company. 
												It is the holding company for Moody’s Investors Service (MIS), 
												an American credit rating agency, and Moody’s Analytics (MA), 
												an American provider of financial analysis software and services.
												Moody’s was founded by John Moody in 1909 to produce manuals of statistics 
												related to stocks and bonds and bond ratings.
												Moody’s was acquired by Dun & Bradstreet in 1962. In 2000, Dun & Bradstreet spun off Moody’s Corporation as 
												a separate company that was listed on the NYSE under MCO. In 2007, Moody’s 
												Corporation was split into two operating divisions, Moody’s Investors Service, 
												the rating agency, and Moody’s Analytics, with all of its other products.`}
											/>
										</div>
									</div>
								</div>
								<div className="sidebar-column col-lg-5 col-md-12 col-sm-12 position-relative">
									<aside className="sidebar">
										<JobOverview />
										<div className=" sidebar-widget mt-5 ">
											<ContentTitle
												contentTitle={`Job Skills`}
												color={`var(--text-color)`}
											/>
											<div>
												<div class="widget-content pt-4">
													<div class="job-skills ">
														{jobSkills.map(
															(elem, key) => {
																return (
																	<>
																		<div className="d-flex">
																			<Text
																				text={
																					elem.skill
																				}
																			/>
																		</div>
																	</>
																);
															},
														)}
													</div>
												</div>
											</div>
										</div>
										<CompanyOveriview />
									</aside>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Interview;

import React, { useState, Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Input from '../core/typography/Input';
import LabelText from '../core/typography/LabelText';
import Text from '../core/typography/Text';
import { IconContext } from 'react-icons';
import { FaCaretUp } from 'react-icons/fa6';
import SubText from '../core/typography/SubText';
import UploaddocImg from '../core/img/UploaddocImg';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import ButtonPrimary from '../core/buttons/ButtonPrimary';
import ButtonSecondary from '../core/buttons/ButtonSecondary';
import Caption from '../core/typography/Caption';
import TextArea from '../core/typography/TextArea';
import CheckBox from '../core/typography/CheckBox';
import UploadDocument from '../core/typography/UploadDocument';
import UploadImg from '../core/img/UploadImg';

const AddBlogForm = () => {
	// Define and initialize state variables
	const [title, setTitle] = useState('');
	const [key, setKey] = useState('first');
	const [formErrors, setFormErrors] = useState({});

	const validateForm = () => {
		let errors = {};

		// Validate the title
		if (!title.trim()) {
			errors.title = 'Title is required';
		}

		// Extend this function to validate other fields as required

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Return true if no errors
	};
	// Define the submit handler for the form
	const handleSubmit = (e) => {
		e.preventDefault();

		if (validateForm()) {
			const blogPost = { title };

			// Save this blog post to console for now
			console.log(blogPost);
		}
	};

	// Define the categories for the blog
	const categories = [
		'Banner Updates',
		'Banner Updates',
		'Banner Updates',
		'Banner Updates',
	];

	// Return the JSX for the component
	return (
		<Fragment>
			{/* Define the row for the form */}

			<div className="row add-blog-form container">
				{/* Define the left column of the form */}
				<div className="col-lg-9">
					{/* Define the form and its fields */}
					<form onSubmit={handleSubmit}>
						{/* Blog title input */}
						<Input
							type={'text'}
							label={'Blog title'}
							id={'floatingTitle'}
							name={'blogtitle'}
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
						{/* Display the title validation error if it exists */}
						{formErrors.title && (
							<p className="text-danger">{formErrors.title}</p>
						)}
						<div className="border border-secondary p-3">
							<div className="d-flex justify-content-between border-bottom border-secondary ">
								<LabelText labelText={'Post Content'} />
								<IconContext.Provider
									value={{ size: '2rem', color: '#fff' }}
								>
									<FaCaretUp />
								</IconContext.Provider>
							</div>
							<div className="pt-4">
								<LabelText labelText={'Header Image'} />
								<SubText
									subText={'Minimum image size 1440 x 810'}
								/>
								<UploadDocument
									acceptTypes=".jpg,.png"
									buttonText="Upload Image"
									showCaption={true}
									UploadImgComponent={UploadImg}
								/>

								<div className="pt-2 pb-4 border-bottom border-secondary">
									<LabelText labelText={'Social Intro'} />
									<TextArea placeholder={'Write blog...'} />
								</div>
								<div className="pt-3">
									<LabelText labelText={'Share Text'} />
									<SubText
										subText={
											'Used when sharing; Defaults to article title'
										}
									/>
									<div className="pt-3">
										<Input
											type={'text'}
											id={'floatingText'}
											name={'sharetext'}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				{/* Define the right column of the form */}
				<div className="col-lg-3">
					<div className="border border-secondary p-3">
						<div className="d-flex justify-content-between border-bottom border-secondary ">
							<LabelText labelText={'Post Content'} />
							<IconContext.Provider
								value={{ size: '2rem', color: '#fff' }}
							>
								<FaCaretUp />
							</IconContext.Provider>
						</div>
						<div className="pt-5">
							{/* ... Control panel ... */}
							<div className="d-flex justify-content-between">
								<ButtonPrimary text={'Save Draft'} />
								<ButtonPrimary text={'Preview'} />
							</div>

							<div className="d-flex align-items-center pt-3 pb-3 border-bottom border-secondary ">
								<Text text={'Status'} />
								<span className="mx-2 me-2">:</span>
								<Text text={'Active'} />
							</div>
							<div className="d-flex justify-content-between pt-3 pb-3 ">
								<ButtonSecondary text={'Move to Trash'} />
								<ButtonPrimary text={'Publish'} />
							</div>
						</div>
					</div>
					<div className="border border-secondary p-3 mt-5">
						{/* ... Categories ... */}
						<div className="d-flex justify-content-between border-bottom border-secondary ">
							<LabelText labelText={'Categories'} />
							<IconContext.Provider
								value={{ size: '2rem', color: '#fff' }}
							>
								<FaCaretUp />
							</IconContext.Provider>
						</div>
						<div className="pt-2">
							<Tabs
								id="controlled-tab-example"
								activeKey={key}
								onSelect={(k) => setKey(k)}
								className="tabs"
							>
								<Tab eventKey="first" title="All Categories">
									<div className="pt-3">
										{categories.map((category, index) => (
											<div className="d-flex" key={index}>
												<CheckBox />
												<LabelText
													labelText={category}
												/>
											</div>
										))}
									</div>
								</Tab>
								<Tab eventKey="second" title="Most Used">
									<div className="pt-3">
										{categories.map((category, index) => (
											<div className="d-flex" key={index}>
												<CheckBox />
												<LabelText
													labelText={category}
												/>
											</div>
										))}
									</div>
								</Tab>
							</Tabs>
						</div>
					</div>
					<div className="border border-secondary p-3 mt-5">
						<div className="d-flex justify-content-between border-bottom border-secondary ">
							<LabelText labelText={'Post Content'} />
							<IconContext.Provider
								value={{ size: '2rem', color: '#fff' }}
							>
								<FaCaretUp />
							</IconContext.Provider>
						</div>
						<div className="d-flex flex-column  justify-content-center align-items-center pt-5">
							{/* Define the file upload for the blog */}
							<div className="pb-3">
								<UploadDocument
									acceptTypes=".pdf,.doc"
									buttonText="Upload File"
									// showCaption={true}
									UploadImgComponent={UploaddocImg}
								/>
							</div>
							<Caption
								caption={`To upload file size is (Max 5Mb) and allowed file types are .pdf, doc`}
								size={`1.5rem`}
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default AddBlogForm;

// // ACTUAL API
// import axios from 'axios';

// export const fetchCounterData = async () => {
// 	try {
// 		const response = await axios.get('https://api.example.com/counters');
// 		return response.data;
// 	} catch (error) {
// 		console.error("Error fetching counters:", error);
// 		throw error;
// 	}
// };

export const fetchCounterData = () => {
	return Promise.resolve({
		counters: [
			{
				icon: 'ImProfile',
				value: '25000 +',
				title: 'Profiles Reviewed',
			},
			{
				icon: 'PiUserGear',
				value: '2000 +',
				title: 'Profiles Interviewed',
			},
			{
				icon: 'FiUserCheck',
				value: '750 +',
				title: 'Candidates Placed',
			},
			{
				icon: 'PiBuildingsBold',
				value: '275',
				title: 'Companies',
			},
		],
	});
};

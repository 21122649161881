// applicantsApi.js
import axios from 'axios';
import BlogProfile from '../components/core/img/BlogProfile';

const mockApplicants = [
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
	{
		applicantImg: <BlogProfile />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		hoursAgo: '11 hours ago',
		salary: '2LPA - 5LPA',
		remote: 'WFH',
		joining: 'Urgent',
		workType: 'Full Time',
	},
];

export async function fetchApplicants() {
	try {
		const response = await new Promise((resolve) => {
			setTimeout(() => {
				resolve({ data: mockApplicants });
			}, 1000);
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching applicants:', error);
		throw new Error('Error fetching applicants');
	}
}

import React from 'react';
import Title from '../../components/core/typography/Title';
import Text from '../../components/core/typography/Text';
import SubTitle from '../../components/core/typography/SubTitle';

import ContentTitle from '../../components/core/typography/ContentTitle';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import { Link } from 'react-router-dom';
import FAQImg from '../../components/core/img/FAQImg';
import ContactForm from '../../components/forms/ContactForm';
import AboutImg from '../../components/core/img/AboutImg';
import Company from '../../assets/images/aboutimages/Company.png';
import Team from '../../assets/images/aboutimages/Team.png';

function About() {
	return (
		<>
			<div className=" about-container background">
				<div className="title-section mb-5 pb-5">
					<Title title={'About Us'} />
					<ContentTitle
						contentTitle={
							'Your bridge to the perfect job opportunity!'
						}
					/>
				</div>
				<div className="row mb-3">
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="story-section w-50 pt-5 d-flex flex-column justify-content-end ">
							<SubTitle subTitle={'Our Story'} />
							<div className="pt-5">
								<Text
									text={`Founded in 2023, XYZ Consultancy emerged from a single,
					powerful idea: that everyone deserves the perfect job. In
					today's competitive world, finding the right fit can be
					challenging, both for the job seeker and the employer. As a
					budding startup, we're determined to change that narrative.`}
								/>
							</div>
						</div>
					</div>
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center ">
						<div className="w-50">
							<AboutImg
								src={Company}
								alt="Description of Image"
								className="some-bootstrap-class-or-custom-class"
							/>
						</div>
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="w-50">
							<AboutImg
								src={Company}
								alt="Description of Image"
								className="some-bootstrap-class-or-custom-class"
							/>
						</div>
					</div>
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="values-section ">
							<SubTitle subTitle={'Core Values'} />2 / 2
							<div className="pt-5"></div>
							<div class="bullet-item d-flex align-items-center">
								<span class="bullet-title pe-2">
									<ContentTitle
										contentTitle={`Integrity: `}
									/>
								</span>
								<Text
									text={`We ensure
					transparent and honest communication.`}
								/>
							</div>
							<div class="bullet-item d-flex align-items-center">
								<span class="bullet-title pe-2">
									<ContentTitle contentTitle={`Passion: `} />
								</span>
								<Text
									text={`Committed in
					heart and mind to help you succeed.`}
								/>
							</div>
							<div class="bullet-item d-flex align-items-center">
								<span class="bullet-title pe-2">
									<ContentTitle
										contentTitle={`Collaboration: `}
									/>
								</span>
								<Text
									text={`We believe
					in the power of collective brilliance`}
								/>
							</div>
							<div class="bullet-item d-flex align-items-center">
								<span class="bullet-title pe-2">
									<ContentTitle
										contentTitle={`Innovation: `}
									/>
								</span>
								<Text
									text={`New ways to
					solve your challenges and seize opportunities.`}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="team-section w-50">
							<SubTitle subTitle={'Meet the Team'} />
							<div className="pt-5 pb-5">
								<Text
									text={`Our team comprises a diverse group of professionals who come
					from various backgrounds but share a common goal - to
					redefine the recruitment process. From HR experts to tech
					enthusiasts, every member of our team plays a pivotal role
					in ensuring your success.`}
								/>
							</div>
							<ButtonPrimary text={`Know more`} />
						</div>
					</div>
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="w-50">
							<AboutImg
								src={Team}
								alt="Description of Image"
								className="some-bootstrap-class-or-custom-class"
							/>
						</div>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="w-50">
							<AboutImg
								src={Company}
								alt="Description of Image"
								className="some-bootstrap-class-or-custom-class"
							/>
						</div>
					</div>
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="goals-section w-50">
							<SubTitle subTitle={'Our Goals '} />
							<div className="pt-5">
								<Text
									text={`As we pave our journey forward, our goals are clear. We aim
					to revolutionize the job market, offer unparalleled support
					to both job seekers and employers, and create a global
					footprint in the consultancy domain. We are here for the
					long haul, and we're just getting started!`}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
						<div className="contact-section w-50">
							<SubTitle subTitle={'Connect with Us'} />
							<div className="pt-5 pb-5">
								<Text
									text={`If our vision resonates with you or if you're looking for
					the perfect job opportunity, drop us a message. We're
					excited to hear from you!`}
								/>
							</div>
							<Link to={`mailto:info@xyzconsultancy.com`}>
								<ButtonPrimary text={`Email Us`} />
							</Link>
						</div>
					</div>
					<div className="col-lg-6 ">
						<ContactForm />
					</div>
				</div>
			</div>
		</>
	);
}

export default About;

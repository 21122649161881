// applicantsData, bookingsDetails, NoOfInterviews, and months were given in your original code.
const applicantsData = [
	{
		name: 'Applicant 1',
		jobTitle: 'Job Title 1',
		date: '31-07-23',
		time: '5:00PM',
		imgUrl: 'image1_url',
	},
	{
		name: 'Applicant 2',
		jobTitle: 'Job Title 2',
		date: '31-07-23',
		time: '5:00PM',
		imgUrl: 'image2_url',
	},
	{
		name: 'Applicant 3',
		jobTitle: 'Job Title 3',
		date: '31-07-23',
		time: '5:00PM',
		imgUrl: 'image3_url',
	},
	{
		name: 'Applicant 4',
		jobTitle: 'Job Title 4',
		date: '31-07-23',
		time: '5:00PM',
		imgUrl: 'image4_url',
	},
	{
		name: 'Applicant 5',
		jobTitle: 'Job Title 5',
		date: '31-07-23',
		time: '5:00PM',
		imgUrl: 'image5_url',
	},
];
const NoOfInterviews = [
	{ value: '', label: '1' },
	{ value: '', label: '2' },
	{ value: '', label: '3' },
	{ value: '', label: '4' },
];
const months = [
	{ value: '', label: 'This month: August' },
	{ value: '', label: 'This month: August' },
	{ value: '', label: 'This month: August' },
	{ value: '', label: 'This month: August' },
];

const bookingsDetails = [
	{
		name: 'undefined, 21:00 - 22:00',
		dateFrom: '2023-03-02T21:00',
		dateTo: '2023-03-02T22:00',
		id: 42,
		memberID: undefined,
		meta: 'Marketing',

		serviceID: 16,
		type: 'BOOKED',

		// url:"/profile/startup/order/"+row.id
	},
];
export const getApplicantsData = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(applicantsData);
		}, 500);
	});
};

export const getBookingsDetails = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(bookingsDetails);
		}, 500);
	});
};

export const getNoOfInterviews = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(NoOfInterviews);
		}, 500);
	});
};

export const getMonths = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(months);
		}, 500);
	});
};

import React from 'react';
import blogImg from '../../../assets/images/blogImg/blog-3.jpg';

const HRImage = () => {
	return (
		<div>
			<div>
				<img
					src={blogImg}
					width="50"
					height="50"
					class="rounded-circle me-4"
					alt="resource"
				/>
			</div>
		</div>
	);
};

export default HRImage;

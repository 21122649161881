import React from 'react';
import UploadDoc from '../../../assets/images/uploadImg/upload-folder.png';
const UploaddocImg = () => {
	return (
		<div>
			<img src={UploadDoc} width="60rem" height="60rem" />
		</div>
	);
};

export default UploaddocImg;

import React, { useState } from 'react';
import Input from '../core/typography/Input';
import TextArea from '../core/typography/TextArea';
import ButtonPrimary from '../core/buttons/ButtonPrimary';

const ContactForm = () => {
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		message: '',
	});

	const [formErrors, setFormErrors] = useState({
		username: '',
		email: '',
		message: '',
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

		// Form validation
		let errors = formErrors;
		switch (name) {
			case 'email':
				errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
					? ''
					: 'Email is not valid!';
				break;
			// Add more validation if necessary
			default:
				break;
		}

		setFormData({ ...formData, [name]: value });
		setFormErrors(errors);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// Ensure all fields are valid before submitting
		if (isValid(formErrors)) {
			console.log('Form data submitted:', formData);
		} else {
			console.log('Form has errors.');
		}
	};

	const isValid = (errors) => {
		let valid = true;
		Object.values(errors).forEach(
			(val) => val.length > 0 && (valid = false),
		);
		return valid;
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Input
					type={`text`}
					label={`Your Name`}
					id={`floatingName`}
					name={`username`}
					value={formData.username}
					onChange={handleChange}
				/>
				{formErrors.username && <span>{formErrors.username}</span>}

				<Input
					type={`email`}
					label={`Email `}
					id={`floatingEmail`}
					name={`email`}
					value={formData.email}
					onChange={handleChange}
				/>
				{formErrors.email && <span>{formErrors.email}</span>}

				<TextArea
					placeholder={`Message`}
					value={formData.message}
					onChange={handleChange}
				/>

				<div className="pt-5">
					<ButtonPrimary text={`Submit`} />
				</div>
			</form>
		</>
	);
};

export default ContactForm;

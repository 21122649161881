import React from 'react';

const SubText = ({ subText, color, size }) => {
	const subTextStyle = {
		color,
		fontSize: size,
	};
	return (
		<>
			<p className="sub-text" style={{ ...subTextStyle }}>
				{subText}
			</p>
		</>
	);
};

export default SubText;

import React from 'react';
import PropTypes from 'prop-types';

const AboutImg = ({ src, alt = '', className = '', ...rest }) => {
	return (
		<img
			src={src}
			alt={alt}
			className={className}
			{...rest}
			width={'500'}
			height={'500'}
		/>
	);
};

AboutImg.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string,
	className: PropTypes.string,
};

export default AboutImg;

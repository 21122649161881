import React from 'react';
import blogImg from '../../../assets/images/blogImg/blog-3.jpg';
const BlogProfile = () => {
	return (
		<div>
			<img
				src={blogImg}
				width="50"
				height="50"
				class="rounded-circle me-4"
				alt="resource"
			/>
		</div>
	);
};

export default BlogProfile;

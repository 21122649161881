import React from 'react';
import logo from '../../../assets/images/logo.png';

const MenteechainLogo = () => {
	return (
		<>
			<img
				src={logo}
				alt=""
				width="55"
				height="50"
				className="d-inline-block align-text-top"
			/>
		</>
	);
};

export default MenteechainLogo;

import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaArrowUp } from 'react-icons/fa';

const BackToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	const checkScrollTop = () => {
		if (!isVisible && window.scrollY > 300) {
			setIsVisible(true);
		} else if (isVisible && window.scrollY <= 300) {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', checkScrollTop);
		return () => window.removeEventListener('scroll', checkScrollTop); // Cleanup listener on unmount
	}, [isVisible]);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return isVisible ? (
		<button className="back-to-top" onClick={scrollToTop}>
			<IconContext.Provider value={{ size: '2rem', color: 'black' }}>
				<FaArrowUp />
			</IconContext.Provider>
		</button>
	) : null;
};

export default BackToTop;

import React from 'react';
import LogoSlider from './LogoSlider';
import SubTitle from '../core/typography/SubTitle';

const ClientsSection = () => {
	return (
		<div>
			<section id="clients" className="client-section">
				<div className="container">
					<div className="text-center">
						<SubTitle subTitle={`Our Clients`} color={`gray`} />
					</div>

					<section className="customer-logos container">
						<LogoSlider />
					</section>
				</div>
			</section>
		</div>
	);
};

export default ClientsSection;

import React from 'react';
import CompaniesLogo from '../core/img/CompaniesLogo';

const CompanyOveriview = () => {
	return (
		<div>
			<div class="sidebar-widget company-widget">
				<div class="widget-content">
					<div class="company-title">
						<div class="company-logo">
							<CompaniesLogo />
						</div>
						<div>
							<h5 class="company-name">Segment</h5>
							<a href="#" class="profile-link">
								View company profile
							</a>
						</div>
					</div>
					<ul class="company-info">
						<li>
							Primary industry:
							<span>Software</span>
						</li>
						<li>
							Company size:
							<span>501-1,000</span>
						</li>
						<li>
							Founded in: <span>2011</span>
						</li>
						<li>
							Phone: <span>123 456 7890</span>
						</li>
						<li>
							Email:
							<span>info@joio.com</span>
						</li>
						<li>
							Location:
							<span>London, UK</span>
						</li>
						<li>
							Social media:
							<div class="social-links">
								<a
									href="https://www.instagram.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i class="fab fa-instagram"></i>
								</a>
								<a
									href="https://www.linkedin.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i class="fab fa-linkedin-in"></i>
								</a>
							</div>
						</li>
					</ul>
					<div class="btn-box mb-0 text-center position-relative">
						<a
							href="#"
							target="_blank"
							rel="noopener noreferrer"
							class="theme-btn btn-style-three"
						>
							https://segment.com
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyOveriview;

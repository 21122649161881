import React, { useState } from 'react';
import { Modal, Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
	FaGoogle,
	FaLinkedinIn,
	FaTwitter,
	FaUserCircle,
} from 'react-icons/fa';
import { TbMenuDeep } from 'react-icons/tb';
import MenteechainLogo from '../img/MenteechainLogo';
import Text from '../typography/Text';
import SubTitle from '../typography/SubTitle';
import SubText from '../typography/SubText';
import LoginForm from '../../forms/LoginForm';
import RegisterForm from '../../forms/RegisterForm';
import ButtonPrimary from '../buttons/ButtonPrimary';
import { IconContext } from 'react-icons';
import ButtonSecondary from '../buttons/ButtonSecondary';
import { HiMenu } from 'react-icons/hi';
// component for modal Content
const ModalContent = ({ title, FormComponent, switchText, switchModal }) => {
	return (
		<div className="container py-2 h-75">
			<div className="row d-flex justify-content-center align-items-center h-100">
				<div className="col-lg-12  ">
					<div
						className="card bg-dark text-white"
						style={{ borderRadius: '1rem' }}
					>
						<div className="card-body p-4 text-center">
							<div className="mb-md-2 mt-md-3 pb-2">
								<div className="container px-4 ">
									<div className="container d-flex justify-content-between mb-3">
										<div>
											<MenteechainLogo />
										</div>
										<div>
											<SubTitle
												subTitle={`Welcome to MenteeChain`}
											/>
										</div>
									</div>
									{/* Form Component */}
									<FormComponent />
									{/* Social Icons */}
									<div className="icons d-flex justify-content-center text-center mt-3">
										<IconContext.Provider
											value={{
												size: '2rem',
												color: '#fff',
											}}
										>
											<FaLinkedinIn className="icon mx-2" />
											<FaTwitter className="icon mx-2" />
											<FaGoogle className="icon mx-2" />
										</IconContext.Provider>
									</div>
								</div>
							</div>
							<div className="text-center d-flex justify-content-center align-items-center">
								<SubText subText={switchText} />
								<Link
									onClick={switchModal}
									className="mx-2 text-decoration-none"
								>
									<Text text={title} />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Navbar = () => {
	// state for Login Modal
	const [showLoginModal, setShowLoginModal] = useState(false);
	// state for Signup Modal
	const [showRegisterModal, setShowRegisterModal] = useState(false);
	const [activeLink, setActiveLink] = useState(''); // function to open Modals
	const handleModalOpen = () => {
		setShowLoginModal(true);
	};
	return (
		<>
			<BootstrapNavbar bg="dark" expand="lg" fixed="top">
				<div className="container">
					{/* Company logo */}
					<Link to="/">
						<BootstrapNavbar.Brand>
							<MenteechainLogo />
						</BootstrapNavbar.Brand>
					</Link>
					<BootstrapNavbar.Toggle aria-controls="basic-navbar-nav">
						<IconContext.Provider
							value={{ size: '2.5rem', color: 'white' }}
							className="custom-navbar-toggle"
						>
							<HiMenu />
						</IconContext.Provider>
					</BootstrapNavbar.Toggle>
					<BootstrapNavbar.Collapse id="basic-navbar-nav">
						{/* Menu bar */}
						<Nav className="me-auto">
							<Link
								className={`nav-link ${
									activeLink === '/' ? 'active' : ''
								}`}
								to="/"
								onClick={() => setActiveLink('/')}
							>
								Home
							</Link>
							<a
								className={`nav-link ${
									activeLink === '#about' ? 'active' : ''
								}`}
								href="#about"
								onClick={() => setActiveLink('#about')}
							>
								About us
							</a>
							<a
								className={`nav-link ${
									activeLink === '#recent-jobs'
										? 'active'
										: ''
								}`}
								href="#recent-jobs"
								onClick={() => setActiveLink('#recent-jobs')}
							>
								Jobs
							</a>
							<a
								className={`nav-link ${
									activeLink === '#blogs' ? 'active' : ''
								}`}
								href="#blogs"
								onClick={() => setActiveLink('#blogs')}
							>
								Blogs
							</a>
							<a
								className={`nav-link ${
									activeLink === '#clients' ? 'active' : ''
								}`}
								href="#clients"
								onClick={() => setActiveLink('#clients')}
							>
								Clients
							</a>
							{/* <a
								className={`nav-link ${
									activeLink === '#services' ? 'active' : ''
								}`}
								href="#services"
								onClick={() => setActiveLink('#services')}
							>
								Services
							</a> */}
							{/* <Link
								className={`nav-link ${
									activeLink === '/faq' ? 'active' : ''
								}`}
								to="/faq"
								onClick={() => setActiveLink('/faq')}
							>
								FAQ
							</Link> */}
						</Nav>

						{/* Buttons  */}
						<div className="nav-btn d-flex flex-lg-row flex-md-column flex-column text-center justify-content-center align-items-center me-2">
							{/* <Link to={'/uploadcv'} className="me-3 ">
								<ButtonSecondary
									text={'Upload your Resume'}
									// onClick={uploadCvButtonClick}
								/>
							</Link> */}
							{/* ****************** */}
							{/* temporary */}
							{/* ****************** */}

							<Link to={'/uploadcv'} className="me-3 ">
								<ButtonPrimary
									text={'Upload your Resume'}
									// onClick={uploadCvButtonClick}
								/>
							</Link>
							{/* <div>
								<ButtonPrimary
									text={'Login / Register'}
									onClick={handleModalOpen}
									color={'var(--common-color)'}
									bgColor="#fff"
								/>
							</div> */}

							{/* <Link to={'/jobpost'} className="mx-3 my-md-3 my-3">
								<ButtonPrimary
									text={'JobPost'}
									// onClick={JobPostButtonClick}
								/>
							</Link> */}

							{/* <Link
								to={`/profile`}
								className="mx-3 d-flex align-items-center my-md-3"
							>
								<IconContext.Provider
									value={{ size: '3.5rem', color: '#fff' }}
								>
									<FaUserCircle />
								</IconContext.Provider>
							</Link> */}
						</div>
					</BootstrapNavbar.Collapse>
				</div>
			</BootstrapNavbar>

			{/* Login Modal */}
			<Modal
				show={showLoginModal}
				onHide={() => setShowLoginModal(false)}
			>
				<Modal.Body>
					<ModalContent
						title="Register"
						FormComponent={LoginForm}
						switchText="Don't have an account?"
						switchModal={() => {
							setShowLoginModal(false);
							setShowRegisterModal(true);
						}}
					/>
				</Modal.Body>
			</Modal>
			{/* Sign up Modal */}
			<Modal
				show={showRegisterModal}
				onHide={() => setShowRegisterModal(false)}
			>
				<Modal.Body>
					<ModalContent
						title="Login"
						FormComponent={RegisterForm}
						switchText="Already have an account?"
						switchModal={() => {
							setShowRegisterModal(false);
							setShowLoginModal(true);
						}}
					/>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Navbar;

import React, { Fragment, useEffect, useState } from 'react';
import SelectInput from '../../components/core/typography/SelectInput';
import ContentTitle from '../../components/core/typography/ContentTitle';
import { IconContext } from 'react-icons';
import SubText from '../../components/core/typography/SubText';
import Text from '../../components/core/typography/Text';
import { FaCheckCircle, FaTrash, FaUsers } from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import Title from '../../components/core/typography/Title';
import FilterInput from '../../components/core/typography/FilterInput';
import { HiOutlineSearch } from 'react-icons/hi';
import {
	fetchApplicantDetails,
	fetchCategories,
} from '../../API/ShortListedApi';
import Pagination from '../../components/pagination/Pagination';

const ITEMS_PER_PAGE = 10;
const ShortlistedResume = () => {
	const [applicants, setApplicants] = useState([]);
	const [categories, setCategories] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	useEffect(() => {
		const getApplicantDetails = async () => {
			try {
				const fetchedApplicantDetails = await fetchApplicantDetails();
				setApplicants(fetchedApplicantDetails);

				const fetchedCategories = await fetchCategories(); // <-- Fetch categories
				setCategories(fetchedCategories); // <-- Set categories to state
			} catch (error) {
				console.error('Failed to fetch data:', error);
			}
		};

		getApplicantDetails();
	}, []);

	const totalPages = Math.ceil(applicants.length / ITEMS_PER_PAGE); // Calculate total pages

	const paginatedApplicants = applicants.slice(
		(currentPage - 1) * ITEMS_PER_PAGE,
		currentPage * ITEMS_PER_PAGE,
	);

	return (
		<Fragment>
			<div className="container-lg container-md container-sm pt-3">
				<HeaderSection categories={categories} />
				<MainSection applicants={paginatedApplicants} />
				<div className="container pb-5">
					<Pagination
						currentPage={currentPage}
						totalPages={totalPages}
						onPageChange={(page) => setCurrentPage(page)}
					/>
				</div>
			</div>
		</Fragment>
	);
};

const HeaderSection = ({ categories }) => (
	<div className="shortlisted container">
		<div className="title-section px-5">
			<div className=" container-lg container-md container-sm">
				<Title title="Approved / Shortlisted Applicants" />
			</div>
			<SearchAndFilter categories={categories} />
		</div>
	</div>
);
const SearchAndFilter = ({ categories }) => (
	<div className="search-filter row d-flex justify-content-lg-end mb-5 g-4">
		<div className="form-group col-lg-3 col-md-8 col-sm-12">
			<IconContext.Provider
				value={{ size: '2.4rem', color: '#5c6770', className: 'icon' }}
			>
				<HiOutlineSearch />
			</IconContext.Provider>
			<FilterInput type="text" name="search" placeholder="Search" />
		</div>
		<div className="col-lg-2 ">
			<SelectInput options={categories} />
		</div>
	</div>
);
const MainSection = ({ applicants }) => (
	<section className="container pt-5 pb-5">
		<div className=" row d-flex rounded-5 mb-5">
			{applicants.map((detail, index) => (
				<div className="col-lg-6 col-md-12 col-sm-12 ">
					<ApplicantCard key={index} detail={detail} />
				</div>
			))}
		</div>
	</section>
);

const ApplicantCard = ({ detail }) => (
	<div className=" container col-lg-12 col-md-12 col-sm-12 p-3  ">
		<div className="applicant-card p-3 rounded-4 ">
			<div className="">
				<div className="d-flex container justify-content-between">
					<ContentTitle contentTitle={detail.title} />
					<div className=" ">
						<IconContext.Provider
							value={{
								size: '2rem',
								color: 'var(--common-color)',
							}}
						>
							<div>
								<FaCheckCircle />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				<div className="job-ad-item pt-2">
					<div className="d-flex">
						<div className="d-flex mx-4 align-items-center">
							<SubText
								subText={detail.designation}
								size={'1.5rem'}
							/>
						</div>
						<div className="d-flex mx-4 align-items-center">
							<SubText
								subText={detail.location}
								size={'1.5rem'}
							/>
						</div>
					</div>
				</div>
				<div className=" justify-content-lg-between align-items-lg-center">
					<div className="d-flex justify-content-around pt-4">
						<div className="joining">
							<Text text={detail.remote} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={detail.joining} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={detail.workType} size={`1.5rem`} />
						</div>
					</div>
					<div className="d-flex justify-content-end mt-4">
						<IconContext.Provider
							value={{
								size: '1.7rem',
								color: '#fff',
							}}
						>
							<div className="me-3">
								<FaUsers />
							</div>
							<div className="me-3">
								<AiFillCloseCircle />
							</div>
							<div>
								<FaTrash />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default ShortlistedResume;

import React from 'react';

const Caption = ({ caption, size, color }) => {
	const captionStyles = {
		fontSize: size,
		color: color,
	};
	return (
		<div>
			<h3 className="caption" style={{ ...captionStyles }}>
				{caption}
			</h3>
		</div>
	);
};

export default Caption;

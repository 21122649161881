import React from 'react';
import trainer from '../../../assets/images/blogImg/post1.jpg';
const TrainerImg = () => {
	return (
		<div>
			<img src={trainer} className="card-img-top" />
		</div>
	);
};

export default TrainerImg;

import React from 'react';
import CompaniesLogo from '../img/CompaniesLogo';
import ContentTitle from '../typography/ContentTitle';
import { FiMapPin } from 'react-icons/fi';
import SubText from '../typography/SubText';
import { IconContext } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { GoBriefcase } from 'react-icons/go';

const CompanyCard = ({
	companyName,
	companyLocation,
	companyField,
	companyJobs,
}) => {
	const subTextStyle = { color: 'var(--subtext-color)' };

	return (
		<div className="company-container job-item media">
			<a href="#">
				<div className="company-logo">
					<CompaniesLogo />
				</div>
			</a>
			<div className="media-body align-self-center">
				<Link to="/company">
					<ContentTitle contentTitle={companyName} />
				</Link>

				<div className="job-ad-item pt-3">
					<ul>
						<IconContext.Provider value={{ size: '1.8rem' }}>
							<li className="d-flex align-items-center">
								<FiMapPin />
								<div className="mx-2">
									<SubText
										subText={companyLocation}
										{...subTextStyle}
									/>
								</div>
							</li>
							<li className="d-flex align-items-center">
								<GoBriefcase />
								<div className="mx-2">
									<SubText
										subText={companyField}
										{...subTextStyle}
									/>
								</div>
							</li>
						</IconContext.Provider>
					</ul>
				</div>
				<div className="openings text-end">
					<SubText
						subText={`Open Jobs - ${companyJobs}`}
						color={'var(--common-color)'}
					/>
				</div>
			</div>
		</div>
	);
};

export default CompanyCard;

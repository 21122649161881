import React, { useState } from 'react';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import SelectInput from '../core/typography/SelectInput';
import TextArea from '../core/typography/TextArea';
import UploadDoc from '../core/typography/UploadResume';
import UploadDocument from '../core/typography/UploadDocument';
import UploaddocImg from '../core/img/UploaddocImg';

const JobPostForm = () => {
	// State to manage the form fields
	const [formData, setFormData] = useState({
		title: '',
		description: '',
		email: '',
		username: '',
		deadline: '',
		address: '',
		// ... other fields ...
	});

	// State for form validations
	const [formErrors, setFormErrors] = useState({});

	// Handle change for all inputs
	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	// select options
	const specialism = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Web Development' },
		{ value: '', label: 'Banking' },
		{ value: '', label: 'Full Stack Developer' },
	];
	const jobType = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Banking' },
		{ value: '', label: 'Digital & Creative' },
		{ value: '', label: 'Human Resources' },
		{ value: '', label: 'Management' },
	];
	const offerSalary = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Rs15000' },
		{ value: '', label: 'Rs20000' },
		{ value: '', label: 'Rs25000' },
		{ value: '', label: 'Rs30000' },
	];
	const careerLevel = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Banking' },
		{ value: '', label: 'Digital & Creative' },
		{ value: '', label: 'Human Resources' },
		{ value: '', label: 'Management' },
	];
	const experience = [
		{ value: '', label: 'Select' },
		{ value: '', label: '0-1years' },
		{ value: '', label: '1-3years' },
		{ value: '', label: '2-3years' },
		{ value: '', label: '4-6years' },
	];
	const gender = ['Select', 'Male', 'Female', 'Transgender'];
	const industry = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Banking' },
		{ value: '', label: 'Digital & Creative' },
		{ value: '', label: 'Human Resources' },
		{ value: '', label: 'Management' },
	];
	const qualification = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Graduate' },
		{ value: '', label: 'Post-Graduate' },
		{ value: '', label: 'Diploma' },
	];
	const country = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'America' },
		{ value: '', label: 'Australia' },
		{ value: '', label: 'Brazil' },
		{ value: '', label: 'Canada' },
		{ value: '', label: 'India' },
	];

	const city = [
		{ value: '', label: 'Select' },
		{ value: '', label: 'Gurugram' },
		{ value: '', label: 'Nagpur' },
		{ value: '', label: 'Gurgao' },
		{ value: '', label: 'Mumbai' },
	];

	// Basic validation check for form fields
	const validateForm = () => {
		let isValid = true;
		let errors = {};

		// Check if job title is provided
		if (!formData.title.trim()) {
			isValid = false;
			errors.title = 'Job title is required';
		}

		// Check if description is provided
		if (!formData.description.trim()) {
			isValid = false;
			errors.description = 'Job description is required';
		}

		// Email validation
		const emailPattern = /^\S+@\S+\.\S+$/;
		if (!emailPattern.test(formData.email.trim())) {
			isValid = false;
			errors.email = 'Invalid email address';
		}

		// Username validation
		if (!formData.username.trim()) {
			isValid = false;
			errors.username = 'Username is required';
		}

		// ... Add other validation checks as required ...

		setFormErrors(errors);
		return isValid;
	};

	// Handle form submission
	const handleSubmit = (event) => {
		event.preventDefault();
		if (validateForm()) {
			console.log('Form data submitted:', formData);
			// Proceed with form submission or any other operations
		}
	};
	return (
		<div>
			<form
				className="job-post-form default-form position-relative"
				onSubmit={handleSubmit}
			>
				<div className="row d-flex justify-content-between p-4">
					<div className="form-group col-lg-12 col-md-12">
						<LabelText labelText={`Job Title`} />
						<Input
							type={`text`}
							label={`Title`}
							id={`floatingTitle`}
							name={`title`}
							value={formData.title}
							onChange={handleChange}
						/>
						{formErrors.title && (
							<p className="text-danger">{formErrors.title}</p>
						)}
					</div>

					<div className="form-group col-lg-12 col-md-12">
						<LabelText labelText={`Job Description`} />
						<TextArea
							placeholder={`Spent several years working on ...`}
							name={`description`}
							value={formData.description}
							onChange={handleChange}
						/>
						{formErrors.description && (
							<p className="text-danger">
								{formErrors.description}
							</p>
						)}
					</div>

					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Email Address`} />
						<Input
							type={`text`}
							name={`email`}
							id={`floatingEmail`}
							label={`Email`}
							value={formData.email}
							onChange={handleChange}
						/>
						{formErrors.email && (
							<p className="text-danger">{formErrors.email}</p>
						)}
					</div>

					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Username`} />
						<Input
							type={`text`}
							name={`username`}
							id={`floatingName`}
							label={`Username`}
							value={formData.username}
							onChange={handleChange}
						/>
						{formErrors.username && (
							<p className="text-danger">{formErrors.username}</p>
						)}
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Specialism`} />

						<SelectInput options={specialism} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Job Type`} />
						<SelectInput options={jobType} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Offered Salary`} />
						<SelectInput options={offerSalary} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Career Level`} />

						<SelectInput options={careerLevel} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Experience`} />

						<SelectInput options={experience} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Gender`} />

						<SelectInput options={gender} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Industry`} />

						<SelectInput options={industry} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Qualification`} />
						<SelectInput options={qualification} />
					</div>
					<div className="form-group col-lg-12 col-md-12">
						<LabelText labelText={`Application Deadline Date`} />

						<Input
							type={`text`}
							name={`deadline`}
							id={`floatingDeadline`}
							label={`Deadline eg. 06.12.2023`}
							value={formData.deadline}
							onChange={handleChange}
						/>
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`Country`} />

						<SelectInput options={country} />
					</div>
					<div className="form-group col-lg-5 col-md-12 col-sm-12">
						<LabelText labelText={`City`} />

						<SelectInput options={city} />
					</div>
					<div className="form-group col-lg-12 col-md-12">
						<LabelText labelText={`Complete Address`} />

						<Input
							type={`text`}
							name={`address`}
							id={`floatingAddress`}
							label={`Gurugram, Delhi`}
							value={formData.address}
							onChange={handleChange}
						/>
						{formErrors.title && (
							<p className="text-danger">{formErrors.address}</p>
						)}
					</div>
					<LabelText labelText={`Upload Supported Documents`} />
					<div className="upload-doc uploading-resume">
						<UploadDocument
							acceptTypes=".pdf,.doc"
							buttonText="Upload Documents"
							showCaption={true}
							UploadImgComponent={UploaddocImg}
						/>
					</div>

					<div className="form-group col-lg-12 col-md-12 text-end">
						<ButtonTertiary text={'Post Job'} />
					</div>
				</div>
			</form>
		</div>
	);
};

export default JobPostForm;

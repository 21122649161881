// api.js

// Sample data for companies
const companiesData = [
	{
		id: 'BE1903',
		title: 'Software Engineer',
		forwardedAt: 'Forwarded At: 4 July - 1 Month',
	},
	{
		id: 'BE1904',
		title: 'Data Analyst',
		forwardedAt: 'Forwarded At: 5 July - 1 Month',
	},
	{
		id: 'BE1905',
		title: 'Front-end Developer',
		forwardedAt: 'Forwarded At: 6 July - 1 Month',
	},
];

const userDetails = {
	name: 'Sam Jon',
	role: 'Catalyst',
	location: 'Mumbai, India',
	lastActive: '11 Hour ago',
	salaryRange: '3LPA-$LPA',
	skills: ['App', 'Design', 'Digital'],
};

const jobStatusDetails = {
	jobId: '#B1232C',
	company: 'Udemy PVT LTD',
	location: 'Delhi, India',
	email: 'example@gmail.com',
	phone: '+91 9359565534',
	paymentMethod: 'Credit card',
	interviewDetails: {
		title: 'L1 level interview',
		time: '11:30 AM',
		date: 'July 30th, 2023',
		takenBy: 'Himanshu Shukla',
		status: 'Pass',
		message: 'Person Cleared 1st round, forwarded to second round',
	},
	hrMessage: {
		name: 'Kritika',
		content: 'Candidate is selected for second round',
	},
};

// Mock API calls

const fetchCompanies = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(companiesData);
		}, 1000);
	});
};

const fetchUserDetails = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(userDetails);
		}, 1000);
	});
};

const fetchJobStatusDetails = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(jobStatusDetails);
		}, 1000);
	});
};

export { fetchCompanies, fetchUserDetails, fetchJobStatusDetails };

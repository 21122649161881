import React from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import CompaniesLogo from '../../components/core/img/CompaniesLogo';
import SubText from '../../components/core/typography/SubText';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { IconContext } from 'react-icons';
const jobDetails = [
	{
		icon: GoBriefcase,
		text: 'Segment',
	},
	{
		icon: FiMapPin,
		text: 'Mumbai, India',
	},
	{
		icon: AiOutlineClockCircle,
		text: '11 Hour ago',
	},
	{
		icon: IoCashOutline,
		text: '3LPA-$LPA',
	},
];
const CompanyTitle = () => {
	return (
		<div className="container">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<CompaniesLogo />

					<div className="mx-3">
						<SubTitle subTitle="Software Engineer" />

						<div className="job-ad-item pt-2">
							<div className="d-flex flex-wrap">
								{jobDetails.map((detail, index) => (
									<div
										className="d-flex mx-3 align-items-center"
										key={index}
									>
										<IconContext.Provider
											value={{
												size: '1.7rem',
												color: 'var(--subtext-color)',
												className: 'me-2',
											}}
										>
											<detail.icon />
										</IconContext.Provider>
										<SubText
											subText={detail.text}
											size="1.7rem"
										/>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyTitle;

import React from 'react';
import uploadImage from '../../../assets/images/uploadImg/upload-arrows.png';

const UploadImg = () => {
	return (
		<>
			<img
				src={uploadImage}
				alt="upload-arrow"
				width={'50'}
				height={'50'}
			/>
		</>
	);
};

export default UploadImg;

import React from 'react';

const CheckBox = () => {
	return (
		<div>
			<input
				className="check-input text-center me-3 "
				type="checkbox"
				value=""
				id="flexCheckDefault"
			/>
		</div>
	);
};

export default CheckBox;

import React from 'react';

const SelectInput = ({ options, value, onChange }) => {
	return (
		<>
			<select
				className="chosen-single form-select"
				value={value}
				onChange={onChange}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</>
	);
};

export default SelectInput;

import React from 'react';
import client1 from '../../assets/images/clientsImg/client1.png';
import client2 from '../../assets/images/clientsImg/client2.png';
import client3 from '../../assets/images/clientsImg/client3.png';
import client4 from '../../assets/images/clientsImg/client4.png';
import client5 from '../../assets/images/clientsImg/client5.png';
import client6 from '../../assets/images/clientsImg/client6.png';
import client7 from '../../assets/images/clientsImg/client7.png';
import client8 from '../../assets/images/clientsImg/client8.png';
import client9 from '../../assets/images/clientsImg/client9.png';
import client10 from '../../assets/images/clientsImg/client10.png';
import client11 from '../../assets/images/clientsImg/client11.png';
import Slider from 'react-slick';

const LogoSlider = () => {
	const settings = {
		// dots: true,
		infinite: true,
		slidesToShow: 9,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2000,
		autoplaySpeed: 2000,
		cssEase: 'linear',

		responsive: [
			{
				breakpoint: 1024, // breakpoint for large devices
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768, // breakpoint for medium devices
				settings: {
					slidesToShow: 3, // adjust this number as needed
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480, // breakpoint for small devices
				settings: {
					slidesToShow: 2, // adjust this number as needed
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<>
			<div className="container slider-container">
				<div className="container">
					<Slider {...settings}>
						<div class="">
							<img src={client1} alt="logo" />
						</div>
						<div class="slide">
							<img src={client2} alt="logo" />
						</div>
						<div class="slide">
							<img src={client3} alt="logo" />
						</div>
						<div class="slide">
							<img src={client4} alt="logo" />
						</div>
						<div class="slide">
							<img src={client5} alt="logo" />
						</div>
						<div class="slide">
							<img src={client6} alt="logo" />
						</div>
						<div class="slide">
							<img src={client7} alt="logo" />
						</div>
						<div class="slide">
							<img src={client8} alt="logo" />
						</div>
						<div class="slide">
							<img src={client9} alt="logo" />
						</div>
						<div class="slide">
							<img src={client10} alt="logo" />
						</div>
						<div class="slide">
							<img src={client11} alt="logo" />
						</div>
					</Slider>
				</div>
			</div>
		</>
	);
};

export default LogoSlider;

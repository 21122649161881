import axios from 'axios';

const BASE_URL = 'https://fakestoreapi.com';

export const registerUser = async (userData) => {
	try {
		const response = await axios.post(`${BASE_URL}/users`, userData);
		if (response.data && response.data.id) {
			return response.data;
		} else {
			throw new Error('Failed to register. Please try again.');
		}
	} catch (error) {
		throw error;
	}
};

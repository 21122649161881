import React from 'react';
import Title from '../../components/core/typography/Title';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import SubText from '../../components/core/typography/SubText';
import ContentTitle from '../../components/core/typography/ContentTitle';
import HRImage from '../../components/core/img/HRImage';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import { FaCheckCircle, FaTrash, FaUsers } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { AiFillCloseCircle, AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import { FiMapPin } from 'react-icons/fi';
import { GoBriefcase } from 'react-icons/go';
import FilterInput from '../../components/core/typography/FilterInput';
import { HiOutlineSearch } from 'react-icons/hi';
import { fetchForwardCandidateDetails } from '../../API/ForwardedCanApi';
import { useEffect } from 'react';
import { useState } from 'react';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';

const handleForward = () => {
	console.log('forward candidate btn clicked');
};
const ForwardCandidate = () => {
	const [applicantDetails, setApplicantDetails] = useState([]);

	useEffect(() => {
		const getApplicantDetails = async () => {
			try {
				const fetchedApplicantDetails =
					await fetchForwardCandidateDetails();
				setApplicantDetails(fetchedApplicantDetails);
			} catch (error) {
				console.error(
					'Failed to fetch forward candidate details:',
					error,
				);
			}
		};

		getApplicantDetails();
	}, []);
	return (
		<>
			<div className="container-lg container-md container-sm">
				<HeaderSection />
				<section className="container pt-5 pb-5">
					<MainContent applicantDetails={applicantDetails} />
					<FooterSection />
				</section>
			</div>
		</>
	);
};
const HeaderSection = () => (
	<div className="pt-3">
		<ProfileTitleSection title={'Forward Candidate'} />
		<div className="container pt-5 mb-3 px-3">
			<SearchBar />
		</div>
	</div>
);
const SearchBar = () => (
	<div className="form-group col-lg-6 col-md-8 col-sm-12">
		<IconContext.Provider
			value={{ size: '2.4rem', color: '#5c6770', className: 'icon' }}
		>
			<HiOutlineSearch />
		</IconContext.Provider>
		<FilterInput type="text" name="search" placeholder="Search" />
	</div>
);

const MainContent = ({ applicantDetails }) => (
	<div className="row g-3">
		{applicantDetails.map((detail) => (
			<ApplicantCard key={detail.name} detail={detail} />
		))}
		<div className="col-lg-1 col-md-12 col-sm-12  d-flex align-items-center justify-content-center ">
			<SubTitle subTitle="For" />
		</div>
		<JobDetailCard />
	</div>
);
const ApplicantCard = ({ detail }) => (
	<div className=" container col-lg-6 col-md-12 col-sm-12 p-3  ">
		<div className="applicant-card p-3 rounded-4 ">
			<div className="">
				<div className="d-flex container justify-content-between">
					<ContentTitle contentTitle={'Sam John'} />
					<div className=" ">
						<IconContext.Provider
							value={{
								size: '2rem',
								color: 'var(--common-color)',
							}}
						>
							<div>
								<FaCheckCircle />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				<div className="job-ad-item pt-2">
					<div className="d-flex">
						<div className="d-flex mx-4 align-items-center">
							<SubText subText={'UI Designer'} size={'1.5rem'} />
						</div>
						<div className="d-flex mx-4 align-items-center">
							<SubText subText={'India'} size={'1.5rem'} />
						</div>
					</div>
				</div>
				<div className=" justify-content-lg-between align-items-lg-center">
					<div className="d-flex justify-content-around pt-4">
						<div className="joining">
							<Text text={'Followers'} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={'Following'} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={'Groups'} size={`1.5rem`} />
						</div>
					</div>
					<div className="d-flex justify-content-end mt-4">
						<IconContext.Provider
							value={{
								size: '1.7rem',
								color: '#fff',
							}}
						>
							<div className="me-3">
								<FaUsers />
							</div>
							<div className="me-3">
								<AiFillCloseCircle />
							</div>
							<div>
								<FaTrash />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const JobDetailCard = () => (
	<div className=" container col-lg-5 col-md-12 col-sm-12 p-3  ">
		<div className="applicant-card p-3 rounded-4 ">
			<div className="">
				<div className="d-flex container justify-content-between">
					<div className="d-flex align-items-center">
						<div>
							<HRImage />
						</div>

						<ContentTitle contentTitle={'Recruiting Coordinator'} />
					</div>
				</div>
				<div class="job-ad-item pt-2">
					<div className="d-flex flex-wrap ">
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<GoBriefcase />
							</IconContext.Provider>
							<SubText subText={'Catalyst'} size={'1.3rem'} />
						</div>
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<FiMapPin />
							</IconContext.Provider>
							<SubText
								subText={'Mumbai, India'}
								size={'1.3rem'}
							/>
						</div>

						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<AiOutlineClockCircle />
							</IconContext.Provider>
							<SubText subText={'11 Hour ago'} size={'1.3rem'} />
						</div>
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<IoCashOutline />
							</IconContext.Provider>
							<SubText subText={'3LPA-$LPA'} size={'1.3rem'} />
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-around pt-4">
					<div className="joining">
						<Text text={'Followers'} size={`1.5rem`} />
					</div>
					<div className="joining ">
						<Text text={'Following'} size={`1.5rem`} />
					</div>
					<div className="joining">
						<Text text={'Groups'} size={`1.5rem`} />
					</div>
				</div>
			</div>
		</div>
	</div>
);

const FooterSection = () => (
	<div className="form-group container p-5 d-flex justify-content-end pt-lg-5 mt-lg-5  ">
		<ButtonPrimary text="Forward Candidate" onClick={handleForward} />
	</div>
);

export default ForwardCandidate;

import React, { useEffect, useState } from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import SelectInput from '../../components/core/typography/SelectInput';
import FilterInput from '../../components/core/typography/FilterInput';
import ContentTitle from '../../components/core/typography/ContentTitle';

import { Table, Button } from 'react-bootstrap';
import { BsThreeDots } from 'react-icons/bs';

import Applicant from '../../components/core/img/Applicant';
import ManageCalendar from '../../components/calendar/ManageCalendar';
import Title from '../../components/core/typography/Title';
import { HiOutlineSearch } from 'react-icons/hi';
import { IconContext } from 'react-icons';
import {
	getApplicantsData,
	getBookingsDetails,
	getMonths,
	getNoOfInterviews,
} from '../../API/viewInterviewApi';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';

const FilterSection = ({
	NoOfInterviews,
	months,
	handleButtonClick,
	activeBtn,
}) => {
	const inputStyle = {
		padding: '1.7rem 2rem',
		marginTop: '.5rem',
		fontWeight: 'bold',
		fontSize: '1.5rem',
	};
	return (
		<div className="filter-container container text-center pb-5">
			<div className="row g-3 align-items-center">
				<div className="col-lg-1 col-md-2 mx-1">
					<SelectInput options={NoOfInterviews} />
				</div>
				<div className="col-lg-3 col-md-3 mx-1">
					<SelectInput options={months} />
				</div>
				<div className="col-lg-3 col-md-3 mx-1 form-group">
					<IconContext.Provider
						value={{
							size: '2.4rem',
							color: '#5c6770',
							className: 'icon',
						}}
					>
						<HiOutlineSearch />
					</IconContext.Provider>
					<FilterInput
						type="text"
						placeholder={`Search Applicant by name of title`}
					/>
				</div>
				<div
					className="col-lg-3 col-md-4 col-sm-3 mx-1 btn-group"
					role="group"
					aria-label="Basic radio toggle button group"
				>
					<input
						type="radio"
						className="btn-check"
						name="btnradio"
						id="btnradio1"
						autoComplete="off"
					/>
					<label
						className={`btn border border-light text-light ${
							activeBtn === 'btnradio1'
								? 'bg-light text-dark'
								: ''
						}`}
						htmlFor="btnradio1"
						style={inputStyle}
						onClick={() => handleButtonClick('btnradio1')}
					>
						Day
					</label>
					<input
						type="radio"
						className="btn-check"
						name="btnradio"
						id="btnradio2"
						autoComplete="off"
					/>
					<label
						className={`btn border border-light text-light ${
							activeBtn === 'btnradio2'
								? 'bg-light text-dark'
								: ''
						}`}
						htmlFor="btnradio2"
						style={inputStyle}
						onClick={() => handleButtonClick('btnradio2')}
					>
						Week
					</label>
					<input
						type="radio"
						className="btn-check "
						name="btnradio"
						id="btnradio3"
						autoComplete="off"
						defaultChecked
					/>
					<label
						className={`btn border border-light text-light ${
							activeBtn === 'btnradio3'
								? 'bg-light text-dark'
								: ''
						}`}
						htmlFor="btnradio3"
						style={inputStyle}
						onClick={() => handleButtonClick('btnradio3')}
					>
						Month
					</label>
				</div>
			</div>
		</div>
	);
};

const TableSection = ({ applicantsData }) => {
	const tableStyle = {
		backgroundColor: '#000',
		color: '#ffffff',
		margin: '1.5rem',
		padding: '1rem',
	};
	return (
		<div className="container">
			<div className="table-responsive">
				<Table
					style={tableStyle}
					// striped
					// bordered
					hover
					variant="dark"
					className="container w-100 p-0 m-0"
				>
					<thead className="text-center bg-gray ">
						<tr>
							<th className="p-4 ">Applicant</th>
							<th className="p-4 ">Job Title</th>
							<th className="p-4 ">Date</th>
							<th className="p-4 ">Time</th>
							<th className="p-4 ">Action</th>
						</tr>
					</thead>
					<tbody>
						{applicantsData.map((applicant, index) => (
							<tr
								key={index}
								className="text-center table-content"
							>
								<td className="d-flex justify-content-evenly pb-5">
									<Applicant />
									{applicant.name}
								</td>
								<td>{applicant.jobTitle}</td>
								<td className="text-danger">
									{applicant.date}
								</td>
								<td className="text-danger">
									{applicant.time}
								</td>
								<td className="">
									<Button
										variant="outline-secondary"
										className="mr-2 me-5 text-white"
									>
										View Details
									</Button>
									<BsThreeDots />
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

const CalendarSection = ({ bookingsDetails }) => {
	return (
		<div className="">
			<ManageCalendar
				month={new Date().getMonth() + 1}
				year={new Date().getFullYear()}
				preloadedEvents={
					bookingsDetails != undefined ? bookingsDetails : []
				}
			/>
		</div>
	);
};

const ViewInterviews = () => {
	const [activeBtn, setActiveBtn] = useState('btnradio1');
	const [applicants, setApplicants] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [interviews, setInterviews] = useState([]);
	const [monthsData, setMonthsData] = useState([]);

	useEffect(() => {
		getApplicantsData().then((data) => {
			setApplicants(data);
		});

		getBookingsDetails().then((data) => {
			setBookings(data);
		});

		getNoOfInterviews().then((data) => {
			setInterviews(data);
		});

		getMonths().then((data) => {
			setMonthsData(data);
		});
	}, []);

	const handleButtonClick = (id) => {
		setActiveBtn(id);
	};

	return (
		<section className="job-detail-section applicants resume-alert ">
			<div className="container-lg container-md container-sm ">
				<div className="container pb-5 pt-3">
					<ProfileTitleSection
						title={'View Interviews'}
						subText={'Ready to jump back in?'}
					/>
				</div>
				<FilterSection
					NoOfInterviews={interviews}
					months={monthsData}
					handleButtonClick={handleButtonClick}
					activeBtn={activeBtn}
				/>
				<TableSection applicantsData={applicants} />
				<div className="container pt-4">
					<ContentTitle contentTitle={'Appointment'} size={'3rem'} />
				</div>
				<CalendarSection bookingsDetails={bookings} />
			</div>
		</section>
	);
};

export default ViewInterviews;

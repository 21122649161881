import React from 'react';
import faq from '../../../assets/images/faq.jpg';
const FAQImg = () => {
	return (
		<div>
			<img src={faq} alt="blog post" width={'100%'} height={'550'} />
		</div>
	);
};

export default FAQImg;

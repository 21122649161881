import React, { Fragment, useEffect, useState } from 'react';
import { FaBookmark, FaInbox, FaRegBookmark } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import Candidate from '../../components/core/img/Candidate';
import SubTitle from '../../components/core/typography/SubTitle';
import SubText from '../../components/core/typography/SubText';
import Text from '../../components/core/typography/Text';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import CompaniesLogo from '../../components/core/img/CompaniesLogo';
import ContentTitle from '../../components/core/typography/ContentTitle';
import { BsThreeDotsVertical } from 'react-icons/bs';
import HRImage from '../../components/core/img/HRImage';
import Input from '../../components/core/typography/Input';
import {
	fetchCompanies,
	fetchUserDetails,
	fetchJobStatusDetails,
} from '../../API/MessageApi';

// card

const DetailsCard = ({ companyDetails }) => {
	return (
		<div className="company-container job-item media">
			<a href="#">
				<div className="company-logo">
					<CompaniesLogo />
				</div>
			</a>
			<div className="media-body align-self-center">
				<div>
					<SubText subText={companyDetails.id} />
				</div>
				<ContentTitle contentTitle={companyDetails.title} />
				<div>
					<SubText subText={companyDetails.forwardedAt} />
				</div>
			</div>
		</div>
	);
};

// main
const Message = () => {
	// Bookmark state
	const [isBookmarked, setIsBookmarked] = useState(false);
	// Bookmark toggle function
	const handleBookmarkClick = () => {
		setIsBookmarked(!isBookmarked);
	};
	const [companiesData, setCompaniesData] = useState([]);
	const [userDetails, setUserDetails] = useState({});
	const [jobStatusDetails, setJobStatusDetails] = useState({});

	// Use the useEffect hook to fetch the data from the mock API upon component mount
	useEffect(() => {
		fetchCompanies().then((data) => {
			setCompaniesData(data);
		});

		fetchUserDetails().then((data) => {
			setUserDetails(data);
		});

		fetchJobStatusDetails().then((data) => {
			setJobStatusDetails(data);
		});
	}, []);
	return (
		<Fragment>
			<section className=" company-details candidate-resume">
				<div className="container-lg container-md container-sm d-flex align-items-center pt-5 ">
					<div className="title-section pb-5  ">
						<div className="row  d-flex align-items-center">
							<div class="detail-title  container col-lg-8 col-md-12 col-sm-12">
								<div class=" d-flex justify-content-between align-items-center">
									<div class=" d-flex align-items-center">
										<Candidate />

										<div className="mx-lg-3 mx-2">
											<SubTitle
												subTitle={userDetails.name}
											/>

											<div class="job-ad-item pt-2">
												<div className="d-flex flex-wrap ">
													<div className="d-flex align-items-center">
														<IconContext.Provider
															value={{
																size: '1.7rem',
																color: 'var(--subtext-color)',
																className:
																	'me-2',
															}}
														>
															<GoBriefcase />
														</IconContext.Provider>
														<SubText
															subText={
																userDetails.role
															}
															size={'1.7rem'}
														/>
													</div>
													<div className="d-flex mx-lg-3 mx-2 align-items-center">
														<IconContext.Provider
															value={{
																size: '1.7rem',
																color: 'var(--subtext-color)',
																className:
																	'me-2',
															}}
														>
															<FiMapPin />
														</IconContext.Provider>
														<SubText
															subText={
																userDetails.location
															}
															size={'1.7rem'}
														/>
													</div>

													<div className="d-flex mx-lg-3 mx-0 align-items-center">
														<IconContext.Provider
															value={{
																size: '1.7rem',
																color: 'var(--subtext-color)',
																className:
																	'me-2',
															}}
														>
															<AiOutlineClockCircle />
														</IconContext.Provider>
														<SubText
															subText={
																userDetails.lastActive
															}
															size={'1.7rem'}
														/>
													</div>
													<div className="d-flex mx-lg-3 mx-2 align-items-center">
														<IconContext.Provider
															value={{
																size: '1.7rem',
																color: 'var(--subtext-color)',
																className:
																	'me-2',
															}}
														>
															<IoCashOutline />
														</IconContext.Provider>
														<SubText
															subText={
																userDetails.salaryRange
															}
															size={'1.7rem'}
														/>
													</div>
												</div>
											</div>
											<div className="d-flex pt-3 justify-content-around">
												{userDetails.skills &&
													userDetails.skills.map(
														(skill, index) => (
															<div
																key={index}
																className="imp-skills"
															>
																<Text
																	text={skill}
																/>
															</div>
														),
													)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="container col-lg-4 col-md-12 col-sm-12 d-flex justify-content-end align-items-center ">
								<div className="me-3">
									<ButtonPrimary text={'View Detail'} />
								</div>

								<div
									className="bookmark rounded mx-4 mt-3 d-flex justify-content-center align-items-center"
									onClick={handleBookmarkClick}
								>
									<IconContext.Provider
										value={{
											size: '2.4rem',
											color: 'var(--common-color)',
										}}
									>
										{isBookmarked ? (
											<FaBookmark />
										) : (
											<FaRegBookmark />
										)}
									</IconContext.Provider>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section className="  pb-5 pt-4">
					<div className="container">
						<div className=" d-flex flex-lg-row flex-column justify-content-between container p-5">
							<div className="text text-white">
								<strong>20</strong> jobs
							</div>
							<ListSwitcher />
						</div>
						<div className="row container">
							<div className="col-lg-4 ">
								<div className="col-lg-12 col-md-12 col-sm-12 pt-4">
									<div className="company-container job-item media">
										<div className="media-body container align-self-center d-flex justify-content-between pb-5">
											<div className="d-flex align-items-center">
												<IconContext.Provider
													value={{
														size: '1.8rem',
														color: '#fff',
													}}
												>
													<FaInbox />
												</IconContext.Provider>
												<div className="mx-3">
													<SubText
														subText={'Jobs (5)'}
														color={'#fff'}
													/>
												</div>
											</div>

											<div>
												<IconContext.Provider
													value={{
														size: '1.5rem',
														color: '#fff',
													}}
												>
													<BsThreeDotsVertical />
												</IconContext.Provider>
											</div>
										</div>
									</div>
									{companiesData.map(
										(companyDetails, index) => (
											<DetailsCard
												key={index}
												companyDetails={companyDetails}
											/>
										),
									)}
								</div>
							</div>

							<div className="col-lg-8 ">
								<div className="container">
									<div>
										<SubText
											subText={`JobId: ${jobStatusDetails.jobId}`}
										/>{' '}
									</div>

									<div className="col-12 d-flex justify-content-between align-items-center bg-secondary p-3">
										<div>
											<SubText
												subText={'JOB STATUS'}
												color={'#fff'}
											/>
										</div>
										<div className=" d-flex row g-3 ">
											<div className="col-lg-3 col-md-6 col-sm-12">
												<div className="bg-white text-center w-100 rounded-2 p-1 mx-2  ">
													<Text
														text={'Selected'}
														color={'#38b000'}
													/>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-12">
												<div className="bg-white text-center w-100 rounded-2 p-1 mx-2  ">
													<Text
														text={'Screening'}
														color={'#fb5607'}
													/>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-12">
												<div className="bg-white text-center w-100 rounded-2 p-1 mx-2 ">
													<Text
														text={'Forwarded'}
														color={'#ffbe0b'}
													/>
												</div>
											</div>
											<div className="col-lg-3 col-md-6 col-sm-12">
												<div className="bg-white  text-center w-100 rounded-2 p-1 mx-2 ">
													<Text
														text={'Rejected'}
														color={'#e01e37'}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="pt-4">
										<ContentTitle
											contentTitle={
												jobStatusDetails.company
											}
										/>
										<div className="status mt-2 d-flex justify-content-between row g-4">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="d-flex">
													<SubText subText={'From'} />
													<span className="px-2">
														<SubText
															subText={
																jobStatusDetails.location
															}
															color={
																'var(--link-color)'
															}
														/>
													</span>
												</div>
												<div className="d-flex">
													<SubText
														subText={'Email Id'}
													/>
													<span className="px-2">
														<SubText
															subText={
																jobStatusDetails.email
															}
														/>
													</span>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="d-flex">
													<SubText
														subText={'Phone'}
													/>
													<span className="px-2">
														<SubText
															subText={
																jobStatusDetails.phone
															}
															color={
																'var(--link-color)'
															}
														/>
													</span>
												</div>
												<div className="d-flex">
													<SubText
														subText={'Secure with'}
													/>
													<span className="px-2">
														<SubText
															subText={
																jobStatusDetails.paymentMethod
															}
															color={
																'var(--link-color)'
															}
														/>
													</span>
												</div>
											</div>
										</div>
									</div>

									<div className="pt-4">
										<ContentTitle
											contentTitle={
												jobStatusDetails
													?.interviewDetails?.title
											}
										/>
										<div className="status d-flex flex-column mt-2 ">
											<div className="d-flex">
												<SubText subText={'Time'} />
												<span className="px-2">
													<SubText
														subText={
															jobStatusDetails
																?.interviewDetails
																?.time
														}
														color={
															'var(--text-color)'
														}
													/>
												</span>
											</div>
											<div className="d-flex">
												<SubText subText={'Date'} />
												<span className="px-2">
													<SubText
														subText={
															jobStatusDetails
																?.interviewDetails
																?.date
														}
														color={
															'var(--text-color)'
														}
													/>
												</span>
											</div>
											<div className="d-flex">
												<SubText subText={'Taken By'} />
												<span className="px-2">
													<SubText
														subText={
															jobStatusDetails
																?.interviewDetails
																?.takenby
														}
														color={
															'var(--text-color)'
														}
													/>
												</span>
											</div>
											<div className="d-flex">
												<SubText subText={'Status'} />
												<span className="px-2">
													<SubText
														subText={
															jobStatusDetails
																?.interviewDetails
																?.status
														}
														color={
															'var(--text-color)'
														}
													/>
												</span>
											</div>
											<div className="d-flex">
												<SubText subText={'Message'} />
												<span className="px-2">
													<SubText
														subText={
															jobStatusDetails
																?.interviewDetails
																?.message
														}
														color={
															'var(--text-color)'
														}
													/>
												</span>
											</div>
										</div>
									</div>

									<div className="pt-4">
										<ContentTitle
											contentTitle={'Message '}
										/>
										<div className="row status mt-2">
											<div className="col-lg-12 col-md-12 col-sm-12 d-flex p-4 pb-5">
												<div className="col-lg-2">
													<HRImage />
												</div>
												<div className="col-lg-10">
													<SubText
														subText={`Dear ${jobStatusDetails?.hrMessage?.name}`}
													/>
													<Text
														text={
															jobStatusDetails
																?.hrMessage
																?.content
														}
													/>
												</div>
											</div>
										</div>
									</div>

									<div className="pt-5 reply-input ">
										<form className="d-flex">
											<Input
												type={'text'}
												label={'write a reply...'}
												id={'floatingReply'}
												name={'reply'}
												style={{ flex: 9 }}
											/>
											<div className="d-flex justify-content-end">
												<ButtonPrimary
													text={'Send Reply'}
													style={{ flex: 1 }}
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</section>
		</Fragment>
	);
};

export default Message;

import React from 'react';
import Title from '../core/typography/Title';
import SubText from '../core/typography/SubText';

const ProfileTitleSection = ({ title, subText }) => {
	return (
		<div className="title-section">
			<div className="pb-5 px-5">
				<Title title={title} />
				<SubText subText={subText} />
			</div>
		</div>
	);
};

export default ProfileTitleSection;

import React, { Fragment, useState, useEffect } from 'react';

//COMPONENTS
// import Header from "../../components/header";
// import Footer from "../../components/footer.js";
// import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
// import Navbar from '../../components/core/header/Navbar';
import About from '../../components/about/About';
import JobsCategories from '../../components/jobs/JobsCategories';
import CounterSection from '../../components/counter/CounterSection';
import ClientsSection from '../../components/clients/ClientsSection';
// import OurServices from '../../components/ourServices/OurServices';
import BlogSection from '../../components/blogs/BlogSection';
import RecruitingSection from '../../components/recruiting/RecruitingSection';
import HeroSection from '../../components/herosection/HeroSection';
import RecentJobs from '../../components/jobs/recenteJobs/RecentJobs';
import BackToTop from '../../components/core/buttons/backtotop/BackToTop';

function Homepage(props) {
	window.scrollTo(0, 0);

	return (
		<>
			<HeroSection />
			<About />
			{/* <JobsCategories /> */}
			<RecentJobs />
			<CounterSection />
			<ClientsSection />
			{/* <OurServices /> */}
			<BlogSection />
			{/* <RecruitingSection /> */}
			<BackToTop />
		</>
	);
}

export default Homepage;

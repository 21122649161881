import axios from 'axios';
// ACTUAL API FETCHING
// *********************************

// export async function fetchJobs() {
//     try {
//         const response = await axios.get('https://your-api/jobs');
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching jobs:", error);
//         throw new Error('Error fetching jobs');
//     }
// }

// **************************

// Mock job data
const mockJobs = [
	{
		id: 1,
		jobTitle: 'Senior Manager – Customer Success – SaaS',
		location: 'Gurugram, Delhi',
		jobType: 'Full Time',
		salaryRange: 'Rs 25,000 - Rs 35,000',
	},
	{
		id: 2,
		jobTitle: 'Virtual relationship Manager – NR',
		location: 'Mumbai ',
		jobType: 'Full Time',
		salaryRange: 'Rs 50,000 - Rs 70,000',
	},
	{
		id: 3,
		jobTitle: 'HR Business Partner – Recruitment Firm',
		location: 'Gurugram',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
	{
		id: 4,
		jobTitle: 'Manager – Enterprise Sales – SaaS Technology Domain',
		location: 'Mumbai ',
		jobType: 'Full Time',
		salaryRange: 'Rs 25,000 - Rs 35,000',
	},
	{
		id: 5,
		jobTitle: 'Human Resources Manager',
		location: 'Tamil Nadu ',
		jobType: 'Full Time',
		salaryRange: 'Rs 50,000 - Rs 70,000',
	},
	{
		id: 6,
		jobTitle: 'Diversity Recruiter',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
	{
		id: 7,
		jobTitle: 'Technical Talent Acquisition Specialist',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
	{
		id: 8,
		jobTitle: 'Finance Manager',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
	{
		id: 9,
		jobTitle: 'Technical Sales Specialist ',
		location: 'Pune, Maharashtra',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
	{
		id: 10,
		jobTitle: 'Manager – Human Resource',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
	},
];

export async function fetchJobs() {
	// returning hardcoded data.
	const response = await new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				data: mockJobs,
			});
		}, 1000);
	});

	return response.data;
}

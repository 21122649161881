// ResumeAlertsApi.js

// Mock data for resume alerts
const resumeAlertsData = [
	{
		id: 1,
		title: 'Education',
		query: 'Category: Education Training, Posted Date: All, Salary:Rs 2lpa-4lpa',
		jobs: 'Jobs Found 5',
		time: 'Weekly',
	},
	{
		id: 2,
		title: 'Developer',
		query: 'Category: Education Training, Posted Date: All, Salary:Rs 2lpa-4lpa',
		jobs: 'Jobs Found 5',
		time: 'Weekly',
	},
	{
		id: 3,
		title: 'Trainer',
		query: 'Category: Education Training, Posted Date: All, Salary:Rs 2lpa-4lpa',
		jobs: 'Jobs Found 5',
		time: 'Weekly',
	},
	{
		id: 4,
		title: 'Doctor',
		query: 'Category: Education Training, Posted Date: All, Salary:Rs 2lpa-4lpa',
		jobs: 'Jobs Found 5',
		time: 'Weekly',
	},
];

// Mock function to simulate fetching data from an API
export const fetchResumeAlerts = async () => {
	// Simulating a network request delay using setTimeout
	return new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(resumeAlertsData);
		}, 1000);
	});
};
export const fetchTimelineData = () => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve([
				{ value: '', label: 'Last 1 month' },
				{ value: '', label: 'Last 3 months' },
				{ value: '', label: 'Last 6 months' },
				{ value: '', label: 'All' },
			]);
		}, 1000);
	});
};

import React from 'react';

const Input = ({ type, label, id, name, icon, onChange }) => {
	return (
		<div>
			<div class="form-floating mb-5">
				{icon && <span className="icon px-2 ">{icon}</span>}
				<input
					type={type}
					class="form-control"
					id={id}
					placeholder="Enter value"
					name={name}
					onChange={onChange}
				/>
				<label htmlFor={id}>{label}</label>
			</div>
		</div>
	);
};

export default Input;

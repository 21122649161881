import React, { useEffect } from 'react';
import { useState } from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import Input from '../../components/core/typography/Input';
import SelectInput from '../../components/core/typography/SelectInput';
import ContentTitle from '../../components/core/typography/ContentTitle';
import SubText from '../../components/core/typography/SubText';
import Text from '../../components/core/typography/Text';
import TextArea from '../../components/core/typography/TextArea';
import { IconContext } from 'react-icons';
import { FaCheckCircle, FaSearch, FaTrash, FaUsers } from 'react-icons/fa';
import { AiFillCloseCircle, AiOutlineClockCircle } from 'react-icons/ai';
import HRImage from '../../components/core/img/HRImage';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { IoCashOutline } from 'react-icons/io5';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import Calendar from '../../components/calendar/Calendar';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import FilterInput from '../../components/core/typography/FilterInput';
import { HiOutlineSearch } from 'react-icons/hi';
import Title from '../../components/core/typography/Title';
import {
	getCategories,
	getEndTimes,
	getStartTimes,
} from '../../API/InterviewScheduleApi';
import { getApplicantDetails } from '../../API/CandidateApi';

const applicantDetails = [
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
];
const category = [
	{ value: '', label: 'Default' },
	{ value: '', label: 'Newest' },
	{ value: '', label: 'Oldest' },
];
const startTime = [
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
];
const endTime = [
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
	{ value: '', label: '9:00AM' },
];

const InterviewSchedule = () => {
	const [date, setDate] = useState(new Date());

	const [applicantDetailsData, setApplicantDetailsData] = useState([]);
	const [categories, setCategories] = useState([]);
	const [startTimes, setStartTimes] = useState([]);
	const [endTimes, setEndTimes] = useState([]);

	useEffect(() => {
		const fetchApplicantDetails = async () => {
			const details = await getApplicantDetails();
			setApplicantDetailsData(details);
		};

		const fetchCategories = async () => {
			const categoryData = await getCategories();
			setCategories(categoryData);
		};

		const fetchStartTimes = async () => {
			const times = await getStartTimes();
			setStartTimes(times);
		};

		const fetchEndTimes = async () => {
			const times = await getEndTimes();
			setEndTimes(times);
		};

		fetchApplicantDetails();
		fetchCategories();
		fetchStartTimes();
		fetchEndTimes();
	}, []);
	const onChange = (date) => {
		setDate(date);
	};

	return (
		<>
			<div className="container-lg container-md container-sm">
				<HeaderSection />
				<section className=" pt-5 pb-5">
					{/* maincontent component called  */}
					<MainContent />
					{/* Date and time */}
					<div className="d-flex flex-column align-items-start container pt-5 ">
						<div>
							<ContentTitle
								contentTitle={'Select Date and Time'}
							/>
						</div>
						<div className="row d-flex justify-content-between pt-5 w-100">
							<div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-sm-center ">
								<Calendar />
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column g-4 ">
								<div className="mb-4 ">
									<ContentTitle contentTitle={'Start Time'} />
								</div>

								<SelectInput options={startTime} />
								<div className="mb-4 mt-4">
									<ContentTitle contentTitle={'End Time'} />
								</div>

								<SelectInput options={endTime} />
							</div>
						</div>
						<div className="w-100 pt-5 ">
							<TextArea placeholder={'Enter meeting Agenda...'} />
						</div>
					</div>
					<div className="d-flex justify-content-end pt-5 container">
						<ButtonPrimary text={'Send Invitation'} />
					</div>
				</section>
			</div>
		</>
	);
};
const HeaderSection = () => (
	<>
		<div className="title-section px-5">
			<div>
				<Title title="Interview Schedule" />
			</div>
			<SearchAndFilter />
		</div>
	</>
);
const SearchAndFilter = () => (
	<div className="search-filter  row d-flex justify-content-lg-end mb-5 g-4">
		<div className="form-group col-lg-4 col-md-8 col-sm-12">
			<IconContext.Provider
				value={{ size: '2.4rem', color: '#5c6770', className: 'icon' }}
			>
				<HiOutlineSearch />
			</IconContext.Provider>
			<FilterInput type="text" name="search" placeholder="Search" />
		</div>
		<div className=" d-flex justify-content-lg-end col-lg-2 col-md-3 col-sm-12">
			<SelectInput options={category} />
		</div>
	</div>
);
const MainContent = () => (
	<div className="row g-3">
		{applicantDetails.map((detail) => (
			<ApplicantCard key={detail.name} detail={detail} />
		))}
		<div className="col-lg-1 col-md-12 col-sm-12  d-flex align-items-center justify-content-center ">
			<SubTitle subTitle="For" />
		</div>
		<JobDetailCard />
	</div>
);

const ApplicantCard = ({ detail }) => (
	<div className=" container col-lg-6 col-md-12 col-sm-12 p-3  ">
		<div className="applicant-card p-3 rounded-4 ">
			<div className="">
				<div className="d-flex container justify-content-between">
					<ContentTitle contentTitle={'Sam John'} />
					<div className=" ">
						<IconContext.Provider
							value={{
								size: '2rem',
								color: 'var(--common-color)',
							}}
						>
							<div>
								<FaCheckCircle />
							</div>
						</IconContext.Provider>
					</div>
				</div>
				<div className="job-ad-item pt-2">
					<div className="d-flex">
						<div className="d-flex mx-4 align-items-center">
							<SubText subText={'UI Designer'} size={'1.5rem'} />
						</div>
						<div className="d-flex mx-4 align-items-center">
							<SubText subText={'India'} size={'1.5rem'} />
						</div>
					</div>
				</div>
				<div className=" justify-content-lg-between align-items-lg-center">
					<div className="d-flex justify-content-around pt-4">
						<div className="joining">
							<Text text={'Followers'} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={'Following'} size={`1.5rem`} />
						</div>
						<div className="joining">
							<Text text={'Groups'} size={`1.5rem`} />
						</div>
					</div>
					<div className="d-flex justify-content-end mt-4">
						<IconContext.Provider
							value={{
								size: '1.7rem',
								color: '#fff',
							}}
						>
							<div className="me-3">
								<FaUsers />
							</div>
							<div className="me-3">
								<AiFillCloseCircle />
							</div>
							<div>
								<FaTrash />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const JobDetailCard = () => (
	<div className=" container col-lg-5 col-md-12 col-sm-12 p-3  ">
		<div className="applicant-card p-3 rounded-4 ">
			<div className="">
				<div className="d-flex container justify-content-between">
					<div className="d-flex align-items-center">
						<div>
							<HRImage />
						</div>

						<ContentTitle contentTitle={'Recruiting Coordinator'} />
					</div>
				</div>
				<div class="job-ad-item pt-2">
					<div className="d-flex flex-wrap ">
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<GoBriefcase />
							</IconContext.Provider>
							<SubText subText={'Catalyst'} size={'1.3rem'} />
						</div>
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<FiMapPin />
							</IconContext.Provider>
							<SubText
								subText={'Mumbai, India'}
								size={'1.3rem'}
							/>
						</div>

						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<AiOutlineClockCircle />
							</IconContext.Provider>
							<SubText subText={'11 Hour ago'} size={'1.3rem'} />
						</div>
						<div className="d-flex mx-2 align-items-center">
							<IconContext.Provider
								value={{
									size: '1.5rem',
									color: 'var(--subtext-color)',
									className: 'me-2',
								}}
							>
								<IoCashOutline />
							</IconContext.Provider>
							<SubText subText={'3LPA-$LPA'} size={'1.3rem'} />
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-around pt-4">
					<div className="joining">
						<Text text={'Followers'} size={`1.5rem`} />
					</div>
					<div className="joining ">
						<Text text={'Following'} size={`1.5rem`} />
					</div>
					<div className="joining">
						<Text text={'Groups'} size={`1.5rem`} />
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default InterviewSchedule;

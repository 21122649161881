import React, { useEffect, useState } from 'react';
import JobsFilter from '../../components/jobs/JobsFilter';
import { Modal } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
// import Title from '../../components/core/typography/Title';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import JobCard from '../../components/core/cards/JobCard';
import Recruiting from '../../components/recruiting/Recruiting';
import ButtonSecondary from '../../components/core/buttons/ButtonSecondary';
import { IconContext } from 'react-icons';
import { IoFilter } from 'react-icons/io5';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import { fetchJobs } from '../../API/JobsAPI';
import Pagination from '../../components/pagination/Pagination';

const ITEMS_PER_PAGE = 10;

const FindJobs = () => {
	// state for filter column
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
	const [jobs, setJobs] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		async function loadJobs() {
			try {
				const fetchedJobs = await fetchJobs();
				setJobs(fetchedJobs);
			} catch (error) {
				console.error('Error fetching jobs:', error);
			}
		}

		loadJobs();
	}, []);

	// function
	const toggleFilterModal = () => {
		setIsFilterModalVisible(!isFilterModalVisible);
	};
	const totalPages = Math.ceil(jobs.length / ITEMS_PER_PAGE);
	const paginatedJobs = jobs.slice(
		(currentPage - 1) * ITEMS_PER_PAGE,
		currentPage * ITEMS_PER_PAGE,
	);

	return (
		<>
			<div className="background pt-5">
				<div className="find-jobs container-lg container-md container-sm pt-3">
					<ProfileTitleSection
						title={'Jobs'}
						subText={'Ready to jump back in?'}
					/>
					<div className="row gx-0 content-section pt-0">
						{/* Filters column - Always visible on large screens */}
						<div
							className="filters-column col-lg-3 d-none d-lg-block"
							style={{ width: '35rem' }}
						>
							<JobsFilter />
							<Recruiting />
						</div>

						<div className=" col-lg-8 col-md-12 col-sm-12 jobs-list">
							{/* Filter button visible only on small and medium screens */}
							<div className="container  d-flex  align-items-center d-lg-none  ">
								<div className="menu-btn m-auto px-5">
									<IconContext.Provider
										value={{
											size: '2.3rem',
											color: 'var(--common-color)',
										}}
									>
										<IoFilter />
									</IconContext.Provider>
									<ButtonSecondary
										text={'Filter'}
										onClick={toggleFilterModal}
									/>
								</div>
							</div>
							<div class="ls-switcher container  m-auto  pb-5 position-relative d-flex justify-content-between align-items-center">
								<div class="showing-result">
									<div class="text text-white">
										<strong>20</strong> jobs
									</div>
								</div>
								<ListSwitcher />
							</div>
							{paginatedJobs.map((job, index) => (
								<JobCard key={index} {...job} />
							))}

							<Pagination
								currentPage={currentPage}
								totalPages={Math.ceil(
									jobs.length / ITEMS_PER_PAGE,
								)}
								onPageChange={(page) => setCurrentPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* Filter Modal for medium and smaller screens */}
			<Modal
				show={isFilterModalVisible}
				onHide={toggleFilterModal}
				size="lg"
				className="d-lg-none left-side-modal"
			>
				<div className="d-flex justify-content-end pt-4 pe-4">
					<FiX
						style={{
							cursor: 'pointer',
							fontSize: '2.3rem',
							border: '.2rem solid white',
							borderRadius: '5rem',
						}}
						onClick={toggleFilterModal}
					/>
				</div>
				<Modal.Body>
					<JobsFilter />
					<Recruiting />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default FindJobs;

import React from 'react';
import { IconContext } from 'react-icons';
import { GrFacebookOption, GrTwitter } from 'react-icons/gr';
import Text from '../core/typography/Text';
import { FaLinkedinIn } from 'react-icons/fa';
const ShareApp = () => {
	const socialLinks = [
		{
			Icon: GrFacebookOption,
			title: 'Facebook',
		},
		{
			Icon: GrTwitter,
			title: 'Twitter',
		},
		{
			Icon: FaLinkedinIn,
			title: 'LinkedIn',
		},
	];
	return (
		<>
			<div className="container">
				<div className=" row d-flex justify-content-around flex-lg-row flex-column g-4 flex-wrap">
					{socialLinks.map((elem, key) => {
						const { Icon, title } = elem;
						return (
							<>
								<div
									className="col-lg-3 col-md-12 col-sm-12 social-share p-3 d-flex align-items-center justify-content-center rounded-3 text-center mx-3"
									key={key}
								>
									<IconContext.Provider
										value={{
											size: '2rem',
											color: 'var(--text-color)',
											paddingRight: '1rem',
										}}
									>
										<Icon />
									</IconContext.Provider>
									<div className="mx-3">
										<Text text={title} />
									</div>
								</div>
							</>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default ShareApp;

import React from 'react';

const Title = ({ title, color }) => {
	const titlestyle = {
		color: color,
	};
	return (
		<div className="">
			<h1 className="title" style={titlestyle}>
				{title}
			</h1>
		</div>
	);
};

export default Title;

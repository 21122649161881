import React from 'react';
import { FaSearch } from 'react-icons/fa';

const ButtonPrimary = (props) => {
	const handleClick = () => {
		if (props.onClick) {
			props.onClick();
		}
	};
	const { color, bgColor, icon, size } = props;

	const componentStyle = {
		backgroundColor: bgColor,
		color: color,
		fontSize: size,
	};

	return (
		<>
			{/* This makes sure the div takes up full width */}
			<button
				className="theme-btn button-primary border-0 full-width-btn text-center"
				onClick={handleClick}
				style={{ ...componentStyle }}
				// data-bs-toggle={props. data - bs - toggle}
				// data-bs-target={data - bs - target}
			>
				{icon && <span className="icon">{icon}</span>}
				{props.text}
			</button>
		</>
	);
};

export default ButtonPrimary;

import React from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import CompanyProfileForm from '../../components/forms/CompanyProfileForm';
import SocialNetwork from '../../components/forms/SocialNetwork';
import Title from '../../components/core/typography/Title';
import SubText from '../../components/core/typography/SubText';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';

const CompanyProfile = () => {
	return (
		<>
			<div>
				<div className="container-lg container-md container-sm pt-3">
					<ProfileTitleSection
						title={'Company Profile'}
						subText={'Ready to jump back in?'}
					/>
					<div className="container mt-5 ">
						<div className="container p-2 mb-5 bg-dark rounded-4">
							<CompanyProfileForm />
						</div>
						<div className="container p-2 mb-5 bg-dark rounded-4">
							<SocialNetwork />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CompanyProfile;

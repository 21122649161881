import React from 'react';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import { useState } from 'react';
import Title from '../../components/core/typography/Title';
import ApplicantCard from '../../components/core/cards/ApplicantCard';
import { fetchApplicants } from '../../API/ApplicantsApi';
import { useEffect } from 'react';
import Pagination from '../../components/pagination/Pagination';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
// const applicantDetails = [
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// 	{
// 		applicantImg: <BlogProfile />,
// 		title: 'Software Engineer (Android), Library',
// 		designation: 'UI Developer',
// 		location: 'Mumbai, India',
// 		hoursAgo: '11 hours ago',
// 		salary: '2LPA - 5LPA',
// 		remote: 'WFH',
// 		joining: 'Urgent',
// 		workType: 'Full Time',
// 	},
// ];
const status = [
	{
		title: 'Total(s)',
		value: `6`,
	},
	{
		title: 'Approved',
		value: '3',
	},
	{
		title: 'Rejected(s)',
		value: '2',
	},
];

const ITEMS_PER_PAGE = 10;
const Applicants = () => {
	// Bookmark state
	const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
	const [applicants, setApplicants] = useState([]);
	const [currentPage, setCurrentPage] = useState(1); // <-- Add state for current page

	const totalPages = Math.ceil(applicants.length / ITEMS_PER_PAGE); // <-- Calculate total pages based on applicants length

	const paginatedApplicants = applicants.slice(
		(currentPage - 1) * ITEMS_PER_PAGE,
		currentPage * ITEMS_PER_PAGE,
	);
	// Bookmark toggle function
	const toggleBookmark = (index) => {
		if (bookmarkedJobs.includes(index)) {
			// If the job is already bookmarked, remove it from the array
			setBookmarkedJobs((prev) =>
				prev.filter((jobIndex) => jobIndex !== index),
			);
		} else {
			// Otherwise, add it to the array
			setBookmarkedJobs((prev) => [...prev, index]);
		}
	};

	useEffect(() => {
		const getApplicants = async () => {
			try {
				const fetchedApplicants = await fetchApplicants();
				setApplicants(fetchedApplicants);
			} catch (error) {
				console.error('Failed to fetch applicants:', error);
			}
		};
		getApplicants();
	}, []);

	return (
		<>
			<section className=" applicants ">
				<div className=" container-lg container-md container-sm pt-3">
					<ProfileTitleSection
						title={'Applicants'}
						subText={'Ready to jump back in?'}
					/>
					<div className="container rounded d-flex align-items-center pt-3 pb-5">
						<div class="detail-title  ">
							<div class=" d-flex flex-lg-row flex-column justify-content-between align-items-lg-center">
								<SubTitle
									subTitle={`Senior Product Designer`}
									size={`2.6rem`}
								/>
								<div className="pt-3">
									<ListSwitcher />
								</div>
							</div>
						</div>
					</div>
					<div className=" row container m-auto p-2 pt-5 pb-5 rounded-4 mt- 5 d-flex justify-content-center bg-dark align-items-center">
						{status.map((curElement, key) => {
							const { title, value } = curElement;
							return (
								<>
									<div className="container  col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
										<Text text={title} />
										<span
											style={{
												display: 'inline-block',
												fontSize: 'inherit',
												fontWeight: 'bolder',
											}}
											className="mx-2 mt-0 "
										>
											:
										</span>
										<Text text={value} />
									</div>
								</>
							);
						})}
					</div>
					<section className=" pt-5 pb-5">
						<div className="row d-flex mb-5 g-3">
							{paginatedApplicants.map(
								(
									curElement,
									index, // <-- Use paginatedApplicants here
								) => (
									<div className="col-lg-6 col-md-12 col-sm-12 mb-3">
										<ApplicantCard
											key={index}
											details={curElement}
											isBookmarked={bookmarkedJobs.includes(
												index,
											)}
											onBookmarkToggle={() =>
												toggleBookmark(index)
											}
										/>
									</div>
								),
							)}
						</div>

						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={(page) => setCurrentPage(page)}
						/>
					</section>
				</div>
			</section>
		</>
	);
};

export default Applicants;

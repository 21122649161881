import React from 'react';
import Title from '../../components/core/typography/Title';
import CompanyFilter from '../../components/companyFilter/CompanyFilter';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import CompanyCard from '../../components/core/cards/CompanyCard';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import { Modal } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
import { useState } from 'react';
import { IconContext } from 'react-icons';
import { IoFilter } from 'react-icons/io5';
import ButtonSecondary from '../../components/core/buttons/ButtonSecondary';

const companies = new Array(8).fill({});

const Companies = () => {
	// state
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

	const toggleFilterModal = () => {
		setIsFilterModalVisible(!isFilterModalVisible);
	};

	const companies = [
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
	];
	return (
		<div className="container-lg container-md container-sm ">
			<div className="title-section px-5 d-flex justify-content-between pb-5 align-items-center">
				<div>
					<Title title="Companies" />
				</div>
				<div>
					<ButtonPrimary text={'Create New Employer'} />
				</div>
			</div>
			<div className="row gx-0 content-section">
				{/* Filters column - Always visible on large screens */}
				<div
					className="filters-column hidden-1023  col-lg-4 col-md-12 col-sm-12 d-none d-lg-block"
					style={{ width: '35rem' }}
				>
					<CompanyFilter />
				</div>
				<div className="container col-lg-8 col-md-12 col-sm-12 jobs-list">
					{/* Filter button visible only on small and medium screens */}

					<div className="d-flex align-items-center justify-content-center d-lg-none pb-3 ">
						<IconContext.Provider
							value={{
								size: '2rem',
								color: 'var(--common-color)',
							}}
						>
							<IoFilter onClick={toggleFilterModal} />
						</IconContext.Provider>
						<ButtonSecondary
							text={'Filter'}
							onClick={toggleFilterModal}
						/>
					</div>
					<div className="ls-switcher container m-auto pb-5 position-relative d-flex justify-content-between align-items-center">
						<div className="showing-result">
							<div className="text text-white">
								<strong>20</strong> companies
							</div>
						</div>
						<ListSwitcher />
					</div>
					{companies.map((company, index) => (
						<CompanyCard
							key={index}
							companyName={company.companyName}
							companyLocation={company.companyLocation}
							companyField={company.companyField}
							companyJobs={company.companyJobs}
						/>
					))}
				</div>
			</div>
			{/* Filter Modal for medium and smaller screens */}
			<Modal
				show={isFilterModalVisible}
				onHide={toggleFilterModal}
				size="lg"
				className="d-lg-none left-side-modal"
			>
				<div className="d-flex justify-content-end pt-4 pe-4">
					<FiX
						style={{
							cursor: 'pointer',
							fontSize: '2.3rem',
							border: '.2rem solid white',
							borderRadius: '5rem',
						}}
						onClick={toggleFilterModal}
					/>
				</div>
				<Modal.Body>
					<CompanyFilter />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Companies;

import React from 'react';
import '../../assets/styles.css';
import HomeBanner from '../core/img/HomeBanner';
import SearchBar from './SearchBar';
import Title from '../core/typography/Title';
import Text from '../core/typography/Text';
const HeroSection = () => {
	const title = 'Find the Job';
	const title1 = 'That Fits Your Life';
	const text = `We connect talented people with great companies. Join our network today.`;

	return (
		<>
			<div className="banner" id="banner">
				<div
					id="carouselExampleIndicators"
					className="carousel slide"
					data-ride="carousel"
				>
					<div className="carousel-inner">
						<div className="carousel-item item-bg active">
							<HomeBanner />
							<div className="carousel-caption banner-slider-inner d-flex h-100">
								<div className="carousel-content container ">
									<div className="banner-inner ">
										<div className="m-auto ">
											<Title title={title} />
											<Title title={title1} />
											<Text text={text} />
										</div>
										{/* <div className="container mt-5 pt-5 pb-md-5 mb-md-5">
											<SearchBar />
										</div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HeroSection;

import React from 'react';
import blogImg from '../../../assets/images/blogImg/post1.jpg';
const BlogPost = () => {
	return (
		<div>
			<img src={blogImg} alt="resource" />
		</div>
	);
};

export default BlogPost;

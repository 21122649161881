import React from 'react';
import SocialNetwork from '../../components/forms/SocialNetwork';
import ApplicantForm from '../../components/forms/ApplicantForm';
import Title from '../../components/core/typography/Title';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';

const CreateApplicant = () => {
	return (
		<>
			<div className="container-lg container-md container-sm pt-3">
				<ProfileTitleSection
					title={'Create Applicant'}
					subText={'Ready to jump back in?'}
				/>
				<div className="container mt-5">
					<div className=" container p-2 mb-5 bg-dark rounded-4 ">
						<ApplicantForm />
					</div>
					<div className="container p-2 mb-5 bg-dark rounded-4">
						<SocialNetwork />
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateApplicant;

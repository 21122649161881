// ACTUAL API
// *******************************
// import axios from 'axios';

// const BASE_URL = 'https://api.yourwebsite.com/';

// export const getApplicantDetails = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}interviewSchedule/applicantDetails`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch applicant details:', error);
//         return [];
//     }
// };

// export const getCategories = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}interviewSchedule/categories`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch categories:', error);
//         return [];
//     }
// };

// export const getStartTimes = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}interviewSchedule/startTimes`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch start times:', error);
//         return [];
//     }
// };

// export const getEndTimes = async () => {
//     try {
//         const response = await axios.get(`${BASE_URL}interviewSchedule/endTimes`);
//         return response.data;
//     } catch (error) {
//         console.error('Failed to fetch end times:', error);
//         return [];
//     }
// };

// *************************
// HARDCODED DATA

const hardcodedApplicantDetails = [
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	//... More applicants can be added here
];

const hardcodedCategories = [
	{ value: '', label: 'Default' },
	{ value: '', label: 'Newest' },
	{ value: '', label: 'Oldest' },
	//... More categories
];

const hardcodedStartTimes = [
	{ value: '', label: '9:00AM' },
	{ value: '', label: '10:00AM' },
	{ value: '', label: '11:00AM' },
	//... More start times
];

const hardcodedEndTimes = [
	{ value: '', label: '4:00PM' },
	{ value: '', label: '5:00PM' },
	{ value: '', label: '6:00PM' },
	//... More end times
];

export const getApplicantDetails = async () => {
	try {
		// Simulating an API delay using setTimeout
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(hardcodedApplicantDetails);
			}, 1000); // 1-second delay for simulation
		});
	} catch (error) {
		console.error('Failed to fetch hardcoded applicant details:', error);
		return [];
	}
};

export const getCategories = async () => {
	try {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(hardcodedCategories);
			}, 1000);
		});
	} catch (error) {
		console.error('Failed to fetch categories:', error);
		return [];
	}
};

export const getStartTimes = async () => {
	try {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(hardcodedStartTimes);
			}, 1000);
		});
	} catch (error) {
		console.error('Failed to fetch start times:', error);
		return [];
	}
};

export const getEndTimes = async () => {
	try {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(hardcodedEndTimes);
			}, 1000);
		});
	} catch (error) {
		console.error('Failed to fetch end times:', error);
		return [];
	}
};

import React from 'react';
import CompaniesLogo from '../img/CompaniesLogo';
import ContentTitle from '../typography/ContentTitle';
import SubText from '../typography/SubText';
import ButtonPrimary from '../buttons/ButtonPrimary';
import { Link } from 'react-router-dom';
import { FiMapPin } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';

const JobCard = ({ id, jobTitle, location, jobType, salaryRange }) => {
	// DRY: Create a structured array and map through it
	const jobDetail = [
		{ Icon: <FiMapPin />, detail: location },
		{ Icon: <AiOutlineClockCircle />, detail: jobType },
		{ Icon: <IoCashOutline />, detail: salaryRange },
	];

	return (
		<div className="job-item media">
			<div className="company-logo">
				<CompaniesLogo />
			</div>
			<div className="media-body align-self-center">
				<ContentTitle contentTitle={jobTitle} />
				<div className="d-flex ">
					{jobDetail.map((element, key) => {
						const { Icon, detail } = element;
						return (
							<div className="job-ad-item pt-2 " key={key}>
								<div className="d-flex flex-wrap ">
									<div className="d-flex align-items-center me-3">
										<IconContext.Provider
											value={{
												size: '1.7rem',
												color: 'var(--subtext-color)',
												className: 'me-2',
											}}
										>
											{Icon}
										</IconContext.Provider>
										<SubText
											subText={detail}
											// size={'1.7rem'}
										/>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="text-end">
					<Link to={`/jobdetails/${id}`}>
						<ButtonPrimary text="View Details" />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default JobCard;

import React from 'react';
const Pagination = ({ prevPost, nextPost }) => {
	return (
		<div className="post-control">
			<div>
				{prevPost && (
					<div className="prev-post position-relative">
						<span className="icon fa-solid fa-chevron-left"></span>
						<span className="title">Previous Post</span>
					</div>
				)}
			</div>
			<div>
				{nextPost && (
					<div className="next-post">
						<span className="title me-3">Next Post</span>
						<span className="icon fa-solid fa-chevron-right"></span>
					</div>
				)}
			</div>
		</div>
	);
};

export default Pagination;

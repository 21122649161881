// Actual API
// **********************************
// import axios from 'axios';

// const BASE_URL = 'https://yourapiurl.com/api/';

// export const getApplicantDetails = async () => {
// 	try {
// 		const response = await axios.get(
// 			`${BASE_URL}browseCandidate/applicants`,
// 		);
// 		return response.data;
// 	} catch (error) {
// 		console.error('Failed to fetch applicant details:', error);
// 		return [];
// 	}
// };

// **********************************

// Hardcoded data
import Candidate from '../components/core/img/Candidate';
const hardcodedApplicantDetails = [
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},

	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
	{
		applicantImg: <Candidate />,
		title: 'Software Engineer (Android), Library',
		designation: 'UI Developer',
		location: 'Mumbai, India',
		salary: '2LPA - 5LPA',
		skill1: 'App',
		skill2: 'Design',
		skill3: 'Digital',
	},
];

export const getApplicantDetails = async () => {
	try {
		// Simulating an API delay using setTimeout
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(hardcodedApplicantDetails);
			}, 1000); // 1 second delay for simulation
		});
	} catch (error) {
		console.error('Failed to fetch hardcoded applicant details:', error);
		return [];
	}
};

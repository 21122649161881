import React, { useState, useEffect } from 'react';
import {
	MdDashboardCustomize,
	MdMessage,
	MdPersonSearch,
} from 'react-icons/md';
import { LuArrowLeftRight, LuCalendarClock, LuLogOut } from 'react-icons/lu';
import { RiLockPasswordFill, RiDeleteBin6Fill } from 'react-icons/ri';
import MenteechainLogo from '../core/img/MenteechainLogo';
import { Link, useNavigate } from 'react-router-dom';
import { HiDocumentText } from 'react-icons/hi';
import { HiBellAlert } from 'react-icons/hi2';
import {
	FaBars,
	FaFileUpload,
	FaBriefcase,
	FaUsers,
	FaSearchPlus,
	FaCodepen,
	FaUser,
	FaFileVideo,
	FaUserGraduate,
} from 'react-icons/fa';
import { IoIosCreate } from 'react-icons/io';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { AiFillFileAdd } from 'react-icons/ai';
import { Modal } from 'react-bootstrap';
import ButtonSecondary from '../core/buttons/ButtonSecondary';
import { IoFilter } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import { FiX } from 'react-icons/fi';
const ProfileSidebar = ({ children }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => setIsModalOpen(!isModalOpen);
	const toggle = () => setIsOpen(!isOpen);
	const menuItem = [
		{
			path: '/dashboard',
			title: 'Dashboard',
			icon: <MdDashboardCustomize />,
		},
		{
			path: '/companyprofile',
			title: 'Company Profile',
			icon: <FaUser />,
		},
		{
			path: '/jobpost',
			title: 'Post a New Job',
			icon: <FaFileUpload />,
		},
		{
			path: '/jobs',
			title: 'All Jobs',
			icon: <FaBriefcase />,
		},
		{
			path: '/applicants',
			title: 'All Applicants',
			icon: <FaUsers />,
		},

		{
			path: '/forwardcandidate',
			title: 'Forward Candidates',
			icon: <LuArrowLeftRight />,
		},
		{
			path: '/shortlistresume',
			title: 'Shortlisted Resume',
			icon: <FaSearchPlus />,
		},

		{
			path: '/viewinterview',
			title: 'Interviews',
			icon: <FaCodepen />,
		},
		{
			path: '/message',
			title: 'Messages',
			icon: <MdMessage />,
		},
		{
			path: '/changepassword',
			title: 'Change Password',
			icon: <RiLockPasswordFill />,
		},

		{
			path: '/candidateresume',
			title: 'Candidate Resume',
			icon: <FaUserGraduate />,
		},
		{
			path: '/resumealerts',
			title: 'Resume Alert',
			icon: <HiBellAlert />,
		},
		{
			path: '/interviews',
			title: 'View Interviews',
			icon: <FaFileVideo />,
		},
		{
			path: '/creatapplicant',
			title: 'Create Applicant',
			icon: <IoIosCreate />,
		},
		{
			path: '/browsecandidate',
			title: 'Browse Candidate',
			icon: <MdPersonSearch />,
		},
		{
			path: '/clientsidecompanies',
			title: 'Client Side Companies',
			icon: <BsFillBuildingsFill />,
		},
		{
			path: '/interviewschedule',
			title: 'Interview Schedule',
			icon: <LuCalendarClock />,
		},
		{
			path: '/addblog',
			title: 'Add Blog',
			icon: <AiFillFileAdd />,
		},
		{
			title: 'Delete Profile',
			icon: <RiDeleteBin6Fill />,
		},
		{ title: 'Logout', icon: <LuLogOut /> },
	];

	const navigate = useNavigate();

	useEffect(() => {
		navigate('/profile/dashboard');
	}, []);
	return (
		<div className="profile-section d-flex ">
			{/* Button to open modal, only visible on medium and smaller screens */}

			<div className="profile-sidebar d-none d-lg-block">
				<div
					style={{ width: isOpen ? '30rem' : '5rem' }}
					className="sidebar"
				>
					<div className="top_section">
						<div
							className="icon logo"
							style={{ display: isOpen ? 'block' : 'none' }}
						>
							<Link to={`/`}>
								<MenteechainLogo />
							</Link>
						</div>
						<div
							style={{ marginLeft: isOpen ? '16rem' : '0rem' }}
							className="bars"
						>
							<FaBars onClick={toggle} />
						</div>
					</div>
					{menuItem.map((item, index) => (
						<Link
							to={`/profile${item.path}`}
							key={index}
							className="link d-flex"
							activeclassName="active"
						>
							<div className="icon">{item.icon}</div>
							<div
								style={{ display: isOpen ? 'block' : 'none' }}
								className="link_text"
							>
								{item.title}
							</div>
						</Link>
					))}
				</div>
			</div>
			{/* Modal for medium and smaller screens */}
			<Modal
				show={isModalOpen}
				onHide={toggleModal}
				size="md"
				className="d-lg-none left-side-modal"
				// style={{ width: '40rem' }}
				// animation={false}
			>
				<div className="d-flex justify-content-end pt-4 pe-4 ">
					<FiX
						style={{
							cursor: 'pointer',
							fontSize: '2.3rem',
							border: '.2rem solid white',
							borderRadius: '5rem',
						}}
						onClick={toggleModal}
					/>
				</div>
				<Modal.Body>
					{/* Your sidebar content, duplicated inside the modal */}
					{/* ... rest of your sidebar content ... */}
					<div style={{ width: '30rem' }} className="sidebar">
						{menuItem.map((item, index) => (
							<Link
								to={`/profile${item.path}`}
								key={index}
								className="link d-flex"
								activeclassName="active"
								onClick={toggleModal}
							>
								<div className="icon">{item.icon}</div>
								<div className="link_text">{item.title}</div>
							</Link>
						))}
					</div>
				</Modal.Body>
			</Modal>
			<main className="main-content mt-3">
				<div className="container mt-5 pt-5 d-flex  align-items-center d-lg-none  ">
					<div className="menu-btn me-1 mx-2 px-5">
						<IconContext.Provider
							value={{
								size: '2.3rem',
								color: 'var(--common-color)',
							}}
						>
							<IoFilter onClick={toggleModal} />
						</IconContext.Provider>
						<ButtonSecondary text={'Menu'} onClick={toggleModal} />
					</div>
				</div>
				{children}
			</main>
		</div>
	);
};

export default ProfileSidebar;

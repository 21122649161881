import React from 'react';
import CandidateProfile from '../../../assets/images/candidate.jpg';
const Candidate = () => {
	return (
		<div>
			<img
				src={CandidateProfile}
				alt="candidate profile"
				width="100"
				height="100"
				className="rounded-circle"
			/>
		</div>
	);
};

export default Candidate;

import React, { useState } from 'react';
import Input from '../core/typography/Input';
import LabelText from '../core/typography/LabelText';
import SubTitle from '../core/typography/SubTitle';
import ButtonTertiary from '../core/buttons/ButtonTertiary';

const SocialNetwork = () => {
	// Initialize state for form values and errors
	const [formData, setFormData] = useState({});
	const [formErrors, setFormErrors] = useState({});

	// Define the content of the form
	const formContent = [
		{
			label: 'Facebook',
			type: 'text',
			id: 'floatingFb',
			name: 'facebook',
			placeholder: 'Facebook-url',
		},
		{
			label: 'Twitter',
			type: 'text',
			id: 'floatingTw',
			name: 'twitter',
			placeholder: 'Twitter-url',
		},
		{
			label: 'LinkedIn',
			type: 'text',
			id: 'floatingLi',
			name: 'linkedin',
			placeholder: 'LinkedIn-url',
		},
		{
			label: 'Google Plus',
			type: 'text',
			id: 'floatingGp',
			name: 'goolgle',
			placeholder: 'Google-Plus',
		},
	];

	// Validation function
	const validateForm = () => {
		let errors = {};

		// Validate each URL
		for (const field of formContent) {
			if (!formData[field.name]) {
				errors[field.name] = `${field.label} URL is required`;
			} else if (!formData[field.name].startsWith('http')) {
				// Simple URL validation
				errors[field.name] = `Invalid ${field.label} URL`;
			}
		}

		setFormErrors(errors);
		return Object.keys(errors).length === 0; // Return true if no errors
	};

	// Form submit handler
	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			console.log(formData);
			// Further processing
		}
	};

	// Input change handler
	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				{/* Form content */}
				<div className="row container p-5 mb-5 d-flex justify-content-between">
					<div className="pb-4">
						<SubTitle
							subTitle={'Social Network Acknowledgement'}
							size={'2.5rem'}
						/>
					</div>

					{/* Map over formContent to generate form fields */}
					{formContent.map((field, key) => (
						<div className="col-lg-5 col-md-12 col-sm-12" key={key}>
							<LabelText labelText={field.label} />
							<Input
								type={field.type}
								label={field.placeholder}
								name={field.name}
								id={field.id}
								value={formData[field.name] || ''}
								onChange={handleInputChange}
							/>
							{/* Display error if it exists */}
							{formErrors[field.name] && (
								<p className="text-danger">
									{formErrors[field.name]}
								</p>
							)}
						</div>
					))}

					<div className="d-flex justify-content-end pb-4">
						<ButtonTertiary text={'Save'} />
					</div>
				</div>
			</form>
		</>
	);
};

export default SocialNetwork;

import axios from 'axios';

const BASE_URL = 'https://fakestoreapi.com';

export const loginUser = async (loginData) => {
	try {
		// const response = await axios.post(`${BASE_URL}/auth/login`, loginData);
		const response = await axios.post(`${BASE_URL}/users`, loginData);
		if (response.data && response.data.token) {
			return response.data;
		} else {
			throw new Error('Failed to login. Please try again.');
		}
	} catch (error) {
		throw error;
	}
};

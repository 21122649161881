import React, { useState } from 'react';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import ButtonPrimary from '../core/buttons/ButtonPrimary';
import TextArea from '../core/typography/TextArea';

const BlogMessage = () => {
	// 1. State management for form inputs
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		subject: '',
		message: '',
	});

	const [errorMessage, setErrorMessage] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// 2.1 Validate form inputs
		for (let key in formData) {
			if (!formData[key]) {
				setErrorMessage(`Please fill out the ${key} field.`);
				return;
			}
		}

		if (!/\S+@\S+\.\S+/.test(formData.email)) {
			setErrorMessage('Please enter a valid email address.');
			return;
		}

		// 2.2 If all validations pass, reset error message and process form
		setErrorMessage('');
		// ... send data to server or other processing
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<div className="row">
					{/* Display validation error */}
					{errorMessage && (
						<div className="form-group col-lg-12 col-md-12 col-sm-12">
							<div className="response text-danger">
								{errorMessage}
							</div>
						</div>
					)}

					{/* Name Input */}
					<div className="col-lg-6 col-md-12 col-sm-12 form-group">
						<LabelText labelText="Enter your name" />
						<Input
							type="text"
							name="username"
							label="Name"
							id="floatingName"
							value={formData.username}
							onChange={handleChange}
						/>
					</div>

					{/* Email Input */}
					<div className="col-lg-6 col-md-12 col-sm-12 form-group">
						<LabelText labelText="Enter your email" />
						<Input
							type="email"
							name="email"
							label="Email"
							id="floatingEmail"
							value={formData.email}
							onChange={handleChange}
						/>
					</div>

					{/* Subject Input */}
					<div className="col-lg-12 col-md-12 col-sm-12 form-group">
						<LabelText labelText="Subject" />
						<Input
							type="text"
							name="subject"
							label="Subject"
							id="floatingSubject"
							value={formData.subject}
							onChange={handleChange}
						/>
					</div>

					{/* Message Input */}
					<div className="col-lg-12 col-md-12 col-sm-12 form-group">
						<LabelText labelText="Message" />
						<TextArea
							name="message"
							placeholder="Write message here..."
							value={formData.message}
							onChange={handleChange}
						/>
					</div>

					{/* Submit Button */}
					<div className="col-lg-12 col-md-12 col-sm-12 form-group">
						<ButtonPrimary text="Send Message" />
					</div>
				</div>
			</form>
		</div>
	);
};

export default BlogMessage;

import React, { useState } from 'react';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import SelectInput from '../core/typography/SelectInput';
import TextArea from '../core/typography/TextArea';
import ButtonTertiary from '../core/buttons/ButtonTertiary';

const salaryOptions = [
	// Your options...
];

const genderOptions = [
	// Your options...
];

const ageOptions = [
	// Your options...
];

const categoriesOptions = [
	// Your options...
];

const searchListingOptions = [
	// Your options...
];

const formFields = [
	{
		label: 'Full Name',
		name: 'fullname',
		type: 'input',
		placeholder: 'Full Name',
	},
	{
		label: 'Job Title',
		name: 'jobtitle',
		type: 'input',
		placeholder: 'Job Title',
	},
	{
		label: 'Phone Number',
		name: 'phonenumber',
		type: 'input',
		placeholder: 'Phone Number',
	},
	{ label: 'Email', name: 'email', type: 'input', placeholder: 'Email' },
	{
		label: 'Website',
		name: 'website',
		type: 'input',
		placeholder: 'Website',
	},
	{
		label: 'Experience',
		name: 'experience',
		type: 'input',
		placeholder: 'Experience',
	},
	{
		label: 'Age',
		name: 'age',
		type: 'input',
		placeholder: ageOptions,
	},
	{
		label: 'Education Level',
		name: 'education',
		type: 'input',
		placeholder: 'Education Level',
	},

	{
		label: 'Languages',
		name: 'languages',
		type: 'input',
		placeholder: 'Languages',
	},
	{
		label: 'Categories',
		name: 'categories',
		type: 'select',
		options: categoriesOptions,
	},
	{
		label: 'Allow in search & listing',
		name: 'searchlisting',
		type: 'select',
		options: searchListingOptions,
	},
];
const ApplicantForm = () => {
	// states for form validation
	const [formData, setFormData] = useState({});
	const [errors, setErrors] = useState({});

	// Handle input changes and set the form data
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};
	// Basic validation
	const validate = () => {
		let tempErrors = {};

		// Check for empty fields
		formFields.forEach((field) => {
			if (!formData[field.name])
				tempErrors[field.name] = `${field.label} is required.`;
		});

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Perform validation
		if (validate()) {
			console.log('Form data submitted:', formData);
		}
	};
	return (
		<form
			onSubmit={() => {
				handleSubmit();
			}}
		>
			<div className="row d-flex justify-content-between p-5 rounded-5">
				{formFields.map((field, index) => {
					if (field.label === 'Experience') {
						return (
							<>
								<div className="col-lg-5 col-md-12 col-sm-12 d-flex">
									<div className="w-50 pe-2">
										<LabelText labelText={'Salary'} />
										<SelectInput options={salaryOptions} />
									</div>
									<div className="w-50 ps-2">
										<LabelText labelText={'Gender'} />
										<SelectInput options={genderOptions} />
									</div>
								</div>
								<div className="col-lg-5">
									<LabelText labelText={field.label} />
									<Input
										label={field.placeholder}
										name={field.name}
										type="text"
									/>
									{errors[field.name] && (
										<span className="text-danger">
											{errors[field.name]}
										</span>
									)}
								</div>
							</>
						);
					}
					return (
						<div className="col-lg-5" key={index}>
							<LabelText labelText={field.label} />
							{field.type === 'select' ? (
								<SelectInput options={field.options} />
							) : (
								<>
									<Input
										label={field.placeholder}
										name={field.name}
										type="text"
									/>
									{errors[field.name] && (
										<span className="text-danger">
											{errors[field.name]}
										</span>
									)}
								</>
							)}
						</div>
					);
				})}
				<div className="col-lg-12 pt-5">
					<LabelText labelText={'Description'} />
					<TextArea placeholder={'Your Description...'} />
				</div>
				<div className="col-12 d-flex justify-content-end pt-5">
					<ButtonTertiary text={'Submit'} />
				</div>
			</div>
		</form>
	);
};
export default ApplicantForm;

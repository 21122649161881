import React, { useEffect, useState } from 'react';
import { FiUserCheck } from 'react-icons/fi';
import { ImProfile } from 'react-icons/im';
import { PiBuildingsBold, PiUserGear } from 'react-icons/pi';
import ContentTitle from '../core/typography/ContentTitle';
import { fetchCounterData } from '../../API/CounterDataApi';

const CounterSection = () => {
	const [counterData, setCounterData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchCounterData();
				setCounterData(data.counters);
			} catch (error) {
				console.error('Error fetching counter data:', error);
			}
		};

		fetchData();
	}, []);

	const IconComponents = {
		ImProfile: <ImProfile className="icon" />,
		PiUserGear: <PiUserGear className="icon" />,
		FiUserCheck: <FiUserCheck className="icon" />,
		PiBuildingsBold: <PiBuildingsBold className="icon" />,
	};

	return (
		<div className="counters">
			<div className="container">
				<div className="row">
					{counterData.map((counter) => (
						<div
							className="col-lg-3 col-md-3 col-sm-6"
							key={counter.title}
						>
							<div className="counter-box">
								{IconComponents[counter.icon]}
								<h1 className="counter Starting">
									{counter.value}
								</h1>
								<ContentTitle contentTitle={counter.title} />
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default CounterSection;

import React, { useEffect, useReducer, useState } from 'react';
import Text from '../../components/core/typography/Text';
import SubText from '../../components/core/typography/SubText';
import ContentTitle from '../../components/core/typography/ContentTitle';
import { IconContext } from 'react-icons';
import {
	FaBookmark,
	FaCaretDown,
	FaCaretUp,
	FaRegBookmark,
	FaRupeeSign,
	FaShoppingBag,
} from 'react-icons/fa';
import { BiBitcoin, BiDollar } from 'react-icons/bi';
import { BsCurrencyEuro } from 'react-icons/bs';
import Chart from '../../components/chart/Chart';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import BlogProfile from '../../components/core/img/BlogProfile';
import TeamMemberImg from '../../components/core/img/TeamMemberImg';
import Title from '../../components/core/typography/Title';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import ApplicantCard from '../../components/core/cards/ApplicantCard';
import { fetchApplicants } from '../../API/ApplicantsApi';
import {
	getApplicantDetails,
	getCountryData,
	getNotifications,
	getStatusData,
	getTeamMembers,
} from '../../API/DashboardApi';

const initialState = {
	notifications: [],
	applicantDetails: [],
	teamMembers: [],
	countryData: [],
	statusData: [],
	bookmarkedJobs: [],
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_NOTIFICATIONS':
			return { ...state, notifications: action.payload };
		case 'SET_APPLICANT_DETAILS':
			return { ...state, applicantDetails: action.payload };
		case 'SET_TEAMMEMBERS_DETAILS':
			return { ...state, teamMembers: action.payload };
		case 'TOGGLE_BOOKMARK':
			return state.bookmarkedJobs.includes(action.payload)
				? {
						...state,
						bookmarkedJobs: state.bookmarkedJobs.filter(
							(id) => id !== action.payload,
						),
				  }
				: {
						...state,
						bookmarkedJobs: [
							...state.bookmarkedJobs,
							action.payload,
						],
				  };
		default:
			return state;
	}
};

const StatusColumn = ({ title, count, change, changeIcon, changeColor }) => (
	<div className="status-col col-lg-3 col-md-6 col-sm-12 p-3">
		<div className="d-flex flex-column justify-content-between">
			<div className="mb-5">
				<ContentTitle contentTitle={title} />
			</div>
			<div className="d-flex justify-content-between">
				<Text text={count} />
				{change && (
					<div className="d-flex align-items-center">
						<IconContext.Provider
							value={{ size: '1.8rem', color: changeColor }}
						>
							{changeIcon}
						</IconContext.Provider>
						<SubText subText={change} color={changeColor} />
					</div>
				)}
			</div>
		</div>
	</div>
);

const CountryColumn = ({ country, value, icon, color }) => (
	<div className="countries-container col-lg-3 col-md-6 col-sm-12 p-3">
		<div className="d-flex flex-column justify-content-between text-center">
			<div className="mb-5">
				<ContentTitle contentTitle={country} />
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<Text text={value} />
				<IconContext.Provider value={{ size: '1.8rem', color }}>
					{icon}
				</IconContext.Provider>
			</div>
		</div>
	</div>
);

const NotificationItem = ({ notification }) => (
	<div className="d-flex align-items-center p-3">
		<div
			className="d-flex justify-content-center align-items-center border border-secondary rounded-circle me-3"
			style={{ width: '5rem', height: '5rem' }}
		>
			<IconContext.Provider value={{ size: '2rem', color: '#fff' }}>
				<FaShoppingBag />
			</IconContext.Provider>
		</div>
		<div className="me-2">
			<Text text={notification.name} />
		</div>
		<div className="me-2">
			<SubText subText={' applied for '} />
		</div>
		<div>
			<Text text={notification.jobTitle} color={'green'} />
		</div>
	</div>
);

const TeamMember = ({ member }) => (
	<div className="col-lg-12 col-md-12 col-sm-12 mb-4">
		<div className="d-flex applicant-card p-3 rounded-4 justify-content-between">
			<div className="d-flex align-items-center">
				{member.memberImg}
				<div className="mx-3">
					<ContentTitle contentTitle={member.name} />
				</div>
			</div>
			<div
				className="text-end d-flex align-items-center px-4  rounded-3"
				style={{ backgroundColor: '#fff' }}
			>
				<SubText
					subText={member.designation}
					color={'var(--common-color)'}
				/>
			</div>
		</div>
	</div>
);
const ApplicantList = ({ applicants, toggleBookmark, bookmarkedJobs }) => (
	<section className=" pb-5 ">
		<div className="row d-flex mb-4 pt-5 px-4 g-5">
			{applicants.map((curElement, index) => (
				<ApplicantCard
					key={index}
					details={curElement}
					isBookmarked={bookmarkedJobs.includes(index)}
					onBookmarkToggle={() => toggleBookmark(index)}
				/>
			))}
		</div>
	</section>
);
const Dashboard = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const {
		notifications,
		applicantDetails,
		teamMembers,
		countryData,
		statusData,
		bookmarkedJobs,
	} = state;

	// const [notifications, setNotifications] = useState([]);
	// const [applicantDetails, setApplicantDetails] = useState([]);
	// const [teamMembers, setTeamMembers] = useState([]);
	// const [countryData, setCountryData] = useState([]);
	// const [statusData, setStatusData] = useState([]);

	// // Bookmark state
	// const [bookmarkedJobs, setBookmarkedJobs] = useState([]);
	// Bookmark toggle function
	// const toggleBookmark = (index) => {
	// 	if (bookmarkedJobs.includes(index)) {
	// 		// If the job is already bookmarked, remove it from the array
	// 		setBookmarkedJobs((prev) =>
	// 			prev.filter((jobIndex) => jobIndex !== index),
	// 		);
	// 	} else {
	// 		// Otherwise, add it to the array
	// 		setBookmarkedJobs((prev) => [...prev, index]);
	// 	}
	// };

	const toggleBookmark = (index) => {
		dispatch({ type: 'TOGGLE_BOOKMARK', payload: index });
	};

	// fetching api

	useEffect(() => {
		const fetchData = async () => {
			try {
				const [
					notifs,
					applicantsDetailsData,
					members,
					countries,
					statuses,
				] = await Promise.all([
					getNotifications(),
					fetchApplicants(),
					getTeamMembers(),
					getCountryData(),
					getStatusData(),
				]);
				dispatch({
					type: 'SET_COUNTRIES_DETAILS',
					payload: countries,
				});
				dispatch({
					type: 'SET_STATUSES_DETAILS',
					payload: statuses,
				});
				dispatch({ type: 'SET_NOTIFICATIONS', payload: notifs });
				dispatch({
					type: 'SET_APPLICANT_DETAILS',
					payload: applicantsDetailsData,
				});
				dispatch({
					type: 'SET_TEAMMEMBERS_DETAILS',
					payload: members,
				});
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	return (
		<>
			<div className="container-lg container-md container-sm pt-3">
				<ProfileTitleSection
					title={'Dashboard'}
					subText={'Ready to jump back in?'}
				/>

				<div className="container pt-5 px-5">
					<div className="row container-lg container-md container-sm border-lg border-lg-dark">
						<StatusColumn title={'Jobs'} count={'456'} />
						<StatusColumn
							title={'Candidates'}
							count={'456'}
							change={'+56%'}
							changeIcon={<FaCaretUp />}
							changeColor={'green'}
						/>
						<StatusColumn
							title={'Rejection Rate'}
							count={'456'}
							change={'+56%'}
							changeIcon={<FaCaretDown />}
							changeColor={'red'}
						/>
						<StatusColumn title={'Currencies'} count={null} />
					</div>

					<div className="pt-5">
						<div className="row container-lg container-md container-sm border border-dark">
							<CountryColumn
								country={'Italy'}
								value={'456'}
								icon={<FaCaretUp />}
								color={'green'}
							/>
							<CountryColumn
								country={'United States'}
								value={'456'}
								icon={<FaCaretUp />}
								color={'green'}
							/>
							<CountryColumn
								country={'Canada'}
								value={'190'}
								icon={<FaCaretDown />}
								color={'red'}
							/>
							<CountryColumn
								country={'Spain'}
								value={'456'}
								icon={<FaCaretUp />}
								color={'green'}
							/>
						</div>
					</div>
				</div>

				<div className="pt-5">
					<div className="row" style={{ width: '100%' }}>
						<div className="container col-lg-6 col-md-12 col-sm-12">
							<Chart />
						</div>

						<div className="col-lg-6 col-md-12 col-sm-12 border border-dark p-4 pe-0 rounded-3 g-5">
							<ContentTitle contentTitle={'Notifications'} />
							{notifications.map((notification, key) => (
								<NotificationItem
									key={key}
									notification={notification}
								/>
							))}
						</div>
					</div>
				</div>

				<div className="pt-5 mt-4">
					<div
						className="row container "
						style={{
							width: '100%',
							height: '54.6rem',
							overflow: 'hidden',
						}}
					>
						<div className="container col-lg-4 col-md-12 col-sm-12">
							<div className="container d-flex justify-content-between px-4 ">
								<ContentTitle
									contentTitle={'Team Members'}
									size={'2.5rem'}
								/>
								<Text
									text={'View All'}
									color={'var(--common-color)'}
								/>
							</div>

							<section className="pt-5 pb-5 ">
								{teamMembers.map((member, key) => (
									<TeamMember key={key} member={member} />
								))}
							</section>
						</div>
						<div className=" col-lg-8 col-md-12 col-sm-12">
							<div className="container d-flex justify-content-between px-4 ">
								<ContentTitle
									contentTitle={'Applicants'}
									size={'2.5rem'}
								/>
								<Text
									text={'View All'}
									color={'var(--common-color)'}
								/>
							</div>
							<ApplicantList
								applicants={applicantDetails}
								toggleBookmark={toggleBookmark}
								bookmarkedJobs={bookmarkedJobs}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;

// const mockJobDetails = {
// 	jobSkills: [
// 		{
// 			skill: 'App',
// 		},
// 		{
// 			skill: 'Administrative',
// 		},
// 		{
// 			skill: 'Android',
// 		},
// 		{
// 			skill: 'WordPress',
// 		},
// 		{
// 			skill: 'React Js',
// 		},
// 		{
// 			skill: 'Node Js',
// 		},
// 	],

// 	jobs: [
// 		{
// 			jobTitle: 'Finance Manager',
// 			location: 'Gurugram, Delhi',
// 			jobType: 'Full Time',
// 			salaryRange: 'Rs 25,000 - Rs 35,000',
// 		},
// 		{
// 			jobTitle: 'Software Developer',
// 			location: 'Bangalore, Karnataka',
// 			jobType: 'Full Time',
// 			salaryRange: 'Rs 50,000 - Rs 70,000',
// 		},
// 		{
// 			jobTitle: 'Data Scientist',
// 			location: 'Pune, Maharashtra',
// 			jobType: 'Part Time',
// 			salaryRange: 'Rs 30,000 - Rs 45,000',
// 		},
// 	],

// 	jobDescription: `A Customer Success Manager is responsible for developing customer relationships that promote retention and loyalty. Their job is to work closely with customers to ensure they are satisfied with the services they receive and to improve upon areas of dissatisfaction.`,

// 	keyResponsibilities: [
// 		`1. Partnering with Sales team and execute effective product demonstrations, emphasizing product features and benefits with focus on the value of the solution`,

// 		`2. Provide strong account management to existing clients, offering support and keeping the abreast of new products and services that could be of value to their business`,

// 		`3. Ensure customer success by driving adoption, managing customer relationships, conducting business reviews and sharing best practices`,

// 		`4. Serve as primary point of escalation when customer issues arise, troubleshooting, and ensuring quick resolution`,

// 		`5. Coach customers to be product experts and train their teams on best practices so they become increasingly self-sufficient.`,

// 		`6. Be responsible for billing, upselling and renewals, and increase the revenue per customer.`,

// 		`7. Help drive customer references and case studies and produce reports and presentations to explain insights and results to marketing professionals.`,

// 		`8. Provide quantitative/qualitative analysis to the product team on how FA can better serveour customers.`,
// 		// ... Add more items here
// 	],

// 	requirements: [
// 		`– At least 4+ years relevant work experience in a customer-facing role (customer success or account management).`,

// 		`– Previous experience collaborating with different internal functions within a fast-growing SaaS company; operating at the intersection of product, sales, and delivery`,

// 		`– Experience in CRM/ERP (e.g. Salesforce, Pipedrive) or B2B Enterprise level software . Familiarity with Supply Chain data and workflow experience preferred`,

// 		`– Basic Knowledge of web technologies like HTML, SQL, API, Json, iOS and Android platforms`,

// 		`-Proficient in Microsoft Office Suite specifically Excel and PowerPoint`,
// 		// ... Add more items here
// 	],
// };

const mockJobDetails = [
	{
		id: 1,
		jobTitle: 'Senior Manager – Customer Success – SaaS',
		location: 'Gurugram, Delhi',
		jobType: 'Full Time',
		salaryRange: 'Rs 25,000 - Rs 35,000',
		skills: [`HTML`, `SQL`, `API`, `JSON`],
		description: `A Customer Success Manager is responsible for developing customer relationships that promote retention and loyalty. Their job is to work closely with customers to ensure they are satisfied with the services they receive and to improve upon areas of dissatisfaction.`,

		keyresponsibilities: [
			`1. Partnering with Sales team and execute effective product demonstrations, emphasizing product features and benefits with focus on the value of the solution`,

			`2. Provide strong account management to existing clients, offering support and keeping the abreast of new products and services that could be of value to their business`,

			`3. Ensure customer success by driving adoption, managing customer relationships, conducting business reviews and sharing best practices`,

			`4. Serve as primary point of escalation when customer issues arise, troubleshooting, and ensuring quick resolution`,

			`5. Coach customers to be product experts and train their teams on best practices so they become increasingly self-sufficient.`,

			`6. Be responsible for billing, upselling and renewals, and increase the revenue per customer.`,

			`7. Help drive customer references and case studies and produce reports and presentations to explain insights and results to marketing professionals.`,

			`8. Provide quantitative/qualitative analysis to the product team on how FA can better serveour customers.`,
		],
		requirements: [
			`– At least 4+ years relevant work experience in a customer-facing role (customer success or account management).`,

			`– Previous experience collaborating with different internal functions within a fast-growing SaaS company; operating at the intersection of product, sales, and delivery`,

			`– Experience in CRM/ERP (e.g. Salesforce, Pipedrive) or B2B Enterprise level software . Familiarity with Supply Chain data and workflow experience preferred`,

			`– Basic Knowledge of web technologies like HTML, SQL, API, Json, iOS and Android platforms`,

			`-Proficient in Microsoft Office Suite specifically Excel and PowerPoint`,
			// ... Add more items here
		],
	},
	{
		id: 2,
		jobTitle: 'Virtual relationship Manager – NR',
		location: 'Mumbai ',
		jobType: 'Full Time',
		salaryRange: 'Rs 50,000 - Rs 70,000',
		skills: [],
		description: `The ideal candidate will be responsible for gaining a deep understanding of the clients’ business objectives and challenges and creating strategic recommendations based off of this understanding. You will do so by building a strong relationship with our clients and analyzing our clients’ data. Essentially, you will act as the main point of contact for our clients.`,
		keyresponsibilities: [
			`Dialogue with our clients to understand their business objectives and challenges`,
			`Analyze clients’ data and provide strategic recommendations to clients`,
			`Present value-proposition to sales leads and current customers`,
			`Prospect for new sales leads and close sales`,
		],
		requirements: [
			`4-6 years’ of client relationship experience`,
			`Ability to manage multiple projects and clients`,
			`Strong communication and interpersonal skills`,
			`Wealth Management`,
			`Virtual RM Experience is Mandatory`,
			`Banking / Finance Background`,
			`Excellent Communication/ Well Groomed`,
			`Currently Working/ Serving Notice`,
			`No Immediate / Break`,
			`Open for Individual Contributor Role
		`,
		],
	},
	{
		id: 3,
		jobTitle: 'HR Business Partner – Recruitment Firm',
		location: 'Gurugram',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: `The HR Business Partner (HRBP) position is responsible for aligning business objectives with employees and management in designated business units.
		
		The position formulates partnerships across the HR function to deliver value-added service to management and employees that reflects the business objectives of the organization.
		
		The HRBP maintains an effective level of business literacy about the business unit’s financial position, its midrange plans, its culture and its competition.`,
		keyresponsibilities: [
			`– Conducts weekly meetings with respective business units.`,

			`– Consults with line management, providing HR guidance when appropriate.`,

			`– Analyzes trends and metrics in partnership with the HR group to develop solutions, programs and policies.`,

			`– Manages and resolves complex employee relations issues. Conducts effective, thorough and objective investigations.`,

			`– Maintains in-depth knowledge of legal requirements related to day-to-day management of employees, reducing legal risks and ensuring regulatory compliance. Partners with the legal department as needed/required.`,

			`– Provides day-to-day performance management guidance to line management (e.g., coaching, counseling, career development, disciplinary actions).`,

			`– Works closely with management and employees to improve work relationships, build morale, and increase productivity and retention.`,

			`– Provides HR policy guidance and interpretation.`,

			`– Develops contract terms for new hires, promotions and transfers.`,

			`– Assists international employees with expatriate assignments and related HR matters.`,

			`– Provides guidance and input on business unit restructures, workforce planning and succession planning.`,

			`– Identifies training needs for business units and individual executive coaching needs.`,

			`– Participates in evaluation and monitoring of training programs to ensure success. Follows up to ensure training objectives are met.`,

			`– Performs other related duties as assigned.`,
		],
		requirements: [
			`– Minimum of 6 years of experience resolving complex employee relations issues.`,

			`– Working knowledge of multiple human resource disciplines, including compensation practices, organizational diagnosis, employee and union relations, diversity, performance management, and federal and state respective employment laws.`,

			`– B.Tech CSE with MBA Preferred`,

			`– SHRM Certified Professional (SHRM-CP) or SHRM Senior Certified Professional (SHRM-SCP) credential or ability to obtain certification within one year of employment`,
		],
	},
	{
		id: 4,
		jobTitle: 'Manager – Enterprise Sales – SaaS Technology Domain',
		location: 'Mumbai ',
		jobType: 'Full Time',
		salaryRange: 'Rs 25,000 - Rs 35,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 5,
		jobTitle: 'Human Resources Manager',
		location: 'Tamil Nadu ',
		jobType: 'Full Time',
		salaryRange: 'Rs 50,000 - Rs 70,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 6,
		jobTitle: 'Diversity Recruiter',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 7,
		jobTitle: 'Technical Talent Acquisition Specialist',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 8,
		jobTitle: 'Finance Manager',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 9,
		jobTitle: 'Technical Sales Specialist ',
		location: 'Pune, Maharashtra',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [],
	},
	{
		id: 10,
		jobTitle: 'Manager – Human Resource',
		location: 'Bengaluru, Karnataka ',
		jobType: 'Part Time',
		salaryRange: 'Rs 30,000 - Rs 45,000',
		skills: [],
		description: ``,
		keyresponsibilities: [],
		requirements: [
			`– Minimum of 6 years of experience resolving complex employee relations issues.`,

			`– Working knowledge of multiple human resource disciplines, including compensation practices, organizational diagnosis, employee and union relations, diversity, performance management, and federal and state respective employment laws.`,

			`– B.Tech CSE with MBA Preferred`,

			`– SHRM Certified Professional (SHRM-CP) or SHRM Senior Certified Professional (SHRM-SCP) credential or ability to obtain certification within one year of employment`,
		],
	},
];
export async function fetchJobDetails() {
	// returning hardcoded data.
	const response = await new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				data: mockJobDetails,
			});
		}, 1000);
	});

	return response.data;
}

import React from 'react';
import Caption from './Caption';
import ButtonTertiary from '../buttons/ButtonTertiary';

const UploadDocument = ({
	acceptTypes,
	buttonText,
	showCaption,
	UploadImgComponent,
}) => {
	return (
		<div>
			<div className="uploadButton position-relative d-flex">
				<div>
					<input
						className="uploadButton-input position-relative overflow-hidden d-none pointer-event-none"
						type="file"
						name="attachments[]"
						accept={acceptTypes}
						id="upload"
						multiple=""
					/>

					<label className="cv-uploadButton" htmlFor="upload">
						<UploadImgComponent />
						<div className="pt-3">
							<ButtonTertiary
								text={buttonText}
								size={`1.3rem`}
								type={'file'}
								id={'upload'}
							/>
						</div>
					</label>
				</div>
				<div className="d-flex align-items-center justify-content-center mx-5 flex-wrap">
					{/* Only render Caption if showCaption is true */}
					{showCaption && (
						<Caption
							caption={`To upload file size is (Max 5Mb) and allowed file types are ${acceptTypes}`}
							size={`1.5rem`}
						/>
					)}
				</div>

				<span className="uploadButton-file-name"></span>
			</div>
		</div>
	);
};

export default UploadDocument;

// mockApi.js
import axios from 'axios';
import BlogImg1 from '../assets/images/blogImg/blog-1.jpg';
import BlogImg2 from '../assets/images/blogImg/blog-2.jpg';
import BlogImg3 from '../assets/images/blogImg/blog-3.jpg';

export async function fetchBlogs() {
	// try {
	//     // Axios GET request to your actual API endpoint
	//     const response = await axios.get('https://your-api-domain.com/blogs');

	//     // Return the data received from the API
	//     return response.data;
	// } catch (error) {
	//     console.error("Error fetching blogs:", error);
	//     throw new Error('Error fetching blogs');
	// }

	//  returning hardcoded data.
	// In a real scenario, this would be an axios.get() request.
	const response = await new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				data: [
					{
						imageUrl: BlogImg1,
						title: 'Blog Post 1',
						date: 'August 31, 2023',
						commentCount: 5,
						content: `I have basic SQL skills. How can I grow in the Data visualization domain? Are there well-paying jobs?`,
						postId: '1',
						link: `https://www.linkedin.com/pulse/i-have-basic-sql-skills-how-can-grow-data-domain-jobs-neha-goyal/`,
					},
					{
						imageUrl: BlogImg2,
						title: 'Blog Post 2',
						date: 'September 2, 2023',
						commentCount: 3,
						content: `I am in college and am confused if I should focus on internships, academics, or GMAT/GRE preparation. What should I do?`,
						postId: '2',
						link: `https://www.linkedin.com/pulse/i-am-college-confused-should-focus-internships-academics-gmatgre-/?trackingId=WMhobPP2AWkTtXsHwAFrGg%3D%3D`,
					},
					{
						imageUrl: BlogImg3,
						title: 'Blog Post 3',
						date: 'September 2, 2023',
						commentCount: 6,
						content: `Is it Okay to Quit your new job, if you realize that it doesn’t meet your expectations immediately after joining?`,
						postId: '3',
						link: `https://www.linkedin.com/pulse/okay-quit-your-new-job-you-realize-doesnt-meet-expectations-/?trackingId=%2BQoMU9q8QHmza7bB50oYRQ%3D%3D`,
					},
					{
						imageUrl: BlogImg3,
						title: 'Blog Post 3',
						date: 'September 2, 2023',
						commentCount: 6,
						content: `Why Project Management can be an apt career option for ex-military personnel?`,
						postId: '4',
						link: `https://www.linkedin.com/pulse/okay-quit-your-new-job-you-realize-doesnt-meet-expectations-/?trackingId=%2BQoMU9q8QHmza7bB50oYRQ%3D%3D`,
					},
					{
						imageUrl: BlogImg3,
						title: 'Blog Post 3',
						date: 'September 2, 2023',
						commentCount: 6,
						content: `Is the hype about Project Management Professional (PMP) worth it?`,
						postId: '3',
						link: `https://www.linkedin.com/pulse/okay-quit-your-new-job-you-realize-doesnt-meet-expectations-/?trackingId=%2BQoMU9q8QHmza7bB50oYRQ%3D%3D`,
					},
					{
						imageUrl: BlogImg3,
						title: 'Blog Post 3',
						date: 'September 2, 2023',
						commentCount: 6,
						content: `How do I pick up a college for an undergraduate degree after my school?`,
						postId: '3',
						link: `https://www.linkedin.com/pulse/okay-quit-your-new-job-you-realize-doesnt-meet-expectations-/?trackingId=%2BQoMU9q8QHmza7bB50oYRQ%3D%3D`,
					},
				],
			});
		}, 1000);
	});

	return response.data;
}

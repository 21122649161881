import React from 'react';
import { PiBriefcaseBold } from 'react-icons/pi';
import { GiMoneyStack } from 'react-icons/gi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import ButtonTertiary from '../../components/core/buttons/ButtonTertiary';
import Title from '../../components/core/typography/Title';
import Caption from '../../components/core/typography/Caption';
import SubTitle from '../../components/core/typography/SubTitle';
import ContentTitle from '../../components/core/typography/ContentTitle';
import JobPostForm from '../../components/forms/JobPostForm';
import SocialNetwork from '../../components/forms/SocialNetwork';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
const JobPost = () => {
	return (
		<>
			<section className="job-post-container">
				<div className="container-lg container-md container-sm pt-3">
					<ProfileTitleSection
						title={'Post Jobs'}
						subText={'Ready to Jump Back in?'}
					/>

					<div className="job-info">
						<div className=" position-relative">
							<div className=" position-relative">
								<div className="tabs-box position-relative">
									<div className="details-container">
										<div className="post-job-steps row">
											<div className="step col-lg-4 col-md-4 col-sm-12">
												<span className="icon">
													<PiBriefcaseBold />
												</span>
												<ContentTitle
													contentTitle={`Job Details`}
												/>
											</div>
											<div className="step col-lg-4 col-md-4 col-sm-12">
												<span className="icon">
													<GiMoneyStack />
												</span>
												<ContentTitle
													contentTitle={`Package & Payments`}
												/>
											</div>
											<div className="step col-lg-4 col-md-4 col-sm-12">
												<span className="icon ">
													<BsFillCheckCircleFill />
												</span>
												<ContentTitle
													contentTitle={`Confirmation`}
												/>
											</div>
										</div>
										<div className="mb-5 bg-dark rounded-4 p-5">
											<JobPostForm />
										</div>
										<div className="bg-dark rounded-4">
											<SocialNetwork />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default JobPost;

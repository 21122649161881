import { useState } from 'react';
import ReactStars from 'react-rating-star-with-type';

const Rating = () => {
	const [star, setStar] = useState(5);

	const onChange = (nextValue) => {
		setStar(nextValue);
	};

	return (
		<ReactStars
			onChange={onChange}
			value={4}
			edit={true}
			activeColors={['red', 'orange']}
		/>
	);
};
export default Rating;

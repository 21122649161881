import React, { useState } from 'react';
import TextArea from '../core/typography/TextArea';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import ButtonTertiary from '../core/buttons/ButtonTertiary';

const MessageForm = () => {
	// States to hold form data and errors
	const [formData, setFormData] = useState({
		username: '',
		message: '',
	});
	const [errors, setErrors] = useState({});

	// Handle input changes and update form data state
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		// Validation Logic
		const newErrors = {};

		// Validate name:
		if (!formData.username) {
			newErrors.username = 'Name is required!';
		}

		// Validate message:
		if (!formData.message) {
			newErrors.message = 'Message cannot be empty!';
		}

		// Update the errors state
		setErrors(newErrors);

		// If there are no errors, proceed with form submission or next actions
		if (Object.keys(newErrors).length === 0) {
			// Perform desired actions with the message data
		}
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<div className="mb-3">
					<LabelText labelText="Name" />
					<Input
						type="text"
						name="username"
						id="floatingName"
						label="Enter your name"
						value={formData.username}
						onChange={handleChange}
					/>
					{errors.username && (
						<p className="error-text">{errors.username}</p>
					)}
				</div>
				<div className="mb-3">
					<LabelText labelText="Message" />
					<TextArea
						name="message"
						placeholder="Write your message..."
						value={formData.message}
						onChange={handleChange}
					/>
					{errors.message && (
						<p className="error-text">{errors.message}</p>
					)}
				</div>
				<ButtonTertiary type="submit" text="Submit" />
			</form>
		</div>
	);
};

export default MessageForm;

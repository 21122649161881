import React from 'react';
import ButtonTertiary from '../../components/core/buttons/ButtonTertiary';
import Title from '../../components/core/typography/Title';
import Caption from '../../components/core/typography/Caption';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import { Link } from 'react-router-dom';

const UploadCV = () => {
	return (
		<div>
			<section className="uploadcv-container ">
				<div className="container">
					<div className="upper-title-box">
						<Title title={`Resume Manager!`} />
						<Caption caption={`Ready to jump back in?`} />
					</div>

					<div className="row">
						<div className="col-lg-12">
							<div className="cv-manager-container  position-relative">
								<div className="title position-relative px-5">
									{/* <SubTitle subTitle={`Resume manager`} /> */}
								</div>
								{/* <div className="cv-content position-relative">
									<div className="uploading-resume">
										<div className="uploadButton position-relative d-flex align-content-center flex-column justify-content-center">
											<input
												className="uploadButton-input position-relative overflow-hidden d-none pointer-event-none"
												type="file"
												name="attachments[]"
												accept=".doc,.docx,.xml,application/msword,application/pdf, image/*"
												id="upload"
												multiple=""
											/>
											<label
												className="cv-uploadButton"
												for="upload"
											>
												<Caption
													caption={`Drop files here to upload`}
													size={`2.2rem`}
													color={'#d90368'}
												/>

												<Caption
													caption={`To upload file size is (Max
													5Mb) and allowed file types
													are (.doc, .docx, .pdf)`}
													size={`1.5rem`}
												/>

												<ButtonTertiary
													text={'Upload Resume'}
												/>
											</label>
											<span className="uploadButton-file-name"></span>
										</div>
									</div>
								</div> */}

								{/* Temporary format */}
								<div className="cv-content position-relative pt-5 pb-5">
									<div className="uploading-resume">
										<div className="uploadButton position-relative d-flex align-content-center flex-column justify-content-center">
											<input
												className="uploadButton-input position-relative overflow-hidden d-none pointer-event-none"
												// type="file"
												name="attachments[]"
												accept=".doc,.docx,.xml,application/msword,application/pdf, image/*"
												id="upload"
												multiple=""
											/>
											<label
												className="cv-uploadButton"
												for="upload"
											>
												<Caption
													caption={`Mail your Resume/CV at neha@menteechain.com `}
													size={`2.2rem`}
													color={'#d90368'}
												/>

												<Caption
													caption={`To upload file size is (Max
													5Mb) and allowed file types
													are (.doc, .docx, .pdf)`}
													size={`1.5rem`}
												/>
												<a
													href="mailto:neha@menteechain.com"
													target="_blank"
												>
													<ButtonTertiary
														text={'Send Resume'}
													/>
												</a>
											</label>
											{/* <span className="uploadButton-file-name"></span> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default UploadCV;

import React from 'react';
import { BsHourglassSplit } from 'react-icons/bs';
import { FiCalendar, FiMapPin, FiUser } from 'react-icons/fi';
import { IoCashOutline } from 'react-icons/io5';

const JobOverview = () => {
	return (
		<div>
			<div class="sidebar-widget">
				<h4 class="widget-title">Job Overview</h4>
				<div class="widget-content">
					<ul class="job-overview position-relative">
						<li>
							<i>
								<FiCalendar />
							</i>
							<div class="px-5">
								<h5>Date Posted:</h5>
								<span>Posted 1 hours ago</span>
							</div>
						</li>
						<li>
							<i>
								<BsHourglassSplit />
							</i>
							<div class="px-5">
								<h5>Expiration date:</h5>
								<span>April 06, 2021</span>
							</div>
						</li>
						<li>
							<i>
								<FiMapPin />
							</i>
							<div class="px-5">
								<h5>Location:</h5>
								<span>London, UK</span>
							</div>
						</li>
						<li>
							<i>
								<FiUser />
							</i>
							<div class="px-5">
								<h5>Job Title:</h5>
								<span>Designer</span>
							</div>
						</li>

						<li>
							<i>
								<IoCashOutline />
							</i>
							<div class="px-5">
								<h5>Salary:</h5>
								<span>$35k - $45k</span>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default JobOverview;

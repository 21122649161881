import React from 'react';

const TextArea = ({ placeholder, onChange }) => {
	return (
		<>
			<textarea
				placeholder={placeholder}
				onChange={onChange}
				style={{ resize: 'none', fontSize: '1.5rem' }}
			></textarea>
		</>
	);
};

export default TextArea;

import React, { useState } from 'react';
import LabelText from '../core/typography/LabelText';
import Input from '../core/typography/Input';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import UploadResume from '../core/typography/UploadResume';

const ApplyForm = () => {
	// 1. State management for form inputs
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		// Add more fields if needed, e.g., resume: null
	});

	const [errorMessage, setErrorMessage] = useState('');

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// 2. Form validations
		if (!formData.username || !formData.email) {
			setErrorMessage('Name and email fields are required!');
			return;
		}

		// Validate email format
		if (!/\S+@\S+\.\S+/.test(formData.email)) {
			setErrorMessage('Invalid email format!');
			return;
		}

		// TODO: Add validation for the uploaded resume if needed.

		// If all validations pass, clear any previous error messages
		setErrorMessage('');
		// TODO: Send the form data to the server or further processing
	};

	return (
		<>
			<form className="container pb-5" onSubmit={handleSubmit}>
				<div className="mb-2">
					<LabelText labelText="Name" />
					<Input
						type="text"
						name="username"
						id="floatingName"
						label="Enter your name"
						value={formData.username}
						onChange={handleChange}
					/>
				</div>
				<div className="mb-2">
					<LabelText labelText="Email" />
					<Input
						type="email"
						name="email"
						id="floatingEmail"
						label="Enter your email"
						value={formData.email}
						onChange={handleChange}
					/>
				</div>
				<div className="mb-2">
					<LabelText labelText="Upload Resume" />
					<UploadResume />
					{/* If the UploadResume component has events or data that affect state, connect them here. */}
				</div>

				{/* Display validation errors */}
				{errorMessage && (
					<div className="text-danger mb-2">{errorMessage}</div>
				)}

				<ButtonTertiary type="submit" text="Submit" />
			</form>
		</>
	);
};

export default ApplyForm;

import React from 'react';

const ContentTitle = ({ contentTitle, uppercase, color, size }) => {
	const contentTitleStyle = {
		textTransform: uppercase,
		color,
		fontSize: size,
	};
	return (
		<div>
			<h4 className="content-title" style={{ ...contentTitleStyle }}>
				{contentTitle}
			</h4>
		</div>
	);
};

export default ContentTitle;

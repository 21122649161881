// /ACTUAL api

// import axios from 'axios';

// export const getTermsAndConditions = async () => {
//     try {
//         const response = await axios.get('https://your-backend-url/terms-and-conditions');
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching terms and conditions:', error);
//         throw new Error('Error fetching terms and conditions');
//     }
// }

// HARD CODED DATA
export const getTermsAndConditions = () => {
	return Promise.resolve({
		lastUpdated: 'August 10, 2023',
		sections: [
			{
				title: '1. Overview',
				content: 'Lorem ipsum dolor sit amet, consectetur...',
			},
			{
				title: '2. Introduction',
				content: 'Lorem ipsum dolor sit amet, consectetur...',
			},
			{
				title: '3. Usage Policy',
				content: 'Lorem ipsum dolor sit amet, consectetur...',
			},
			{
				title: '4. Cookies',
				content: 'Lorem ipsum dolor sit amet, consectetur...',
			},
			// ... you can add more sections as needed
		],
	});
};

import React from 'react';
import ButtonSecondary from '../buttons/ButtonSecondary';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import BlogCardImg from '../img/BlogCardImg';
// import ContentTitle from '../typography/ContentTitle';
import Text from '../typography/Text';
import SubText from '../typography/SubText';

const Blog = ({
	title,
	date,
	commentCount,
	content,
	imageUrl,
	postId,
	link,
}) => {
	return (
		<div className="inner-box">
			<div className="image-box">
				<figure className="image">
					<BlogCardImg imageUrl={imageUrl} />
				</figure>
			</div>
			<div className="lower-content">
				<ul className="post-meta">
					<li>{/* <SubText subText={date} /> */}</li>
					<li>
						{/* <SubText
							subText={`${commentCount} Comment${
								commentCount > 1 ? 's' : ''
							}`}
						/> */}
					</li>
				</ul>
				{/* <ContentTitle contentTitle={title} /> */}
				<Text text={`${content.substring(0, 80)}...`} />
				<a href={link} target="_blank" rel="noopener noreferrer">
					<ButtonSecondary text="Read more" icon={<FaAngleRight />} />
				</a>
			</div>
		</div>
	);
};

export default Blog;

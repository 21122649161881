import React from 'react';
import ContentTitle from '../core/typography/ContentTitle';
import ButtonPrimary from '../core/buttons/ButtonPrimary';
import RecruitingSideImg from '../core/img/RecruitingSideImg';
import SubTitle from '../core/typography/SubTitle';
import Text from '../core/typography/Text';

const Recruiting = () => {
	return (
		<div>
			<div className="call-to-action">
				<SubTitle
					subTitle={`Recruiting?`}
					color={`var(--text-color)`}
				/>

				<Text
					text={`Advertise your jobs to millions of monthly users and search
					15.8 million CVs in our database.`}
				/>
				<ButtonPrimary text={'Start Recruiting Now'} />
				<RecruitingSideImg />
			</div>
		</div>
	);
};

export default Recruiting;

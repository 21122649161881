import React from 'react';

function ButtonSecondary({ text, onClick, icon, color }) {
	const btnStyle = {
		color: color,
	};
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<div>
			<button
				className="button-secondary bg-transparent border-0"
				onClick={handleClick}
				style={btnStyle}
			>
				{text}
				{icon && <span className="icon">{icon}</span>}
			</button>
		</div>
	);
}

export default ButtonSecondary;

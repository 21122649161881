import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ContentTitle from '../core/typography/ContentTitle';
import Text from '../core/typography/Text';

const FAQAccordion = ({ data, activeKey, onKeySelect }) => {
    return (
        <Accordion defaultActiveKey={activeKey} onSelect={onKeySelect} className="custom-accordion pt-5">
            {data.map((item, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header className="accordion-header">
                        <ContentTitle contentTitle={item.header} color={'black'} />
                    </Accordion.Header>
                    <Accordion.Body>
                        <Text text={item.body} />
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};

export default FAQAccordion;

import React from 'react';

const FilterInput = ({ type, name, placeholder }) => {
	return (
		<>
			<input
				type={type}
				name={name}
				placeholder={placeholder}
				className="filter-input"
			/>
		</>
	);
};

export default FilterInput;

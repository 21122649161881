import React from 'react';
import FooterLogo from '../img/FooterLogo';
import { FiMail, FiMapPin, FiPhone } from 'react-icons/fi';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const Footer = () => {
	return (
		<div>
			{' '}
			<footer className="main-footer container-fluid">
				<div className="auto-container container">
					<div
						className="widgets-section aos-init aos-animate"
						data-aos="fade-up"
					>
						<div className="row d-flex">
							{/* <div className="big-column col-xl-4 col-lg-3 col-md-12 px-2">
								<div className="footer-column about-widget">
									<div className="logo d-flex text-white position-relative">
										<a href="#" className="pe-3">
											<FooterLogo />
										</a>
									</div>
									<div className="d-flex flex-column">
										<div className="connect">
											<i>
												<FiMapPin />
											</i>
											<span>india | USA | Canada</span>
										</div>
										<div className="connect">
											<i>
												<FiPhone />
											</i>
											<span>
												<a href="tel:7982952295">
													7982952295
												</a>
											</span>
										</div>
										<div className="connect">
											<i>
												<FiMail />
											</i>

											<span>
												<a href="mailto:neha@menteechain.com">
													neha@menteechain.com
												</a>
											</span>
										</div>
									</div>
									<div className="d-flex social-icons ">
										<a
											href="https://www.linkedin.com/in/nehagoyal2018/"
											target="_blank"
										>
											<i>
												<FaLinkedin />
											</i>
										</a>
										<a
											href="https://www.instagram.com/mentee_chain?igshid=YmMyMTA2M2Y%3D"
											target="_blank"
										>
											<i>
												<FaInstagram />
											</i>
										</a>
									</div>
								</div>
							</div> */}
							{/* <div className="big-column col-xl-8 col-lg-9 col-md-12">
								<div className="row">
									<div className="footer-column col-lg-3 col-md-6 col-sm-12">
										<div className="footer-widget links-widget position-relative">
											<h4 className="widget-title">
												For Candidates
											</h4>
											<div className="widget-content">
												<ul className="list">
													<li>
														<a href="/job-list-v11">
															Browse Jobs
														</a>
													</li>
													<li>
														<a href="/job-list-v3">
															Browse Categories
														</a>
													</li>
													<li>
														<a href="/candidates-dashboard/dashboard">
															Candidate Dashboard
														</a>
													</li>
													<li>
														<a href="/candidates-dashboard/job-alerts">
															Job Alerts
														</a>
													</li>
													<li>
														<a href="/candidates-dashboard/short-listed-jobs">
															My Bookmarks
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="footer-column col-lg-3 col-md-6 col-sm-12">
										<div className="footer-widget links-widget">
											<h4 className="widget-title">
												For Employers
											</h4>
											<div className="widget-content">
												<ul className="list">
													<li>
														<a href="/candidates-list-v1">
															Browse Candidates
														</a>
													</li>
													<li>
														<a href="/employers-dashboard/dashboard">
															Employer Dashboard
														</a>
													</li>
													<li>
														<a href="/employers-dashboard/post-jobs">
															Add Job
														</a>
													</li>
													<li>
														<a href="/employers-dashboard/packages">
															Job Packages
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="footer-column col-lg-3 col-md-6 col-sm-12">
										<div className="footer-widget links-widget">
											<h4 className="widget-title">
												About Us
											</h4>
											<div className="widget-content">
												<ul className="list">
													<li>
														<a href="#about">
															About Us
														</a>
													</li>
													<li>
														<a>Job Page Invoice</a>
													</li>
													<li>
														<a href="/terms">
															Terms Page
														</a>
													</li>
													<li>
														<a>Blog</a>
													</li>
													<li>
														<a>Contact</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="footer-column col-lg-3 col-md-6 col-sm-12">
										<div className="footer-widget links-widget">
											<h4 className="widget-title">
												Helpful Resources
											</h4>
											<div className="widget-content">
												<ul className="list">
													<li>
														<a>Site Map</a>
													</li>
													<li>
														<a href="/terms">
															Terms of Use
														</a>
													</li>
													<li>
														<a>Privacy Center</a>
													</li>
													<li>
														<a>Security Center</a>
													</li>
													<li>
														<a>
															Accessibility Center
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div> */}

							{/* Modified footer content for hosting  */}
							<div className="big-column col-xl-12 col-lg-12 col-md-12 px-2 ">
								<div className="footer-column about-widget d-flex flex-lg-row flex-column justify-content-lg-evenly justify-content-center align-items-center">
									<div className="logo d-flex text-white position-relative">
										<a href="#" className="pe-3">
											<FooterLogo />
										</a>
									</div>

									<div className="d-flex flex-column">
										<IconContext.Provider
											value={{ size: '2rem' }}
										>
											<div className="connect">
												<i>
													<FiMapPin />
												</i>
												<span>
													india | USA | Canada
												</span>
											</div>
											<div className="connect">
												<i>
													<FiPhone />
												</i>
												<span>
													<a href="tel:7982952295">
														7982952295
													</a>
												</span>
											</div>
											<div className="connect">
												<i>
													<FiMail />
												</i>

												<span>
													<a href="mailto:neha@menteechain.com">
														neha@menteechain.com
													</a>
												</span>
											</div>
										</IconContext.Provider>
									</div>
									<div className="d-flex social-icons ">
										<IconContext.Provider
											value={{ size: '3rem' }}
										>
											<a
												href="https://www.linkedin.com/in/nehagoyal2018/"
												target="_blank"
											>
												<i>
													<FaLinkedin />
												</i>
											</a>
											<a
												href="https://www.instagram.com/mentee_chain?igshid=YmMyMTA2M2Y%3D"
												target="_blank"
											>
												<i>
													<FaInstagram />
												</i>
											</a>
										</IconContext.Provider>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom text-center">
					<div className="auto-container container">
						<div className="outer-box">
							<div className="copyright-text pb-5">
								© 2023 Menteechain All Right Reserved.
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;

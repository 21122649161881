import React, { useState } from 'react';
import Input from '../core/typography/Input';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import { Link } from 'react-router-dom';
import SubText from '../core/typography/SubText';

const ChangePassForm = () => {
	// 1. State management for form inputs
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	// Handlers for the form fields
	const handlePasswordChange = (e) => setPassword(e.target.value);
	const handleConfirmPasswordChange = (e) =>
		setConfirmPassword(e.target.value);

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		// 2. Form validations
		// Check if fields are not empty
		if (!password || !confirmPassword) {
			setErrorMessage('Both password fields are required!');
			return;
		}

		// Check if passwords match
		if (password !== confirmPassword) {
			setErrorMessage('Passwords do not match!');
			return;
		}

		// If all validations pass, you can proceed to process the form
		setErrorMessage(''); // Clear any previous error messages
		// TODO: Add the logic to change the password (API calls or other operations)
	};

	return (
		<div>
			<form
				id="change-password"
				className="text-center"
				onSubmit={handleSubmit}
			>
				{/* Password Field */}
				<Input
					type="password"
					name="password"
					id="floatingPassword"
					label="Password"
					value={password}
					onChange={handlePasswordChange}
				/>

				{/* Confirm Password Field */}
				<Input
					type="password"
					name="confirmPassword"
					id="floatingConfirmPassword"
					label="Confirm-password"
					value={confirmPassword}
					onChange={handleConfirmPasswordChange}
				/>

				{/* Display validation errors */}
				{errorMessage && (
					<div className="text-danger">{errorMessage}</div>
				)}

				<div className="pt-5 pb-5">
					<ButtonTertiary type="submit" text="Submit" />
				</div>

				{/* Link to Login */}
				<Link to="/" className="text-end">
					<SubText subText="Login Account" />
				</Link>
			</form>
		</div>
	);
};

export default ChangePassForm;

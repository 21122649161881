import React, { useEffect } from 'react';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import ContentTitle from '../../components/core/typography/ContentTitle';
import Text from '../../components/core/typography/Text';
import BlogCardImg from '../../components/core/img/BlogCardImg';
import SubText from '../../components/core/typography/SubText';
import JobOverview from '../../components/jobs/JobOverview';
import { IconContext } from 'react-icons';
import {
	FaBookmark,
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaRegBookmark,
	FaTwitter,
} from 'react-icons/fa';
import ContactForm from '../../components/forms/ContactForm';
import SubTitle from '../../components/core/typography/SubTitle';
import { GoBriefcase } from 'react-icons/go';
import { FiMapPin } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoCashOutline } from 'react-icons/io5';
import Candidate from '../../components/core/img/Candidate';
import { useState } from 'react';
import {
	getCandidateDetails,
	getTitleSectionDetails,
} from '../../API/CandidateResumeApi';

// const professionalSkills = [

// 	{
// 		skill: 'App',
// 	},
// 	{
// 		skill: 'Administrative',
// 	},
// 	{
// 		skill: 'Android',
// 	},
// 	{
// 		skill: 'WordPress',
// 	},
// 	{
// 		skill: 'React Js',
// 	},
// 	{
// 		skill: 'Node Js',
// 	},
// ];

// const education = [
// 	{
// 		collegeInitial: 'M',
// 		course: `Bachelor's of Computer Science`,
// 		collegeName: `Kamla Nehru College, Nagpur`,
// 		year: '2019-2022',
// 		detail: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio.
// 					Sequi debitis animi obcaecati unde voluptatem `,
// 	},
// 	{
// 		collegeInitial: 'M',
// 		course: `Bachelor's of Computer Science`,
// 		collegeName: `Kamla Nehru College, Nagpur`,
// 		year: '2019-2022',
// 		detail: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio.
// 					Sequi debitis animi obcaecati unde voluptatem `,
// 	},
// ];

// const workExperience = [
// 	{
// 		companyInitial: 'M',
// 		designation: 'Front-End Developer',
// 		companyName: 'Menteechain',
// 		year: `2023-0000`,
// 		info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio.
// 		Sequi debitis animi obcaecati unde voluptatem`,
// 	},
// 	{
// 		companyInitial: 'M',
// 		designation: 'Front-End Developer',
// 		companyName: 'Menteechain',
// 		year: `2023-0000`,
// 		info: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, odio.
// 		Sequi debitis animi obcaecati unde voluptatem`,
// 	},
// ];
// Education

const EducationItem = ({ item }) => (
	<div className="row mt-5">
		<div className="col-3 college-initial rounded-circle text-center d-flex align-items-center justify-content-center">
			<SubTitle subTitle={item.collegeInitial} />
		</div>
		<div className="col-9 college-details d-flex flex-lg-row flex-column justify-content-between">
			<div className="px-3 mt-4">
				<ContentTitle contentTitle={item.course} />
				<SubText
					subText={item.collegeName}
					color="var(--common-color)"
				/>
				<Text text={item.detail} />
			</div>
			<div className="year border border-secondary rounded text-center">
				<Text text={item.year} />
			</div>
		</div>
	</div>
);
// Work and Experience
const WorkExperienceItem = ({ item }) => (
	<div className="row mt-5">
		<div className="col-3 college-initial rounded-circle text-center d-flex align-items-center justify-content-center">
			<SubTitle subTitle={item.companyInitial} />
		</div>
		<div className="col-9 company-details d-flex flex-lg-row flex-column justify-content-between">
			<div className="px-3 mt-4">
				<ContentTitle contentTitle={item.designation} />
				<SubText
					subText={item.companyName}
					color="var(--common-color)"
				/>
				<Text text={item.info} />
			</div>
			<div className="year border border-secondary rounded text-center">
				<Text text={item.year} />
			</div>
		</div>
	</div>
);

const CandidateResume = () => {
	const [isBookmarked, setIsBookmarked] = useState(false);
	const [resumeData, setResumeData] = useState({});
	const [loading, setLoading] = useState(true);
	const [titleSectionData, setTitleSectionData] = useState({});
	useEffect(() => {
		const fetchResumeData = async () => {
			try {
				// Fetch resume details
				const resumeData = await getCandidateDetails();
				setResumeData(resumeData);

				// Fetch title section details
				const titleData = await getTitleSectionDetails();
				setTitleSectionData(titleData);
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchResumeData();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}
	// Bookmark toggle function
	const handleBookmarkClick = () => {
		setIsBookmarked(!isBookmarked);
	};
	//render
	const renderSection = (sectionData, title, Component) => (
		<>
			<div className="mt-5 mb-5">
				<ContentTitle contentTitle={title} size={`2.5rem`} />
			</div>
			{sectionData.map((item, key) => (
				<Component key={key} item={item} />
			))}
		</>
	);
	return (
		<>
			<section className="company-details candidate-resume container-lg container-md container-sm">
				<div className="title-section ">
					<div className="row container container d-flex align-items-center">
						<div className="detail-title  container col-lg-7 col-md-12 col-sm-12">
							<div className="inner-box d-flex justify-content-between align-items-center">
								<div className=" d-flex align-items-center">
									<Candidate />

									<div className="mx-lg-3 mx-2">
										<SubTitle subTitle={`Sam Jon`} />

										<div className="job-ad-item pt-2">
											<div className="d-flex flex-wrap ">
												<div className="d-flex align-items-center">
													<IconContext.Provider
														value={{
															size: '1.7rem',
															color: 'var(--subtext-color)',
															className: 'me-2',
														}}
													>
														<GoBriefcase />
													</IconContext.Provider>
													<SubText
														subText={'Catalyst'}
														size={'1.7rem'}
													/>
												</div>
												<div className="d-flex mx-lg-3 mx-2 align-items-center">
													<IconContext.Provider
														value={{
															size: '1.7rem',
															color: 'var(--subtext-color)',
															className: 'me-2',
														}}
													>
														<FiMapPin />
													</IconContext.Provider>
													<SubText
														subText={
															'Mumbai, India'
														}
														size={'1.7rem'}
													/>
												</div>

												<div className="d-flex mx-lg-3 mx-0 align-items-center">
													<IconContext.Provider
														value={{
															size: '1.7rem',
															color: 'var(--subtext-color)',
															className: 'me-2',
														}}
													>
														<AiOutlineClockCircle />
													</IconContext.Provider>
													<SubText
														subText={'11 Hour ago'}
														size={'1.7rem'}
													/>
												</div>
												<div className="d-flex mx-lg-3 mx-2 align-items-center">
													<IconContext.Provider
														value={{
															size: '1.7rem',
															color: 'var(--subtext-color)',
															className: 'me-2',
														}}
													>
														<IoCashOutline />
													</IconContext.Provider>
													<SubText
														subText={'3LPA-$LPA'}
														size={'1.7rem'}
													/>
												</div>
											</div>
										</div>
										<div className="d-flex pt-3 justify-content-around">
											<div className="imp-skills">
												<Text text={`App`} />
											</div>
											<div className="imp-skills ">
												<Text text={`Design`} />
											</div>
											<div className="imp-skills">
												<Text text={`Digital`} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="container col-lg-5 col-md-12 col-sm-12 d-flex justify-content-end align-items-center justify-content-center">
							<div className="">
								<ButtonPrimary text={'Download Resume'} />
							</div>
							<div className="mx-3">
								<ButtonPrimary text={'Set Interview'} />
							</div>
							<div
								className="bookmark rounded mt-4 d-flex justify-content-center align-items-center"
								onClick={handleBookmarkClick}
							>
								<IconContext.Provider
									value={{
										size: '2.4rem',
										color: 'var(--common-color)',
									}}
								>
									{isBookmarked ? (
										<FaBookmark />
									) : (
										<FaRegBookmark />
									)}
								</IconContext.Provider>
							</div>
						</div>
					</div>
				</div>
				<div className="job-detail-outer container position-relative pt-5">
					<div className=" container d-flex justify-content-center">
						<div className="row g-5">
							<div className="content-column col-lg-8 col-md-12 col-sm-12">
								<div className="job-detail position-relative">
									<div className=" mb-5">
										<ContentTitle
											contentTitle={`About Candidate`}
											size={`2.5rem`}
										/>
									</div>
									<Text text={resumeData.aboutCandidate} />
									{/* <div className="row images-outer mt-4 ">
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<div className="row images-outer mt-4 ">
														{resumeData?.images?.map(
															(imgSrc, index) => (
																<div
																	className="col-lg-3 col-md-3 col-sm-6"
																	key={index}
																>
																	<figure
																		className="image"
																		role="button"
																	>
																		<div className="lightbox-image">
																			<BlogCardImg
																				src={
																					imgSrc
																				}
																			/>
																		</div>
																	</figure>
																</div>
															),
														)}
													</div>
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
										<div className="col-lg-3 col-md-3 col-sm-6">
											<figure
												className="image"
												role="button"
											>
												<div className="lightbox-image">
													<BlogCardImg />
												</div>
											</figure>
										</div>
									</div> */}
									{/* Education Section */}
									<div className=" mb-5 pt-5">
										<ContentTitle
											contentTitle={`Education`}
											size={`2.5rem`}
										/>
									</div>{' '}
									{resumeData?.education?.map((item, key) => (
										<EducationItem key={key} item={item} />
									))}
									<div className=" mb-5 pt-5">
										<ContentTitle
											contentTitle={`Work Experience`}
											size={`2.5rem`}
										/>
									</div>
									{resumeData?.workExperience?.map(
										(item, key) => (
											<WorkExperienceItem
												key={key}
												item={item}
											/>
										),
									)}
								</div>
							</div>

							{/* Sidebar contents */}
							<div className="sidebar-column col-lg-4 col-md-12 col-sm-12 position-relative d-flex justify-content-center">
								<aside className="sidebar">
									<JobOverview />
									<div className=" sidebar-widget mt-5 d-flex justify-content-between">
										<ContentTitle
											contentTitle={`Social media`}
											color={`var(--text-color)`}
										/>
										<div>
											<IconContext.Provider
												value={{
													size: '2rem',
													color: 'var(--text-color)',
													style: {
														marginRight: '1rem',
													},
												}}
											>
												<FaFacebookF />
												<FaTwitter />
												<FaInstagram />
												<FaLinkedinIn />
											</IconContext.Provider>
										</div>
									</div>
									<div className=" sidebar-widget mt-5 ">
										<ContentTitle
											contentTitle={`Professional Skills`}
											color={`var(--text-color)`}
										/>
										<div>
											<div className="widget-content pt-4">
												<div className="job-skills ">
													{(
														resumeData.professionalSkills ||
														[]
													).map((elem, key) => (
														<div
															className="d-flex"
															key={key}
														>
															<Text
																text={
																	elem.skill
																}
															/>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>

									<div className="sidebar-widget">
										<ContentTitle
											contentTitle={`Contact us`}
											color={`var(--text-color)`}
										/>
										<div className="pt-4">
											<ContactForm />
										</div>
									</div>
								</aside>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default CandidateResume;

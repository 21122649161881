import React from 'react';
import Text from '../../components/core/typography/Text';
import SubTitle from '../../components/core/typography/SubTitle';
import MenteechainLogo from '../../components/core/img/MenteechainLogo';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ForgotPasswordForm from '../../components/forms/ForgotPasswordForm';

const ForgotPassword = () => {
	return (
		<>
			<section className="login-section forget-password">
				<div className="container py-2 h-75">
					<div className="row d-flex justify-content-center align-items-center h-100">
						<div className="col-12 col-md-8 col-lg-6 col-xl-12">
							<div
								className="card bg-dark text-white"
								style={{ borderRadius: '1rem' }}
							>
								<div className="card-body pb-2 text-center">
									<div className="mb-md-2 mt-md-3 pb-2">
										<div className="container px-4 ">
											<Modal.Title className=" container d-flex justify-content-between mb-3">
												<div>
													<MenteechainLogo />
												</div>
												<div>
													<SubTitle
														subTitle={`Welcome to MenteeChain`}
														size={`2.8rem`}
														lineHeight={`1.3`}
														letterSpacing={`.3rem`}
													/>
												</div>
											</Modal.Title>
											<div className="mb-5">
												<Text
													text={`Enter your email address and we'll send you an email
														  with instructions to reset your password.`}
												/>
											</div>
										</div>
										<div className="container">
											<ForgotPasswordForm />

											<div className="text-center d-flex justify-content-end  align-items-center mt-4">
												<Link>
													<Text text={`Login`} />
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPassword;

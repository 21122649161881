import React from 'react';
import BlogProfile from '../../components/core/img/BlogProfile';
import BlogBanner from '../../components/core/img/BlogBanner';
import BlogPost from '../../components/core/img/BlogPost';
import CommentProfile from '../../components/core/img/CommentProfile';
import ButtonPrimary from '../../components/core/buttons/ButtonPrimary';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import ContentTitle from '../../components/core/typography/ContentTitle';
import BlogMessage from '../../components/forms/BlogMessage';
import ShareApp from '../../components/shareApp/ShareApp';
import Pagination from '../../components/Pagination';
import { fetchSingleBlog } from '../../API/SingleBlogApi';
import { useEffect } from 'react';
import { useState } from 'react';
// const previousPost = {
// 	title: 'Given Set was without from god divide rule Hath',
// 	url: '/previous-post-url',
// };

// const nextPost = {
// 	title: 'Tree earth fowl given moveth deep lesser After',
// 	url: '/next-post-url',
// };
const SingleBlog = () => {
	const [blogData, setBlogData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		async function fetchData() {
			try {
				const data = await fetchSingleBlog();
				setBlogData(data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;

	return (
		<div className="background">
			<section className="blog-single position-relative pt-5 container-lg container-md container-sm">
				<div className="blog-title text-center position-relative">
					<section className="title-section">
						<div className="page-title pt-5 pb-5 text-center">
							<div className="upper-box position-relative">
								<SubTitle subTitle={blogData.subTitle} />
								<div className="post-info position-relative d-flex justify-content-center text-center">
									<li className="d-flex">
										<BlogProfile />
										{blogData.author}
									</li>
									<li>{blogData.date}</li>
									<li>{blogData.commentsCount} Comment</li>
								</div>
							</div>
						</div>
					</section>
				</div>
				<figure className="main-image position-relative mb-0">
					<BlogBanner />
				</figure>
				<div className="blog-content auto-container container text-start">
					<ContentTitle
						contentTitle={blogData.contentDescription}
						size="2.5rem"
					/>
					{blogData.content.map((paragraph, index) => (
						<Text key={index} text={paragraph} />
					))}
					<blockquote className="blockquote-style-one mb-5 mt-5">
						<Text text={blogData.quote.text} />
						<cite>{blogData.quote.author}</cite>
					</blockquote>
					<ContentTitle
						contentTitle="What you'll learn"
						size="2.5rem"
					/>
					<ul className="list-style-four position-relative ">
						{blogData.requirements.map((req, index) => (
							<div>
								<li key={index}>{req}</li>
							</div>
						))}
					</ul>
					<figure className="image">
						<BlogPost />
					</figure>
					<div className="other-options position-relative ">
						<ContentTitle contentTitle="Share this post" />
						<div className="w-50 d-flex justify-content-center m-auto">
							<ShareApp />
						</div>
					</div>
					<Pagination
						prevPost={blogData.previousPost}
						nextPost={blogData.nextPost}
					/>
					<ContentTitle contentTitle="Comments" size="2.5rem" />
					<div className="comments-area position-relative">
						{blogData.comments.map((comment, index) => (
							<div className="comment-box" key={index}>
								<div className="comment">
									<div className="user-thumb">
										<CommentProfile />
									</div>
									<div className="comment-info">
										<div className="user-name">
											{comment.author}
										</div>
										<div className="title">
											{comment.title}
										</div>
									</div>
									<Text text={comment.text} size="1.4rem" />
								</div>
							</div>
						))}
					</div>
					<div className="comment-form default-form position-relative">
						<ContentTitle
							contentTitle="Leave your thought here"
							size="2.5rem"
						/>
						<BlogMessage />
					</div>
				</div>
			</section>
		</div>
	);
};

export default SingleBlog;

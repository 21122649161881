import React, { useState } from 'react';
import Blog from '../../components/core/cards/Blog';
import Pagination from '../../components/pagination/Pagination';
import BlogProfile from '../../components/core/img/BlogProfile';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import FilterInput from '../../components/core/typography/FilterInput';
import { HiOutlineSearch } from 'react-icons/hi';
import { IconContext } from 'react-icons/lib';
import ContentTitle from '../../components/core/typography/ContentTitle';
import { IoFilter } from 'react-icons/io5';
import ButtonSecondary from '../../components/core/buttons/ButtonSecondary';
import { FiX } from 'react-icons/fi';
import { Modal } from 'react-bootstrap';
import Text from '../../components/core/typography/Text';
import { fetchBlogs } from '../../API/Blogs';
import { useEffect } from 'react';

const postArticles = [
	{
		link: '/blog-details/1',
		title: 'Attract Sales And Profits',
		date: 'August 9, 2021',
	},
	{
		link: '/blog-details/2',
		title: '5 Tips For Your Job Interviews',
		date: 'August 9, 2021',
	},
	{
		link: '/blog-details/3',
		title: 'Overworked Newspaper Editor',
		date: 'August 9, 2021',
	},
];
const tags = ['app', 'administrative', 'android', 'ReactJs', 'design', 'react'];

const Sidebar = () => (
	<div className="sidebar-side col-lg-4 col-md-12 col-sm-12 ">
		<aside className=" blog-sidebar">
			<div className="sidebar-widget search-widget position-relative">
				<ContentTitle contentTitle={'Search for keywords'} />
				<div className="pt-3 search-box position-relative">
					<div className="form-group">
						<IconContext.Provider
							value={{
								size: '2.4rem',
								color: '#5c6770',
								className: 'icon',
							}}
						>
							<HiOutlineSearch />
						</IconContext.Provider>
						<FilterInput
							type="text"
							name="search"
							placeholder="Search"
						/>
					</div>
				</div>
			</div>
			<div className="sidebar-widget catagory-widget">
				<ContentTitle contentTitle={'Categories'} />
				<div className="pt-3 catagory-list position-relative">
					<div className="catagory-item">
						<a href="/blog-list-v1#">Education</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Information</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Interview</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Job Seeking</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Jobs</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Learn</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Skill</a>
					</div>
					<div className="catagory-item">
						<a href="/blog-list-v1#">Travel</a>
					</div>
				</div>
			</div>
			<div className="sidebar-widget recent-post">
				<ContentTitle contentTitle={'Recent Posts'} />
				<div className="pt-3 widget-content">
					{postArticles.map((post, index) => (
						<article key={index} className="post d-flex ">
							<div className="post-thumb">
								<BlogProfile />
							</div>
							<div className="post-content">
								<Text text={post.title} />
								<div className="post-info">{post.date}</div>
							</div>
						</article>
					))}
				</div>
			</div>
			<div className="sidebar-widget">
				<ContentTitle contentTitle={'Tags'} />
				<div className="tag-list  pt-3 position-relative d-flex align-items-center flex-wrap ">
					{tags.map((tag) => (
						<div key={tag} className="tag-item">
							<a href="/blog-list-v1#">{tag}</a>
						</div>
					))}
				</div>
			</div>{' '}
		</aside>
	</div>
);
const Blogs = () => {
	const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const ITEMS_PER_PAGE = 10;

	useEffect(() => {
		async function fetchData() {
			try {
				const data = await fetchBlogs();
				setBlogs(data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;

	// function
	const toggleFilterModal = () => {
		setIsFilterModalVisible(!isFilterModalVisible);
	};

	const totalPages = Math.ceil(blogs.length / ITEMS_PER_PAGE);
	const paginatedBlogs = blogs.slice(
		(currentPage - 1) * ITEMS_PER_PAGE,
		currentPage * ITEMS_PER_PAGE,
	);
	return (
		<>
			<div
				id="blogs"
				className="background blogs-container content-area-5 pt-5"
			>
				<div className="container pt-5">
					<ProfileTitleSection
						title={'Blogs'}
						subText={'Ready to jump back in?'}
					/>
				</div>
				{/* <div className="d-flex me-4 align-items-center justify-content-center d-lg-none pb-3 ">
					<IconContext.Provider
						value={{
							size: '2rem',
							color: 'var(--common-color)',
						}}
					>
						<IoFilter />
					</IconContext.Provider>
					<ButtonSecondary
						text={'Filter'}
						onClick={toggleFilterModal}
					/>
				</div> */}
				<div className="content-sect">
					<div
						className="row aos-init aos-animate container m-auto"
						data-aos="fade-up"
					>
						<div className="row">
							<div className="content-side col-lg-12 col-md-12 col-sm-12">
								<div className="blog-grid">
									<div
										className="row aos-init aos-animate"
										data-aos="fade-up"
									>
										{paginatedBlogs.map((blog, key) => (
											<div
												key={key}
												className="blog-block  col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center"
											>
												<Blog
													key={blog.postId}
													title={blog.title}
													date={blog.date}
													commentCount={
														blog.commentCount
													}
													content={blog.content}
													imageUrl={blog.imageUrl}
													postId={blog.postId}
												/>
											</div>
										))}
									</div>
									<Pagination
										currentPage={currentPage}
										totalPages={Math.ceil(
											blogs.length / ITEMS_PER_PAGE,
										)}
										onPageChange={(page) =>
											setCurrentPage(page)
										}
									/>
								</div>{' '}
							</div>
							{/* <div
								className=" col-lg-2 d-none d-lg-block"
								style={{ width: '35rem' }}
							>
								<Sidebar />
							</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* Filter Modal for medium and smaller screens */}
			{/* <Modal
				show={isFilterModalVisible}
				onHide={toggleFilterModal}
				size="lg"
				className="d-lg-none left-side-modal"
			>
				<div className="d-flex justify-content-end pt-4 pe-4">
					<FiX
						style={{
							cursor: 'pointer',
							fontSize: '2.3rem',
							border: '.2rem solid white',
							borderRadius: '5rem',
						}}
						onClick={toggleFilterModal}
					/>
				</div>
				<Modal.Body>
					<Sidebar />
				</Modal.Body>
			</Modal> */}
		</>
	);
};
export default Blogs;

import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const Calendar = () => {
	const [currentMonth, setCurrentMonth] = useState(new Date());
	const today = new Date();

	const daysInMonth = (month, year) => {
		return new Date(year, month, 0).getDate();
	};

	const createCalendar = (month, year) => {
		const daysInCurrentMonth = daysInMonth(month + 1, year);
		let calendar = [];
		for (let day = 1; day <= daysInCurrentMonth; day++) {
			calendar.push(
				<div
					key={day}
					className={`calendar-day ${
						today.getDate() === day &&
						today.getMonth() === month &&
						today.getFullYear() === year
							? 'today'
							: ''
					}`}
				>
					{day}
				</div>,
			);
		}
		return calendar;
	};

	const prevMonth = () => {
		setCurrentMonth(
			new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1),
		);
	};

	const nextMonth = () => {
		setCurrentMonth(
			new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1),
		);
	};

	return (
		<div className="calendar-container">
			<div className="calendar-nav">
				<button onClick={prevMonth} className="calendar-nav-btn">
					<FaAngleLeft />
				</button>
				<h2 className="calendar-heading">
					{currentMonth.toLocaleString('default', { month: 'long' })}{' '}
					{currentMonth.getFullYear()}
				</h2>
				<button onClick={nextMonth} className="calendar-nav-btn">
					<FaAngleRight />
				</button>
			</div>
			<div className="calendar">
				{createCalendar(
					currentMonth.getMonth(),
					currentMonth.getFullYear(),
				)}
			</div>
		</div>
	);
};

export default Calendar;

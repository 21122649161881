import React, { useEffect, useReducer } from 'react';
import Trainer from '../../components/core/cards/Trainer';
import ProfileTitleSection from '../../components/sidebar/ProfileTitleSection';
import { getTrainersData } from '../../API/TrainersApi';

const initialState = {
	trainers: [],
	loading: true,
	error: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_SUCCESS':
			return {
				loading: false,
				trainers: action.payload,
				error: null,
			};
		case 'FETCH_ERROR':
			return {
				loading: false,
				trainers: [],
				error: action.payload,
			};
		default:
			return state;
	}
};

const Training = () => {
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const fetchTrainersData = async () => {
			try {
				const data = await getTrainersData();
				dispatch({ type: 'FETCH_SUCCESS', payload: data });
			} catch (err) {
				dispatch({ type: 'FETCH_ERROR', payload: err });
			}
		};

		fetchTrainersData();
	}, []);

	if (state.loading) return <p>Loading...</p>;
	if (state.error) return <p>Error loading trainers.</p>;

	return (
		<div className="bg-black training-container bg-dark pt-5">
			<div className="container pt-5">
				<ProfileTitleSection
					title={'Improve Your Skills!'}
					subText={'Ready to jump back in?'}
				/>
			</div>
			<div className=" bg-dark ">
				<div className="row me-0">
					{state.trainers.map((trainer) => (
						<div
							key={trainer.id}
							className="col-lg-5 col-md-6 col-sm-12 mb-5 mt-5"
						>
							<Trainer
								image={trainer.image}
								title={trainer.title}
								text={trainer.text}
								trainerName={trainer.name}
								ratingValue={trainer.rating}
								buttonText="Start"
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Training;

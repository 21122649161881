import React, { useState } from 'react';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import Input from '../core/typography/Input';
import CheckBox from '../core/typography/CheckBox';
import SubText from '../core/typography/SubText';
import { Link } from 'react-router-dom';
import { registerUser } from '../../API/Register';
import Text from '../core/typography/Text';
const RegisterForm = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		terms: true,
	});

	const [errors, setErrors] = useState({});
	const [isRegistered, setIsRegistered] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const newErrors = validateFormData(formData);

		if (Object.keys(newErrors).length === 0) {
			try {
				await registerUser(formData);
				setIsRegistered(true);
				alert('Registration successful!');
			} catch (error) {
				if (error.message === 'Failed to register. Please try again.') {
					setErrors({
						apiError: 'Failed to register. Please try again.',
					});
				} else {
					setErrors({
						apiError: 'Unexpected error. Please try again.',
					});
				}
			}
		} else {
			setErrors(newErrors);
		}
	};

	const validateFormData = (data) => {
		const newErrors = {};
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		const uppercasePattern = /[A-Z]/;
		const lowercasePattern = /[a-z]/;
		const numberPattern = /[0-9]/;
		const specialCharacterPattern =
			/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
		const bannedWords = ['password', '123456', 'admin'];

		if (!data.email) newErrors.email = 'Email is required!';
		else if (!emailPattern.test(data.email))
			newErrors.email = 'Invalid email format!';
		if (!data.password) newErrors.password = 'Password is required!';
		else if (data.password.length < 8)
			newErrors.password = 'Password should be at least 8 characters!';
		else if (!uppercasePattern.test(data.password))
			newErrors.password =
				'Password should have at least one uppercase letter!';
		else if (!lowercasePattern.test(data.password))
			newErrors.password =
				'Password should have at least one lowercase letter!';
		else if (!numberPattern.test(data.password))
			newErrors.password = 'Password should have at least one number!';
		else if (!specialCharacterPattern.test(data.password))
			newErrors.password =
				'Password should have at least one special character!';
		else if (bannedWords.some((word) => data.password.includes(word)))
			newErrors.password = 'Password contains a banned word!';
		if (data.password !== data.confirmPassword)
			newErrors.confirmPassword = 'Passwords do not match!';
		if (!data.terms) {
			newErrors.terms = 'You must agree to the terms and conditions!';
		}

		return newErrors;
	};

	if (isRegistered) return <Text text={'Thank you for Registering!'} />;

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<Input
					type="email"
					id="floatingEmail"
					name="email"
					label="Email"
					value={formData.email}
					onChange={handleChange}
				/>
				{errors.email && (
					<p className="error-text text-danger">{errors.email}</p>
				)}
				<Input
					type="password"
					id="floatingPassword"
					name="password"
					label="Password"
					value={formData.password}
					onChange={handleChange}
				/>
				{errors.password && (
					<p className="error-text text-danger">{errors.password}</p>
				)}
				<Input
					type="password"
					id="floatingConfirmPassword"
					name="confirmPassword"
					label="Confirm Password"
					value={formData.confirmPassword}
					onChange={handleChange}
				/>
				{errors.confirmPassword && (
					<p className="error-text text-danger">
						{errors.confirmPassword}
					</p>
				)}
				<div className="d-flex justify-content-center mb-3">
					<div className="d-flex align-items-center ">
						<CheckBox
							name="terms"
							onChange={(e) =>
								setFormData((prevData) => ({
									...prevData,
									terms: e.target.checked,
								}))
							}
							checked={formData.terms} // Set the checkbox value
						/>
						<SubText
							subText={
								<>
									<span>I agree to all&nbsp;</span>
									<Link
										to="/terms&conditions"
										className="link-style"
									>
										Terms & Conditions
									</Link>
								</>
							}
						/>
					</div>
				</div>

				{/* Display the terms and conditions error */}
				{errors.terms && (
					<p className="error-text text-danger">{errors.terms}</p>
				)}
				<ButtonTertiary type="submit" text="Register" />
			</form>
			{errors.apiError && (
				<p className="error-text text-danger">{errors.apiError}</p>
			)}
		</div>
	);
};

export default RegisterForm;

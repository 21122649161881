import React from 'react';
import blogImg from '../../../assets/images/blogImg/blog-2.jpg';
const BlogBanner = () => {
	return (
		<div>
			<img alt="resource" src={blogImg} />
		</div>
	);
};

export default BlogBanner;

import React, { useState } from 'react';
import SubText from '../../components/core/typography/SubText';
import Title from '../../components/core/typography/Title';
import FAQImg from '../../components/core/img/FAQImg';
import FAQAccordion from '../../components/accordion/FAQAccordion';
import SubTitle from '../../components/core/typography/SubTitle';
import { useEffect } from 'react';
import { fetchFAQData } from '../../API/FaqApi';

const FAQ = () => {
	const [faqData, setFaqData] = useState([]);
	const [activeKey, setActiveKey] = useState('0');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchFAQData();
				setFaqData(data);
			} catch (error) {
				console.error('Error fetching FAQ data:', error);
			}
		};

		fetchData();
	}, []);

	return (
		<section className="faq-container background  pb-5">
			<div className="row container m-auto mt-5 pt-5 d-flex justify-content-between">
				<div className="title-section container col-lg-6 col-md- 12 col-sm-12 pb-5 pe-4 m-auto">
					{/* Title */}
					<div className="mb-3">
						<Title title={'FAQ'} />
						<SubTitle
							subTitle={'(Frequently Asked Questions)'}
							size={'2.5rem'}
						/>
					</div>
					<div>
						<SubText
							subText={
								'Sample text. Click to select the text box. Click again or double click to start editing the text.'
							}
							size={'1.8rem'}
						/>
					</div>

					{/* Accordion */}

					<FAQAccordion
						data={faqData}
						activeKey={activeKey}
						onKeySelect={(selectedKey) => setActiveKey(selectedKey)}
					/>
				</div>
				<div className="image col-lg-5 col-md-6 col-sm-12 m-auto text-center pt-5">
					{/* side image */}
					<FAQImg />
				</div>
			</div>
		</section>
	);
};

export default FAQ;

import React from 'react';
import LoginForm from '../../components/forms/LoginForm';
import MenteechainLogo from '../../components/core/img/MenteechainLogo';
import SubTitle from '../../components/core/typography/SubTitle';
import Text from '../../components/core/typography/Text';
import ChangePassForm from '../../components/forms/ChangePassForm';

const ChangePassword = () => {
	return (
		<>
			<div className="change-password">
				<section className="login-section m-auto reset-password">
					<div className="container py-2 h-75">
						<div className="row d-flex justify-content-center align-items-center h-100">
							<div className="col-12 col-md-8 col-lg-6 col-xl-12">
								<div
									className="card bg-dark text-white"
									style={{ borderRadius: '1rem' }}
								>
									<div className="card-body p-4 text-center">
										<div className="mb-md-2 mt-md-3 pb-2">
											<div className="container px-4 ">
												<div className=" d-flex justify-content-between">
													<div>
														<MenteechainLogo />
													</div>
													<div>
														<SubTitle
															subTitle={`Welcome to MenteeChain`}
															size={`2.8rem`}
															lineHeight={`1.3`}
															letterSpacing={`.3rem`}
														/>
													</div>
												</div>

												<div className="mb-5">
													<Text
														text={`Please enter your new password!`}
													/>
												</div>
											</div>
											<div className="p-4">
												<ChangePassForm />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default ChangePassword;

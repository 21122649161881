import React from 'react';
import { BiSolidChevronLeft, BiSolidChevronRight } from 'react-icons/bi';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	return (
		<div>
			<nav className="ls-pagination text-center pb-5">
				<ul className="position-relative d-flex justify-content-center">
					<li className={`prev ${currentPage === 1 && 'disabled'}`}>
						<button
							disabled={currentPage === 1}
							onClick={() => onPageChange(currentPage - 1)}
							className="ls-pagination"
						>
							<BiSolidChevronLeft />
						</button>
					</li>
					{Array.from({ length: totalPages }).map((_, idx) => (
						<li key={idx}>
							<button
								className={`ls-pagination
									${currentPage === idx + 1 ? 'current-page' : 'disabled'}
										`}
								onClick={() => onPageChange(idx + 1)}
							>
								{currentPage}
							</button>
						</li>
					))}
					<li
						className={`next ${
							currentPage >= totalPages && 'disabled'
						}`}
					>
						<button
							onClick={() => onPageChange(currentPage + 1)}
							className="ls-pagination"
						>
							<BiSolidChevronRight />
						</button>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default Pagination;

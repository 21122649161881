import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Homepage from './routes/homepage/homepage';

// context
import { useUserState } from './context/userContext';
import { SiteInfoProvider } from './context/siteInfoContext';
import FindJobs from './pages/jobs/FindJobs';
import Navbar from './components/core/header/Navbar';
import Footer from './components/core/footer/Footer';
import UploadCV from './pages/uploadCv/UploadCV';
import Blogs from './pages/blogs/Blogs';
import SingleBlog from './pages/blogs/SingleBlog';
import Training from './pages/training/Training';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import CompanyList from './pages/company/CompanyList';
import CompanyDetails from './pages/company/CompanyDetails';
import CompanyProfile from './pages/clientSide/CompanyProfile';
import ChangePassword from './pages/changePassword/ChangePassword';
import CandidateResume from './pages/clientSide/CandidateResume';
import Applicants from './pages/clientSide/Applicants';
import ResumeAlerts from './pages/clientSide/ResumeAlerts';
import CreateApplicant from './pages/clientSide/CreateApplicant';
import BrowseCandidate from './pages/clientSide/BrowseCandidate';
import Companies from './pages/clientSide/Companies';
import ProfileSidebar from './components/sidebar/ProfileSidebar';
import AddBlog from './pages/clientSide/AddBlog';
import ShortlistedResume from './pages/clientSide/ShortlistedResume';
import ForwardCandidate from './pages/clientSide/ForwardCandidate';
import MyProfile from './pages/MyProfile';
import InterviewSchedule from './pages/clientSide/InterviewSchedule';
import Dashboard from './pages/dashboard/Dashboard';
import JobPost from './pages/clientSide/JobPost';
import Message from './pages/clientSide/Message';
import TermsAndConditions from './pages/terms_cond/TermsConditions';
import FAQ from './pages/faq/FAQ';
import JobDetails from './pages/jobs/JobDetails';
import ViewInterviews from './pages/clientSide/ViewInterviews';
import Interview from './pages/clientSide/Interview';
import About from './pages/about/About';
import Main from './routes/homepage/home/Main';
function App() {
	var { isAuthenticated } = true; //useUserState();

	return (
		<>
			<div className="">
				<Navbar />
			</div>
			<Routes>
				{/* <Route path="/" element={<Main />} /> */}
				<Route path="/" element={<Homepage />} />

				{/* AUTH ROUTES */}
				{/* <PublicRoute path="/login" element={Login} />
      <PublicRoute path="/forgetpassword" element={ForgetPassword} />        
      <PublicRoute path="/resetPassword" element={ResetPassword} />
      <PublicRoute path="/registeruser" element={RegisterUser} /> */}

				{/* COMMON ROUTES */}
				{/* <Route exact path="/aboutus" element={AboutUs} />
      <Route path="/news" element={News} />
      <Route path="/FAQs" element={FAQs} />        
      <Route path="/contactus" element={ContactUs} />
      <Route path="/terms-condition" element={TermsCondition} /> */}
				<Route path="/about" element={<About />} exact />

				<Route path="/uploadcv" element={<UploadCV />} exact />
				<Route path="/blogs" element={<Blogs />} exact />
				<Route path="/singleblog" element={<SingleBlog />} exact />
				<Route path="/training" element={<Training />} exact />
				<Route
					path="/jobdetails/:jobId"
					element={<JobDetails />}
					exact
				/>
				<Route path="jobpost" element={<JobPost />} exact />
				<Route path="jobs" element={<FindJobs />} exact />
				<Route path="/faq" element={<FAQ />} exact />
				<Route
					path="/terms&conditions"
					element={<TermsAndConditions />}
					exact
				/>
				<Route
					path="forgot-password"
					element={<ForgotPassword />}
					exact
				/>
				{/* Profile Page and its associated Routes */}
				<Route
					path="/profile/*"
					element={
						<>
							<ProfileSidebar>
								<Routes>
									<Route path="/" element={<MyProfile />} />
									<Route
										path="jobs"
										element={<FindJobs />}
										exact
									/>
									<Route
										path="forget-password"
										element={<ForgotPassword />}
										exact
									/>
									<Route
										path="jobpost"
										element={<JobPost />}
										exact
									/>
									<Route
										path="employer"
										element={<CompanyList />}
										exact
									/>
									<Route
										path="company"
										element={<CompanyDetails />}
										exact
									/>
									<Route
										path="/companyprofile"
										element={<CompanyProfile />}
										exact
									/>
									<Route
										path="/changepassword"
										element={<ChangePassword />}
										exact
									/>
									<Route
										path="/candidateresume"
										element={<CandidateResume />}
										exact
									/>
									<Route
										path="/viewinterview"
										element={<Interview />}
										exact
									/>
									<Route
										path="/applicants"
										element={<Applicants />}
										exact
									/>
									<Route
										path="/resumealerts"
										element={<ResumeAlerts />}
										exact
									/>
									<Route
										path="/interviews"
										element={<ViewInterviews />}
										exact
									/>
									<Route
										path="/creatapplicant"
										element={<CreateApplicant />}
										exact
									/>
									<Route
										path="/browsecandidate"
										element={<BrowseCandidate />}
										exact
									/>
									<Route
										path="/clientsidecompanies"
										element={<Companies />}
										exact
									/>
									<Route
										path="/message"
										element={<Message />}
										exact
									/>
									<Route
										path="/addblog"
										element={<AddBlog />}
										exact
									/>
									<Route
										path="/shortlistresume"
										element={<ShortlistedResume />}
										exact
									/>
									<Route
										path="/forwardcandidate"
										element={<ForwardCandidate />}
										exact
									/>
									<Route
										path="/interviewschedule"
										element={<InterviewSchedule />}
										exact
									/>
									<Route
										path="/dashboard"
										element={<Dashboard />}
										exact
									/>
								</Routes>
							</ProfileSidebar>
						</>
					}
				/>
			</Routes>

			{/* SHOW ON PROFILE PAGE */}

			<Footer />
		</>
	);

	// #######################################################################

	function PrivateRoute({ component, ...rest }) {
		return (
			<Route
				{...rest}
				render={(props) =>
					isAuthenticated ? (
						<SiteInfoProvider>
							{React.createElement(component, props)}
						</SiteInfoProvider>
					) : (
						<Navigate
							to={{
								pathname: '/login',
								state: {
									from: props.location,
								},
							}}
						/>
					)
				}
			/>
		);
	}

	function PublicRoute({ component, ...rest }) {
		return (
			<Route
				{...rest}
				render={(props) =>
					isAuthenticated ? (
						<Navigate
							to={{
								pathname: '/',
							}}
						/>
					) : (
						<SiteInfoProvider>
							{React.createElement(component, props)}
						</SiteInfoProvider>
					)
				}
			/>
		);
	}
}

export default App;

import React from 'react';
import JobCard from '../core/cards/JobCard';

const JobList = ({ jobs }) => {
	return (
		<div className="col-lg-12 jobs-list">
			{jobs.slice(0, 3).map((job, index) => (
				<JobCard key={index} {...job} />
			))}
		</div>
	);
};

export default JobList;

import axios from 'axios';

// ACTUAL CODE

//  export async function fetchApplicantDetails() {
//     try {
//         const response = await axios.get('https://api.yourwebsite.com/applicant-details');
//         return response.data;
//     } catch (error) {
//         throw new Error("Failed to fetch applicant details: " + error.message);
//     }
// }

// MOCK DATA
const mockApplicantDetails = [
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
	{
		title: 'Sam Jon Junior',
		designation: 'UI Developer',
		location: 'India',
		company: 'Menteechain',
		remote: 'Followers',
		joining: 'Following',
		workType: 'Groups',
	},
];

export async function fetchApplicantDetails() {
	try {
		// Simulating a delay with hardcoded data. In a real-world scenario, you'd use axios.get() here.
		const response = await new Promise((resolve) => {
			setTimeout(() => {
				resolve({ data: mockApplicantDetails });
			}, 1000);
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching applicant details:', error);
		throw new Error('Error fetching applicant details');
	}
}

// MOCK DATA for Categories
const mockCategories = [
	{ id: 1, label: 'Default' },
	{ id: 2, label: 'Newest' },
	{ id: 3, label: 'Oldest' },
];

export async function fetchCategories() {
	try {
		// Simulating a delay with hardcoded data.
		// In a real-world scenario, you'd use axios.get() here.
		const response = await new Promise((resolve) => {
			setTimeout(() => {
				resolve({ data: mockCategories });
			}, 1000);
		});

		return response.data;
	} catch (error) {
		console.error('Error fetching categories:', error);
		throw new Error('Error fetching categories');
	}
}

import React from 'react';

const SubTitle = ({ subTitle, color, size, lineHeight, letterSpacing }) => {
	const componentStyle = {
		color: color,
		fontSize: size,
		lineHeight,
		letterSpacing,
	};
	return (
		<div>
			<h2 className="sub-title" style={{ ...componentStyle }}>
				{subTitle}
			</h2>
		</div>
	);
};

export default SubTitle;

import React from 'react';
import CompanyLogo from '../../../assets/images/logoImg/logo-1.png';
const CompaniesLogo = () => {
	return (
		<div>
			<img src={CompanyLogo} alt="logo" />
		</div>
	);
};

export default CompaniesLogo;

import React from 'react';
import { IconContext } from 'react-icons/lib';
import { FiMapPin } from 'react-icons/fi';
import { IoCashOutline } from 'react-icons/io5';
import { GoBriefcase } from 'react-icons/go';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import ContentTitle from '../typography/ContentTitle';
import SubText from '../typography/SubText';
import Text from '../typography/Text';
import ButtonPrimary from '../buttons/ButtonPrimary';

const CandidateCard = ({
	applicantImg,
	title,
	designation,
	location,
	salary,
	skill1,
	skill2,
	skill3,
	handleBookmarkClick,
	isBookmarked,
}) => {
	return (
		<div className=" d-flex p-3 justify-content-between applicant-card rounded-4">
			<div className="d-flex flex-lg-row flex-column">
				<div>{applicantImg}</div>
				<div className="d-flex ">
					<div className="mx-lg-5">
						<div className="d-flex justify-content-between">
							<ContentTitle contentTitle={title} />
						</div>
						<div class="job-ad-item pt-2">
							<div className="d-flex flex-wrap ">
								<div className="d-flex align-items-center">
									<IconContext.Provider
										value={{
											size: '1.7rem',
											color: 'var(--subtext-color)',
											className: 'me-2',
										}}
									>
										<GoBriefcase />
									</IconContext.Provider>
									<SubText
										subText={designation}
										size={'1.7rem'}
									/>
								</div>
								<div className="d-flex mx-lg-3 mx-md-2 mx-sm-2 align-items-center">
									<IconContext.Provider
										value={{
											size: '1.7rem',
											color: 'var(--subtext-color)',
											className: 'me-2',
										}}
									>
										<FiMapPin />
									</IconContext.Provider>
									<SubText
										subText={location}
										size={'1.7rem'}
									/>
								</div>

								<div className="d-flex mx-lg-3 mx-md-2 align-items-center">
									<IconContext.Provider
										value={{
											size: '1.7rem',
											color: 'var(--subtext-color)',
											className: 'me-2',
										}}
									>
										<IoCashOutline />
									</IconContext.Provider>
									<SubText subText={salary} size={'1.7rem'} />
								</div>
							</div>
						</div>
						<div className="d-flex justify-content-around pt-4">
							<div className="joining candidate-skills">
								<Text text={skill1} />
							</div>
							<div className="joining candidate-skills">
								<Text text={skill2} />
							</div>
							<div className="joining candidate-skills">
								<Text text={skill3} />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className=" d-flex align-items-lg-center  ">
				<div
					onClick={handleBookmarkClick}
					style={{
						cursor: 'pointer',
						marginRight: '1.5rem',
					}}
				>
					<IconContext.Provider
						value={{
							size: '2.5rem',
							color: 'var(--common-color)',
						}}
					>
						{isBookmarked ? <FaBookmark /> : <FaRegBookmark />}
					</IconContext.Provider>
				</div>
				<div className="px-2">
					<ButtonPrimary text={'View'} />
				</div>
			</div>
		</div>
	);
};

export default CandidateCard;

import React from 'react';
import Title from '../../components/core/typography/Title';
import CompanyFilter from '../../components/companyFilter/CompanyFilter';
import ListSwitcher from '../../components/listSwitcher/ListSwitcher';
import CompanyCard from '../../components/core/cards/CompanyCard';
import Recruiting from '../../components/recruiting/Recruiting';

const companies = new Array(8).fill({});

const Companies = () => {
	const companies = [
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
		{
			companyName: 'Coursera',
			companyLocation: 'Gurugram, Delhi',
			companyField: 'Accounting / Finance',
			companyJobs: '20',
		},
	];
	return (
		<div>
			<section className="title-section">
				<div className="container outer-section">
					<div className="pt-5 pb-5">
						<Title title="Companies" />
					</div>
				</div>
			</section>
			<div className="row gx-0 content-section">
				<div className="filters-column hidden-1023 w-25 col-lg-4 col-md-12 col-sm-12">
					<CompanyFilter />
					<Recruiting />
				</div>
				<div className="container col-lg-8 col-md-12 col-sm 12 jobs-list">
					<div className="ls-switcher container m-auto pb-5 position-relative d-flex justify-content-between align-items-center">
						<div className="showing-result">
							<div className="text text-white">
								<strong>20</strong> jobs
							</div>
						</div>
						<ListSwitcher />
					</div>
					{companies.map((company, index) => (
						<CompanyCard
							key={index}
							companyName={company.companyName}
							companyLocation={company.companyLocation}
							companyField={company.companyField}
							companyJobs={company.companyJobs}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default Companies;

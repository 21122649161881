import React from 'react';
import Blog from '../core/cards/Blog';
import ButtonTertiary from '../core/buttons/ButtonTertiary';
import { Link } from 'react-router-dom';
import SubTitle from '../core/typography/SubTitle';
// import BlogImg2 from '../../assets/images/blogImg/blog-2.jpg';
// import BlogImg3 from '../../assets/images/blogImg/blog-3.jpg';
import { fetchBlogs } from '../../API/Blogs';
import { useState, useEffect } from 'react';
const BlogSection = () => {
	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		async function fetchData() {
			try {
				const data = await fetchBlogs();
				setBlogs(data);
				setLoading(false);
			} catch (err) {
				setError(err.message);
				setLoading(false);
			}
		}
		fetchData();
	}, []);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;
	return (
		<div>
			<section id="blogs" className="blog-section pb-4 container-fluid ">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 title-container">
							<div className="heading d-flex justify-content-between">
								<SubTitle subTitle="Recent Blogs" />
								<Link to="/blogs">
									<ButtonTertiary text="View More Blogs" />
								</Link>
							</div>
						</div>
					</div>
					<div className="content-sect mt-3 d-flex pb-5">
						<div
							className="row aos-init aos-animate"
							data-aos="fade-up"
						>
							{blogs.slice(0, 3).map((blog, key) => (
								<div
									key={key}
									className="blog-block col-lg-4 col-md-6 col-sm-6 d-flex justify-content-center"
								>
									<Blog
										key={blog.postId}
										title={blog.title}
										date={blog.date}
										commentCount={blog.commentCount}
										content={blog.content}
										imageUrl={blog.imageUrl}
										postId={blog.postId}
										link={blog.link}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default BlogSection;

import React from 'react';
import ContentTitle from '../core/typography/ContentTitle';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import MultiRangeSlider from '../multirange/MultiRangeSlider';
import SelectInput from '../core/typography/SelectInput';
import { GoBriefcase } from 'react-icons/go';
import Text from '../core/typography/Text';
import Recruiting from '../recruiting/Recruiting';
import FilterInput from '../core/typography/FilterInput';
import { IconContext } from 'react-icons';
import { HiOutlineSearch } from 'react-icons/hi';

const CompanyFilter = () => {
	const category = [
		{ value: '', label: 'Choose a category' },
		{ value: '', label: 'Residential' },
		{ value: '', label: 'Commercial' },
		{ value: '', label: 'Apartments' },
	];

	return (
		<div>
			<div className="inner-column">
				<div className="filters-outer border border-dark rounded-4">
					<div className="filter-block ">
						<ContentTitle contentTitle={`Search by Keywords`} />
						<div className="form-group  ">
							<IconContext.Provider
								value={{
									size: '2.4rem',
									color: '#5c6770',
									className: 'icon',
								}}
							>
								<HiOutlineSearch />
							</IconContext.Provider>
							<FilterInput
								type={'text'}
								name={'jobsearch'}
								placeholder={`Job title, keywords, or company`}
							/>
						</div>
					</div>
					<div className="filter-block ">
						<ContentTitle contentTitle={`Location`} />

						<div className="form-group">
							<IconContext.Provider
								value={{
									size: '2.4rem',
									color: '#5c6770',
									className: 'icon',
								}}
							>
								<FiMapPin />
							</IconContext.Provider>
							<FilterInput
								type={'text'}
								name={'location'}
								placeholder={`Location`}
							/>
						</div>
						<Text text={`Radius around selected destination`} />
						<div className="range-slider-one">
							<MultiRangeSlider
								min={0}
								max={1000}
								onChange={({ min, max }) =>
									console.log(`min = ${min}, max = ${max}`)
								}
							/>
							<div className="input-outer pt-5">
								<div className="amount-outer">
									<span className="area-amount">100</span>km
								</div>
							</div>
						</div>
					</div>
					<div className="filter-block">
						<ContentTitle contentTitle={`Category`} />

						<div className="form-group">
							<SelectInput options={category} />
							<span className="icon">
								<GoBriefcase />
							</span>
						</div>
					</div>

					<div className="filter-block">
						<ContentTitle contentTitle={`Foundation Date`} />

						<div className="range-slider-one salary-range">
							<MultiRangeSlider
								min={0}
								max={1000}
								onChange={({ min, max }) =>
									console.log(`min = ${min}, max = ${max}`)
								}
							/>
							<div className="input-outer pt-5">
								<div className="amount-outer">
									<span className="d-inline-flex  ">
										<Text text={`1900 - 2023`} />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyFilter;
